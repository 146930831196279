import { DataList } from "../../components/DataList";
import { TRANSACTION_LIST } from "../../components/Service";
import { __echoText } from "../../components/Helper";
import _ from "lodash"

function Transactions() {
    window.document.title = "Transactions";
    
    const dt = {
        id: 'transactions',
        fetchurl: TRANSACTION_LIST,
        columns: [
            { name: 'id', title: 'Id' },
            { name: 'first_name', title: 'Name' },
            { name: 'email', title: 'Email' },
            { name: 'transaction_type', title: 'Transaction Type' },
            { name: 'amount', title: 'Amount' },
            { name: 'stock', title: 'Stock' },
            { name: 'status', title: 'Status' },
            { name: 'created_at', title: 'Date & Time', type: 'datetime'}
        ]
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h4 className="page-heading">Transactions</h4>
                        </div>
                    </div>
                    <div className="row">
                        <DataList 
                            dt={dt}
                            edit_column={{
                                first_name: (records) =>
                                    __echoText(`${records.first_name} ${records.last_name}`),

                                transaction_type: (records) =>
                                    __echoText(_.startCase(records.transaction_type)),

                                status: (records) =>
                                    <span className={`badge  bg-${ records.status === 'success' ? 'success' : 'warning' } rounded-pill fw-medium minw-70`}>{ records.status === 'success' ? 'Success' : 'Pending' }</span>
                            }}
                        />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Transactions