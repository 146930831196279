import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../components/Context';
import { blobToBase64, noImageHandle, toastNotify, validateForm } from "../../components/Helper";
import { fetchData, MY_PROFILE, UPDATE_PROFILE, ASSET_ENDPOINT, UPDATE_PROFILE_IMAGE } from "../../components/Service";

function AppMyProfile(){
    window.document.title = "My Profile";
    
    const [context, setContext] = useContext(Context)
    const [profile, setProfile] = useState('')
    const [profileImage, setProfileImage] = useState('')
    
    useEffect(() => {
        fetchData(MY_PROFILE, 'GET', '', true, false, (res) => {
            if (res.records) {
                setProfile(res.records)
            }
        });
    }, [])

    const updateProfileData = (e) => {
        let formdata = new FormData(document.getElementById('profileUpdate'));

        if (validateForm(e)) {
            fetchData(UPDATE_PROFILE, 'POST', formdata, true, true, (res) => {
                toastNotify(res)
            });
        }
    }

    const imageSet = async (e) => {
        let formdata = new FormData(document.getElementById('profileUpdate'));
        const imgb64 = await blobToBase64(e.target.files[0])

        setProfileImage(imgb64)

        fetchData(UPDATE_PROFILE_IMAGE, 'POST', formdata, true, true, (res) => {
            toastNotify(res)

            if(res.records){
                setContext(prevState => ({
                    ...prevState,
                    auth: {...context.auth, profile_image: res.records}
                }))
            }
        });
    }

    return(
        <div className="row">
            { profile && <form className="needs-validation" id="profileUpdate" noValidate>
                <div className="col-sm-12">
                    <div className="row mx-0 bg-white rounded-15 p-4 shadow-sm d-flex justify-content-center">
                        <div className='col-sm-12 col-lg-6 my-2'>
                            <label className="image-uploading d-flex align-items-center mb-3">
                                <img src={ !profileImage ? ASSET_ENDPOINT + '/' + profile.profile_image : profileImage } alt="blog_image" className="me-3" onError={noImageHandle} />
                                <button type="button" className="btn btn-primary text-nowrap">Change Profile Image</button>
                                <input type="file" accept=".jpg,.jpeg,.png" className="form-field" name="profile_image" role="button" title="Upload Image" onChange={imageSet}/>
                            </label>
                        </div>
                        <div className="col-sm-12"></div>
                        <div className='col-sm-12 col-lg-6 my-2'>
                            <label htmlFor="staff_name" className="form-label small text-blue fw-medium mb-1">First Name<strong className="text-danger">*</strong></label>
                            <input type="text" className="form-control" id='first_name' name="first_name" placeholder="First Name" spellCheck={false} defaultValue={profile.first_name} required />
                            <div className="invalid-feedback">First Name is required.</div>
                        </div>
                        <div className="col-sm-12"></div>
                        <div className='col-sm-12 col-lg-6 my-2'>
                            <label htmlFor="staff_name" className="form-label small text-blue fw-medium mb-1">Middle Name</label>
                            <input type="text" className="form-control" id='middle_name' name="middle_name" placeholder="Middle Name" spellCheck={false} defaultValue={profile.middle_name} />
                        </div>
                        <div className="col-sm-12"></div>
                        <div className='col-sm-12 col-lg-6 my-2'>
                            <label htmlFor="staff_name" className="form-label small text-blue fw-medium mb-1">Last Name<strong className="text-danger">*</strong></label>
                            <input type="text" className="form-control" id='last_name' name="last_name" placeholder="Last Name" spellCheck={false} defaultValue={profile.last_name} required />
                            <div className="invalid-feedback">Last Name is required.</div>
                        </div>
                        <div className="col-sm-12"></div>
                        <div className='col-sm-12 col-lg-6 my-2'>
                            <label className="w-100 mb-3">
                                <div className="mb-1 fw-medium text-blue small">Bio<strong className="text-danger">*</strong></div>
                                <textarea className="form-control form-field" id="bio" name="bio" spellCheck={false} rows={5} defaultValue={profile.bio} required></textarea>
                                <div className="invalid-feedback">Bio is required.</div>
                            </label>
                        </div>
                        <div className="col-sm-12"></div>
                        <div className='col-sm-12 col-lg-6 my-2'>
                            <label htmlFor="staff_name" className="form-label small text-blue fw-medium mb-1 me-3">Gender<strong className="text-danger">*</strong></label>
                            <input type="radio" name="gender" value="male" defaultChecked={profile.gender === 'male' ? true : false}/> Male
                            <input type="radio" name="gender" className="ms-2" value="female" defaultChecked={profile.gender === 'female' ? true : false} /> Female
                            <input type="radio" name="gender" className="ms-2" value="other" defaultChecked={profile.gender === 'other' ? true : false} /> Other
                        </div>
                        <div className='col-sm-12 my-2 pt-3 text-center'>
                            <button type="button" className="btn btn-primary rounded-pill minw-140 fw-medium" onClick={(e) => updateProfileData(e)} >Submit</button>
                        </div>
                    </div>
                </div>
            </form> }
        </div>
    )
}

export default AppMyProfile