import React, { useState } from "react";
import { APP_TOP_BAG_GETTER, fetchData } from "../../components/Service";
import { floatNumber, } from "../../components/Helper";
import { useEffect } from "react";
import _ from "lodash";

const AppTopBagGetterList = () => {
    const [bagGetter, setBagGetter] = useState('')
    const [filter, setFilter] = useState('day');

    useEffect(() => {
        fetchData(APP_TOP_BAG_GETTER + '?percent_of=' + filter, 'GET', '', false, false, (res) => {
            if (res.records) {
                setBagGetter(res.records)
            }
        });
    }, [filter])

    return (
        <div className="row justify-content-center mt-4">
            <div className="row">
                <div className="col ms-auto d-flex p-0">
                    <div className="w-100 d-inline-flex text-end">
                        <div className="ms-auto">
                            <div className="input-group input-group-pill dropdown">
                                <label className="input-group-text" htmlFor="filter-status">
                                    <svg className="icon me-1" role="img"><use href="#icon_filter"></use></svg>
                                    Percent Of
                                </label>
                                <button type="button" className="btn btn-pageof minw-140 bg-white float-end dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">This {_.startCase(filter)}</button>
                                <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto">
                                    <li>
                                        <button type="button" title="This Day" className="dropdown-item rounded-20 text-blue py-2" onClick = {(e) => setFilter('day')}>This Day</button>
                                    </li>
                                    <li>
                                        <button type="button" title="This Week" className="dropdown-item rounded-20 text-blue py-2" onClick = {(e) => setFilter('week')} >This Week</button>
                                    </li>
                                    <li>
                                        <button type="button" title="This Month" className="dropdown-item rounded-20 text-blue py-2" onClick = {(e) => setFilter('month')}>This Month</button>
                                    </li>
                                    <li>
                                        <button type="button" title="This Year" className="dropdown-item rounded-20 text-blue py-2" onClick = {(e) => setFilter('year')}>This Year</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-spinner active">
                <div className="table-responsive">
                    <table className="table-common">
                        <thead>
                            <tr>
                                <th>Order</th>
                                <th>Username</th>
                                <th className="text-end">Percent</th>
                            </tr>
                        </thead>
                        <tbody>
                            { bagGetter && bagGetter.length > 0 ? bagGetter.map((ele, num) => {
                                return (
                                    <tr role="row" key={num}>
                                        <td>{ ele.order}</td>
                                        <td>{ ele.username}</td>
                                        <td className="text-end"><span className={`text-${ele.trend === 'up' ? 'success' : 'danger' }`}>{ floatNumber(ele.abs_percentage_change)}%</span></td>
                                    </tr>
                                )
                            }) : 
                            <tr role="row">
                                <td colSpan="3">
                                    <div className="d-flex align-items-center justify-content-center text-danger">No Record Found</div>
                                </td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default AppTopBagGetterList