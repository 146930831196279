import React, { useState } from 'react'; 
import { DataList } from "../../components/DataList";
import { fetchData, POST_SOCIAL_LIST, POST_SOCIAL_GET, POST_SOCIAL_UPDATE, POST_SOCIAL_DELETE, POST_SOCIAL_STATUS } from "../../components/Service";
import { __echoText, statusChange, validateForm, ConfirmationModal, toastNotify, deleteRecords } from "../../components/Helper";
import _, { now } from "lodash"

function Comments() {
    window.document.title = "Comments";

    const [refresh, setRefresh] = useState(now)
    const [formfield, setFormfield] = useState({
        id: "",
        comment: "",
    })

    const dt = {
        id: 'comments',
        fetchurl: POST_SOCIAL_LIST,
        columns: [
            { name: 'id', title: 'Id' },
            { name: 'first_name', title: 'Name' },
            { name: 'email', title: 'Email' },
            { name: 'post_type', title: 'Post Type' },
            { name: 'title', title: 'Post Title' },
            { name: 'comment', title: 'Comment' },
            { name: 'status', title: 'Status' },
            { name: 'created_at', title: 'Date & Time', type: 'datetime'},
            { name: 'action', title: 'Action' },
        ]
    }

    const updateComment = async (e) => {
        if (validateForm(e)) {
            fetchData(POST_SOCIAL_UPDATE, 'POST', formfield, true, false, (res) => {
                toastNotify(res);
        		if (res.success) {
        			document.querySelector('#addupdateComments [data-bs-dismiss="offcanvas"]').click()
        		}
        		setRefresh(now)
        	});
        }
    }

    const editComment = (getid) => {
        fetchData(POST_SOCIAL_GET + '/' + getid, 'GET', '', true, false, (res) => {
            if (res.records) {
                setFormfield(prevState => ({
                    ...prevState,
                    id: res.records.id,
                    comment: res.records.comment,
                }))
            }
            else {
                toastNotify(res)
            }
        });
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h4 className="page-heading">Comments</h4>
                        </div>
                    </div>
                    <div className="row">
                        <DataList 
                            dt={dt}
                            refresh={refresh}
                            edit_column={{
                                first_name: (records) =>
                                    `${records.first_name !== 'null' && __echoText(records.first_name)} ${records.last_name !== 'null' && __echoText(records.last_name)}`,

                                post_type: (records) =>
                                    __echoText(_.startCase(records.post_type)),

                                comment: (records) => 
                                    <span>{records.comment.length > 30 ? records.comment.substring(0, 30) + '...' : records.comment}</span> ,

                                status: (records) =>
                                    <span className={`badge  bg-${ records.status === 'published' ? 'success' : 'warning' } rounded-pill fw-medium minw-70`} role="button" onClick={() => statusChange(POST_SOCIAL_STATUS, records.id, records.status === 'draft' ? 'published' : 'draft', setRefresh)}>{ records.status === 'published' ? 'Published' : 'Draft' }</span>,
                                
                                action: (records) =>
                                    <div className="d-flex">
                                        <button className="btn btn-sm text-primary" data-bs-toggle="modal" data-bs-target="#view-comment" title="View" onClick={() => editComment(records.id)}>
                                            <svg className="icon fs-4" role="img"><use href="#icon_eye" /></svg>
                                        </button>
                                        <button className="btn btn-sm text-primary" title="Edit" data-bs-toggle="offcanvas" data-bs-target="#addupdateComments" onClick={() => editComment(records.id)}>
                                            <svg className="icon fs-5" role="img"><use href="#icon_edit"/></svg>
                                        </button>
                                        <button className="btn btn-sm text-danger" title="Delete" data-bs-toggle="modal" data-bs-target="#confModal" onClick={(e) => setFormfield(prevState => ({ ...prevState, id: records.id }))}>
                                            <svg className="icon fs-5" role="img"><use href="#icon_trash"/></svg>
                                        </button>
                                    </div>
                            }}
                        />
                    </div>
                </div>
            </section>

            {/* Add Update Form  */}
            <form className="offcanvas offcanvas-end needs-validation " tabIndex="-1" id="addupdateComments" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="m-0 text-blue">Update Comment</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-3 p-sm-4">
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Comment<strong className="text-danger">*</strong></div>
                        <textarea className="form-control form-field" name="comment" spellCheck={false} rows={10} defaultValue={formfield.comment} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, comment: e.target.value
                        }))} required></textarea>
                    </label>
                </div>
                <div className="offcanvas-footer border-top bg-light p-3 px-sm-4">
                    <button type="button" className="btn btn-primary rounded-pill px-4 me-2" onClick={(e) => updateComment(e)} >Submit</button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Close">Cancel</button>
                </div>
            </form>

            <ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(POST_SOCIAL_DELETE, formfield.id, setRefresh )}/>

            {/* Comment View Modal  */}
            <div className="modal fade bd-example-modal-lg" id="view-comment" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header border-bottom bg-light px-sm-4">
                            <h5 className="m-0 text-blue">Comment Detail</h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-3 p-sm-4">
                            <div className="form-label text-blue small fw-medium mb-1"></div>
                            <div>{formfield.comment}</div>
                        </div>
                    </div>
                </div>
            </div>
            {/*End Comment View Modal  */}
        </>
    )
}

export default Comments
