import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { ButtonSpinner, toastNotify, validateForm } from '../../components/Helper';
import { fetchData, FORGOT_PASSWORD } from '../../components/Service';

function ForgotPassword(){
    const [tinyloader, setTinyloader] = useState(false);

    const changeHandler = (e) => {
        if (e.keyCode === 13) {
            loginSubmission(e)
        }
    }

    const loginSubmission = (e) => {
        let requiredField = new FormData(document.getElementById('forgotPasswordForm'));

        if(validateForm(e)) {
            setTinyloader(true);
            fetchData(FORGOT_PASSWORD, 'POST', requiredField, false, true, (res) => {
                setTinyloader(false);
                toastNotify(res)
            });
        };
    }

    return(
        <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-5">
                        <form className="bg-white rounded-15 p-3 p-sm-4 p-sm-5 shadow-sm needs-validation" id="forgotPasswordForm" noValidate>
                            <div className="text-center">
                                <img src="/logo.png" alt="logo" className="w-100 maxw-280" width="280" height="70.81" />
                            </div>
                            <hr className="text-muted opacity-10 mx-auto"/>
                            <h4 className="text-center my-3">Forgot Password</h4>
                            <label className="input-group input-group-lg input-group-rounded mb-3">
                                <span className="input-group-text">
                                    <svg className="icon tscale-1p3 opacity-50" role="img" aria-label="more">
                                        <use href="#icon_user" />
                                    </svg>
                                </span>
                                <input type="email" className="form-control rounded-pill-end-topbottom" name="email" placeholder="Enter your email address" required onKeyUp={changeHandler}/>
                                <div className="invalid-feedback ps-70">Email address is required.</div>
                            </label>

                            <button type="button" className="btn btn-primary btn-lg w-100 rounded-pill mb-3" onClick={(e) => loginSubmission(e)} disabled={tinyloader}>
                                <ButtonSpinner load={tinyloader} btnName="Submit"/>
                            </button>

                            <div className="d-flex justify-content-between">
                                <div className="my-2">
                                    Go to <Link to="/login" className="fw-medium" title="Login">Login</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword