import { now } from 'lodash';
import React, { useState } from 'react';
import { DataList } from '../../components/DataList';
import { ConfirmationModal, deleteRecords, initialFormState, toastNotify, validateForm } from '../../components/Helper';
import { fetchData, PERCENT_IMPACT_SCALE_CREATE, PERCENT_IMPACT_SCALE_DELETE, PERCENT_IMPACT_SCALE_GET, PERCENT_IMPACT_SCALE_LIST, PERCENT_IMPACT_SCALE_UPDATE } from '../../components/Service';

function PercentImpactScale(props) {
    window.document.title = "Percentage Impact Scale";
    const [refresh, setRefresh] = useState(now)
    const [offcanvastitle, setOffcanvastitle] = useState("Add New Percentage Impact")
    const [formfield, setFormfield] = useState({
        id: "",
        key_from: "",
        key_to: "",
        percent_value: "",
    })
    const dt = {
        id: 'percentImpactScale',
        fetchurl: PERCENT_IMPACT_SCALE_LIST,
        columns: [
            { name: 'id', title: 'Id' },
            { name: 'key_from', title: 'Asset Current Value From' },
            { name: 'key_to', title: 'Asset Current Value To' },
            { name: 'percent_value', title: '% Percentage Value' },
            { name: 'action', title: 'Action' },
        ]
    }
    const addPercentImpact = async (e) => {
        let formdata = new FormData(document.getElementById('addUpdatePercent'));
        let id = document.querySelector('#addUpdatePercent [name="id"]').value;

        if (validateForm(e)) {
            fetchData(id ? PERCENT_IMPACT_SCALE_UPDATE : PERCENT_IMPACT_SCALE_CREATE, 'POST', formdata, true, true, (res) => {
                toastNotify(res)

                if (res.success) {
                    document.querySelector('#addUpdatePercent [data-bs-dismiss="offcanvas"]').click();
                }
                setRefresh(now)
            });
        }
    }
    const editPercentImpact = (getid) => {
        initialFormState('addUpdatePercent');
        setOffcanvastitle("Update Special Character")
        fetchData(PERCENT_IMPACT_SCALE_GET + '/' + getid, 'GET', '', true, false, (res) => {
            if (res.records) {
                setFormfield(prevState => ({
                    ...prevState,
                    id: res.records.id,
                    key_from: res.records.key_from,
                    key_to: res.records.key_to,
                    percent_value: res.records.percent_value,
                }))
            } else {
                toastNotify(res)
            }
        }, (err) => { });
    }
    return (
        <div>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h4 className="page-heading">Percentage Impact Scale</h4>
                        </div>
                    </div>

                    <div className="row">
                        {<DataList
                            dt={dt}
                            refresh={refresh}
                            outerbutton={(records) =>
                                <div className="ms-auto">
                                    <button type="button" className="btn btn-primary rounded-pill px-3 text-nowrap" data-bs-toggle="offcanvas" data-bs-target="#addUpdatePercent" onClick={() => initialFormState('addUpdatePercent')}>
                                        <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg> Create Percentage Impact Scale
                                    </button>
                                </div>
                            }
                            edit_column={{                               
                                action: (records) =>
                                    <div className="d-flex">
                                        <button className="btn btn-sm text-primary" data-bs-toggle="modal" data-bs-target="#view-specialchar" title="View" onClick={() => editPercentImpact(records.id)}>
                                            <svg className="icon fs-4" role="img"><use href="#icon_eye" /></svg>
                                        </button>
                                        <button className="btn btn-sm text-primary" title="Edit" data-bs-toggle="offcanvas" data-bs-target="#addUpdatePercent" onClick={() => editPercentImpact(records.id)}>
                                            <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
                                        </button>
                                        <button className="btn btn-sm text-danger" title="Delete" data-bs-toggle="modal" data-bs-target="#confModal" onClick={(e) => setFormfield(prevState => ({ ...prevState, id: records.id }))}>
                                            <svg className="icon fs-5" role="img"><use href="#icon_trash" /></svg>
                                        </button>
                                    </div>
                            }}
                        />}
                    </div>
                </div>
            </section>
            {/* Add Update Form  */}
            <form className="offcanvas offcanvas-end needs-validation " tabIndex="-1" id="addUpdatePercent" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <input type="text" className="d-none" name="id" defaultValue={formfield.id} />
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="m-0 text-blue">{offcanvastitle}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-3 p-sm-4">
                    <label className="w-100 mb-3">
                        <div className="form-label text-blue small fw-medium mb-1">Price From <strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="key_from" placeholder="Price From" spellCheck="false" defaultValue={(formfield.key_from)} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, name: e.target.value
                        }))} required />
                        <div className="invalid-feedback">Price From is required.</div>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="form-label text-blue small fw-medium mb-1">Price To <strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="key_to" placeholder="Enter Price To " spellCheck="false" defaultValue={(formfield.key_to)} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, name: e.target.value
                        }))} required />
                        <div className="invalid-feedback">Price To is required.</div>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="form-label text-blue small fw-medium mb-1">Percentage Value <strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="percent_value" placeholder="Enter Percentage Value" spellCheck="false" defaultValue={(formfield.percent_value)} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, name: e.target.value
                        }))} required />
                        <div className="invalid-feedback">Percentage Value is required.</div>
                    </label>

                </div>
                <div className="offcanvas-footer border-top bg-light p-3 px-sm-4">
                    <button type="button" className="btn btn-primary rounded-pill px-4 me-2" onClick={(e) => addPercentImpact(e)} >Submit</button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Close">Cancel</button>
                </div>
            </form>

            <ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(PERCENT_IMPACT_SCALE_DELETE, formfield.id, setRefresh)} />

            {/* Percentage Scale View Modal  */}
            <div className="modal fade bd-example-modal-lg" id="view-specialchar" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header border-bottom bg-light px-sm-4">
                            <h5 className="m-0 text-blue">Percentage Scale  Details</h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-3 p-sm-4">
                            <div className="row">

                                <div className="col-md-9">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="w-100 mb-3">
                                                <div className="mb-1 fw-medium text-blue small">Value From</div>
                                                <div>{(formfield.key_from)}</div>
                                            </label>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="w-100 mb-3">
                                                <div className="mb-1 fw-medium text-blue small">Value To</div>
                                                <div>{(formfield.key_to)}</div>
                                            </label>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="w-100 mb-3">
                                                <div className="mb-1 fw-medium text-blue small">%age</div>
                                                <div>{(formfield.percent_value)}</div>
                                            </label>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/*End Percentage Scale  View Modal  */}
        </div>
    );
}

export default PercentImpactScale;