import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchData, DASHBOARD_STATICS} from "../../components/Service";
import { loadingData } from "../../components/Helper";

function Dashboard() {
    window.document.title = "Dashboard";
    const [records, setRecords] = useState("")
    const navigate = useNavigate()
    const [filter, setFilter] = useState('');
    useEffect(() => {
        loadingData(true)
        fetchData(DASHBOARD_STATICS+`?${filter[0]}=${filter[0]}`, 'GET', '', true, false, (res) => {
            if (res.records) {
                setRecords(res.records)
            }
            loadingData(false)
        });
    }, [filter]);

    return(
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="">Dashboard</h4>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-auto mb-3">
                            <h6 className="m-0 text-blue">{filter? filter[1] : "All"} Statistics</h6>
                        </div>
                        <div className="col-auto ms-auto mb-3">
                            <div className="input-group input-group-pill dropdown">
                                <label className="input-group-text" htmlFor="filter-status">
                                    <svg className="icon me-1" role="img"><use href="#icon_filter"></use></svg>
                                    Report Of
                                </label>
                                <button type="button" className="btn btn-pageof minw-140 bg-white float-end dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">   
                                {filter? filter[1] : "All"}
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto">
                                    <li>
                                        <button type="button" title="All" className="dropdown-item rounded-20 text-blue py-2" onClick = {(e) => setFilter(['all','All'])}
                                        >All</button>
                                    </li>
                                    <li>
                                        <button type="button" title="This Day" className="dropdown-item rounded-20 text-blue py-2" onClick = {(e) => setFilter(['thisDay', 'This Day'])} 
                                        >This Day</button>
                                    </li>
                                    <li>
                                        <button type="button" title="This Week" className="dropdown-item rounded-20 text-blue py-2" onClick = {(e) => setFilter(['thisWeek', 'This Week'])} 
                                            >This Week</button>
                                    </li>
                                    <li>
                                        <button type="button" title="This Month" className="dropdown-item rounded-20 text-blue py-2" onClick = {(e) => setFilter(['thisMonth', 'This Month'])}
                                        >This Month</button>
                                    </li>
                                    <li>
                                        <button type="button" title="This Year" className="dropdown-item rounded-20 text-blue py-2" onClick = {(e) => setFilter(['thisYear', 'This Year'])}
                                        >This Year</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 g-2 g-sm-4">
                        <div className="col" role="button" onClick={()=>navigate(`/assets`)} >
                            <div className="bg-white d-flex align-items-center h-100 p-3 p-md-4 shadow-sm rounded-15 border-start border-4 border-info" role="button">
                                <div className="p-3 mmw-70 rounded-pill bg-info-light-15 l1">
                                    <div className="d-flex align-items-center justify-content-center text-info">
                                        <svg className="icon fs-2" role="img"><use href="#icon_suitcase"></use></svg>
                                    </div>
                                </div>
                                <div className="ps-3 w-100">
                                    <div className="row">
                                        <h3 className="mb-1">Assets</h3>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="mb-1">{records && records.asset && records.asset.total}</h1>
                                        </div>
                                        <div className="col">
                                            <ul className="list-group">
                                                <li className="d-flex justify-content-between align-items-center">
                                                    <span className="text-muted">Active</span>
                                                    <span className="badge bg-info rounded-pill">{records && records.asset && records.asset.active}</span>
                                                </li>
                                                <li className="d-flex justify-content-between align-items-center">
                                                    <span className="text-muted">Inactive</span>
                                                    <span className="badge bg-info rounded-pill">{records && records.asset && records.asset.inactive}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col" role="button" onClick={()=>navigate(`/users`)}>
                            <div className="bg-white d-flex align-items-center h-100 p-3 p-md-4 shadow-sm rounded-15 border-start border-4 border-primary" role="button">
                                <div className="p-3 mmw-70 rounded-pill bg-primary-light-15 l1">
                                    <div className="d-flex align-items-center justify-content-center text-primary">
                                        <svg className="icon fs-2" role="img"><use href="#icon_users"></use></svg>
                                    </div>
                                </div>
                                <div className="ps-3 w-100">
                                    <div className="row">
                                        <h3 className="mb-1">Users</h3>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="mb-1">{records && records.user && records.user.total}</h1>
                                        </div>
                                        <div className="col">
                                            <ul className="list-group">
                                                <li className="d-flex justify-content-between align-items-center">
                                                    <span className="text-muted">Active</span>
                                                    <span className="badge bg-primary rounded-pill">{records && records.user && records.user.active}</span>
                                                </li>
                                                <li className="d-flex justify-content-between align-items-center">
                                                    <span className="text-muted">Inactive</span>
                                                    <span className="badge bg-primary rounded-pill">{records && records.user && records.user.inactive}</span>
                                                </li>
                                                <li className="d-flex justify-content-between align-items-center">
                                                    <span className="text-muted">Pending</span>
                                                    <span className="badge bg-primary rounded-pill">{records && records.user && records.user.pending}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col" role="button" onClick={()=>navigate(`/posts`)} >
                            <div className="bg-white d-flex align-items-center h-100 p-3 p-md-4 shadow-sm rounded-15 border-start border-4 border-warning" role="button">
                                <div className="p-3 mmw-70 rounded-pill bg-warning-light-15 l1">
                                    <div className="d-flex align-items-center justify-content-center text-warning">
                                        <svg className="icon fs-2" role="img"><use href="#icon_posts"></use></svg>
                                    </div>
                                </div>
                                <div className="ps-3 w-100">
                                    <div className="row">
                                        <h3 className="mb-1">Blogs</h3>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="mb-1">{records && records.blog && records.blog.total}</h1>
                                        </div>
                                        <div className="col">
                                            <ul className="list-group">
                                                <li className="d-flex justify-content-between align-items-center">
                                                    <span className="text-muted">Published</span>
                                                    <span className="badge bg-warning rounded-pill">{records && records.blog && records.blog.active}</span>
                                                </li>
                                                <li className="d-flex justify-content-between align-items-center">
                                                    <span className="text-muted">Draft</span>
                                                    <span className="badge bg-warning rounded-pill">{records && records.blog && records.blog.pending}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col" role="button" onClick={()=>navigate(`/posts`)} >
                            <div className="bg-white d-flex align-items-center h-100 p-3 p-md-4 shadow-sm rounded-15 border-start border-4 border-danger" role="button">
                                <div className="p-3 mmw-70 rounded-pill bg-danger-light-15 l1">
                                    <div className="d-flex align-items-center justify-content-center text-danger">
                                        <svg className="icon fs-2" role="img"><use href="#icon_posts"></use></svg>
                                    </div>
                                </div>
                                <div className="ps-3 w-100">
                                    <div className="row">
                                        <h3 className="mb-1">News</h3>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="mb-1">{records && records.news && records.news.total}</h1>
                                        </div>
                                        <div className="col">
                                            <ul className="list-group">
                                                <li className="d-flex justify-content-between align-items-center">
                                                    <span className="text-muted">Published</span>
                                                    <span className="badge bg-danger rounded-pill">{records && records.news && records.news.active}</span>
                                                </li>
                                                <li className="d-flex justify-content-between align-items-center">
                                                    <span className="text-muted">Draft</span>
                                                    <span className="badge bg-danger rounded-pill">{records && records.news && records.news.pending}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col" role="button" onClick={()=>navigate(`/transactions`)} >
                            <div className="bg-white d-flex align-items-center h-100 p-3 p-md-4 shadow-sm rounded-15 border-start border-4 border-secondary" role="button">
                                <div className="p-3 rounded-pill bg-secondary-light-15 l1">
                                    <div className="d-flex align-items-center justify-content-center text-secondary">
                                        <svg className="icon fs-2" role="img"><use href="#icon_transaction"></use></svg>
                                    </div>
                                </div>
                                <div className="ps-3 w-100">
                                    <div className="row">
                                        <h3 className="mb-1">Transaction</h3>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="mb-1">{records && records.transaction && records.transaction.total}</h1>
                                        </div>
                                        <div className="col">
                                            <ul className="list-group">
                                                <li className="d-flex justify-content-between align-items-center">
                                                    <span className="text-muted">Success</span>
                                                    <span className="badge bg-secondary rounded-pill">{records && records.transaction && records.transaction.success}</span>
                                                </li>
                                                <li className="d-flex justify-content-between align-items-center">
                                                    <span className="text-muted">Pending</span>
                                                    <span className="badge bg-secondary rounded-pill">{records && records.transaction && records.transaction.pending}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col" role="button" onClick={()=>navigate(`/albums`)} >
                            <div className="bg-white d-flex align-items-center h-100 p-3 p-md-4 shadow-sm rounded-15 border-start border-4 border-danger" role="button">
                                <div className="p-3 mmw-70 rounded-pill bg-danger-light-15 l1">
                                    <div className="d-flex align-items-center justify-content-center text-danger">
                                        <svg className="icon fs-2" role="img"><use href="#icon_posts"></use></svg>
                                    </div>
                                </div>
                                <div className="ps-3 w-100">
                                    <div className="row">
                                        <h3 className="mb-1">Albums</h3>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="mb-1">{records && records.album && records.album.total}</h1>
                                        </div>
                                        <div className="col">
                                            <ul className="list-group">
                                                <li className="d-flex justify-content-between align-items-center">
                                                    <span className="text-muted">Published</span>
                                                    <span className="badge bg-danger rounded-pill">{records && records.album && records.album.active}</span>
                                                </li>
                                                <li className="d-flex justify-content-between align-items-center">
                                                    <span className="text-muted">Draft</span>
                                                    <span className="badge bg-danger rounded-pill">{records && records.album && records.album.pending}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col" role="button" onClick={()=>navigate(`/comments`)} >
                            <div className="bg-white d-flex align-items-center h-100 p-3 p-md-4 shadow-sm rounded-15 border-start border-4 border-primary" role="button">
                                <div className="p-3 mmw-70 rounded-pill bg-primary-light-15 l1">
                                    <div className="d-flex align-items-center justify-content-center text-primary">
                                        <svg className="icon fs-2" role="img"><use href="#icon_chat"></use></svg>
                                    </div>
                                </div>
                                <div className="ps-3 w-100">
                                    <div className="row">
                                        <h3 className="mb-1">Comments</h3>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="mb-1">{records && records.comment && records.comment.total}</h1>
                                        </div>
                                        <div className="col">
                                            <ul className="list-group">
                                                <li className="d-flex justify-content-between align-items-center">
                                                    <span className="text-muted">Published</span>
                                                    <span className="badge bg-primary rounded-pill">{records && records.comment && records.comment.active}</span>
                                                </li>
                                                <li className="d-flex justify-content-between align-items-center">
                                                    <span className="text-muted">Draft</span>
                                                    <span className="badge bg-primary rounded-pill">{records && records.comment && records.comment.pending}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )   
}
export default Dashboard