import React from 'react';
import { Link, Outlet } from "react-router-dom";
import Header, { HeaderFront } from "./Header";
import Aside from "./Aside";


const Layout = () => {
    return (
        <>
            <Header />
            <Aside />
            <Outlet />
        </>
    )

}

export const LayoutFront = () => {
    return (
        <div className="container min-vh-100">
            <HeaderFront />
            <Outlet />
            <div class="py-3 bg-light">
                <div class="container">
                    <div class="row">
                        <div class="col-6 text-left">
                            <Link to="/privacy-policy" class="footerLink">Privacy Policy</Link>
                        </div>
                        <div class="col-6 text-end">
                            <Link to="/contact-us" class="footerLink">Contact Us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Layout