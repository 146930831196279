import { useState, useEffect } from 'react';
import { ASSET_PERFOMANCE_DETAIL_GET, fetchData } from './Service';
import DataTable from 'react-data-table-component';
import { currencyFormat, floatNumber4Decimal } from './Helper';

export default function CalculationDetail(props) {
	const [calculationData, setCalculationData] = useState()
	let assetId = props.assetId;

	const columns = [
		{ name: 'Calculation Type', wrap:'no',allowOverflow:'no', style:{padding:'1.2%' } ,class: 'calcType', selector: (row) => row.calculationType, width: 250, sorting: true },
		{ name: 'Current Value', selector: (row) => { return <span>{currencyFormat(row.current_value)}</span> }, sorting: true },
		{ name: 'Value Change', selector: (row) => { return <span>{currencyFormat(row.value_change)}</span> }, sorting: true },
		{
			name: 'Percent %', selector: (row) => {
				return <span className={`text-${row.trend === 'up' ? 'success' : 'danger'}`}>{row.trend === 'up' ? '+' : '-'} {floatNumber4Decimal(row.percentage_change)}%</span>
			}, sorting: true
		},
		{ name: 'Date Time', selector: (row) => row.updated_at, sorting: true },
		{ name: 'Fetched Date', selector: (row) => row.fetchedDate, sorting: true },

	];
	const columnDefs = [
		{ className: "calcType", "targets": [0]}
	]
	useEffect(() => {
		fetchData(ASSET_PERFOMANCE_DETAIL_GET + '/' + assetId.id, 'GET', '', true, false, (res) => {
			if (res.data) {
				setCalculationData(res.data)
			}
		}, (err) => { }, false, false);
	}, [assetId])

	return ( 
		<div>
			<div className="row justify-content-center mt-4">
				<DataTable fixedHeader highlightOnHover columns={columns} className="calcType" data={calculationData}  pagination sorting columnDefs={columnDefs}></DataTable>
			</div>
		</div>

	)
}
