import { useState } from "react"
import { toastNotify, validateForm } from "../../components/Helper"
import { ADD_CONTACT, fetchData } from "../../components/Service"
import { CommonFooter, CommonHeader } from "./Common"

function Contact() {
    const [data, setData] = useState({ name: "", email: "", message: "", phone: "" })

    const handeleInputChange = (e) => {
        setData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const handleSubmitForm = (e) => {
        e.preventDefault();
        if (validateForm(e)) {
            var contactForm = document.getElementById('contactForm')

            fetchData(ADD_CONTACT, 'POST', data, false, false, (res) => {
                toastNotify(res);
                if (res.success) {
                    setData({ name: "", email: "", message: "", phone: "" })
                    contactForm.reset()
                    contactForm.classList.remove('was-validated')
                }
            }, (err) => { })
        }
    }

    return (
        <>
            <style jsx="true">{`
                .contactForm label {
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 10px;
                }
                
                .contactForm .form-control{
                    height: 50px;
                }
                
                .contactForm .form-control:focus {
                    border-color: #000;
                    box-shadow: none;
                }
                
                .contactForm button{
                    background-color: #000;
                    color: #fff;
                    border: none;
                    margin-top: 20px;
                    width: 150px;
                    height: 50px;
                }
                
                .contactForm button:hover{
                    opacity: 0.7;
                    background-color: #000;
                }
            `}</style>
            <CommonHeader title="Contact us" />
            <div className="container ">
                <div className="row">
                    <div className="col-7 m-auto">
                        <div className="contactForm">
                            <form id="contactForm" className="needs-validation" noValidate>
                                <div className="mb-3 mt-3">
                                    <label htmlFor="name">Name:</label>
                                    <input type="text" className="form-control" name="name" id="name" placeholder="Name" onChange={handeleInputChange} required />
                                    <div className="invalid-feedback">Name is required.</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email">Email:</label>
                                    <input type="text" className="form-control" name="email" id="email" placeholder="name@example.com" onChange={handeleInputChange} required />
                                    <div className="invalid-feedback">Email is required.</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email">Phone:</label>
                                    <input type="number" className="form-control" id="number" placeholder="Enter phone" name="phone" onChange={handeleInputChange} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="comment">Description:</label>
                                    <textarea className="form-control" rows="5" id="comment" name="message" onChange={handeleInputChange} required></textarea>
                                    <div className="invalid-feedback">Message is required.</div>
                                </div>
                                <button type="button" className="btn btn-primary" onClick={handleSubmitForm}>Contact Us</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <CommonFooter />
        </>
    )
}

export default Contact
