import { CommonFooter, CommonHeader } from "./Common"

function Privacy() {

    return (
        <>
            <CommonHeader title="Privacy Policy" />
            <div class="privacyContent pt-3 mt-5">
                <div class="container">
                    <div class="row">
                        <div class="col-10 m-auto">
                            <h3 class="text-center">LOREM IPSUM LOREM IPSUM LOREM IPSUM</h3>
                            <h5 class="text-center">
                                Privacy Policy and Information Notice
                            </h5>
                            <div class="contentDiv mt-5">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia odio quisquam maxime aliquam
                                    id assumenda! Repellat error vel dolorum mollitia provident quas porro deserunt, asperiores
                                    fugiat voluptas minima obcaecati nisi.</p>
                                <p class="mt-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia odio quisquam
                                    maxime aliquam
                                    id assumenda! Repellat error vel dolorum mollitia provident quas porro deserunt, asperiores
                                    fugiat voluptas minima obcaecati nisi. Lorem ipsum dolor sit amet consectetur adipisicing
                                    elit. Officia odio quisquam maxime aliquam
                                    id assumenda! Repellat error vel dolorum mollitia provident quas porro deserunt, asperiores
                                    fugiat voluptas minima obcaecati nisi.</p>
                                <p class="mt-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia odio quisquam
                                    maxime aliquam
                                    id assumenda! Repellat error vel dolorum mollitia provident quas porro deserunt, asperiores
                                    fugiat voluptas minima obcaecati nisi.</p>
                                <h3 class="mt-5">1. Lorem Ipsum Lorem Ipsum</h3>
                                <p class="mt-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia odio quisquam
                                    maxime aliquam
                                    id assumenda! Repellat error vel dolorum mollitia provident quas porro deserunt, asperiores
                                    fugiat voluptas minima obcaecati nisi.</p>
                                <ul class="privacyUl">
                                    <li>Lorem Ispum Lopre Ipsum</li>
                                    <li>Lorem Ispum Lopre Ipsum</li>
                                    <li>Lorem Ispum Lopre Ipsum</li>
                                    <li>Lorem Ispum Lopre Ipsum</li>
                                    <li>Lorem Ispum Lopre Ipsum</li>
                                </ul>
                                <p class="mt-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia odio quisquam
                                    maxime aliquam
                                    id assumenda! Repellat error vel dolorum mollitia provident quas porro deserunt, asperiores
                                    fugiat voluptas minima obcaecati nisi.</p>
                                <h3 class="mt-5">Lorem Ipsum Lorem Ipsum</h3>
                                <p class="mt-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia odio quisquam
                                    maxime aliquam
                                    id assumenda! Repellat error vel dolorum mollitia provident quas porro deserunt, asperiores
                                    fugiat voluptas minima obcaecati nisi.</p>
                                <p class="mt-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia odio quisquam
                                    maxime aliquam
                                    id assumenda! Repellat error vel dolorum mollitia provident quas porro deserunt, asperiores
                                    fugiat voluptas minima obcaecati nisi.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CommonFooter />
        </>
    )
}

export default Privacy
