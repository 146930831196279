import React, { useState, useEffect } from "react";
import { DataList, dtControllers } from "../../components/DataList";
import { APP_ASSET_LIST, ASSET_ENDPOINT, ETF_LIST, FAV_ASSET_LIST, fetchData } from "../../components/Service";
import { noImageHandle, __echoText, currencyFormat, floatNumber } from "../../components/Helper";
import _, { now } from "lodash"
import { useContext } from "react";
import { Context } from "../../components/Context";


const AppAssetList = (props) => {
    const [context] = useContext(Context)
    const [refresh, setRefresh] = useState(now)
    const [assetDt, setAssetDt] = useState(false)
    const [etf, setEtf] = useState(1)
    const [etfList, setEtfList] = useState([])
    const [type, setType] = useState('top')

    useEffect(() => {
        fetchData(ETF_LIST, 'GET', '', false, false, (res) => {
            if (res.records) {
                setEtfList(res.records)
            }
        });

        setAssetDt({
            id: 'assets',
            fetchurl: APP_ASSET_LIST,
            columns: [
                { name: 'image', title: 'Assets', width: 50, sorting: false },
                { name: 'name', sorting: false },
                { name: 'current_value', sorting: false },
                { name: 'value_change', sorting: false },
                { name: 'percent', sorting: false },
                { name: 'action', sorting: false },
                { name: 'detail', sorting: false },
            ],
            query_string: `etf=${etf}&type=${type}`,
            nopaginate: true,
            loading: false
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const changeEtf = (etf, setDt) => {
        setDt(prevState => ({
            ...prevState,
            query_string: `etf=${etf}&type=${type}`,
            nopaginate: type === 'top' ? true : false,
        }))
    }
    const changeEtfGraph = (etf) => {
        props.setEtfGraph(etf)
    }

    const changeType = (type, setDt) => {
        setDt(prevState => ({
            ...prevState,
            fetchurl: APP_ASSET_LIST,
            query_string: `etf=${etf}&type=${type}`,
            nopaginate: type === 'top' ? true : false,
        }))
    }
    const changeFav = (setDt) => {
        setDt(prevState => ({
            ...prevState,
            fetchurl: FAV_ASSET_LIST,
            query_string: `etf=${etf}`,
            nopaginate: false,
        }))
    }

    const setChartAssetId = (id) => {
        props.setChartAssetId(id)
    }

    const setCalculationAssetId = (str) => {
        props.setCalculationAssetId(str)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setRefresh(now)
        }, 60 * 1000);

        return () => clearInterval(interval);
    }, [])

    return (
        <>
            <div className="row justify-content-center mt-4">
                {assetDt && <DataList
                    dt={assetDt}
                    refresh={refresh}
                    searching={false}
                    pagelength={false}
                    outerbutton={(records, setDt) =>
                        <>
                            <div className="w-100">
                                <div className="row mb-2">
                                    <div className="col-4 text-start">
                                        <button type="button" className={`btn ${etf === '' ? 'btn-active' : 'bg-white'} btn-sm rounded-pill text-nowrap text-blue me-2`} onClick={() => { setEtf(''); changeEtf('', setDt); changeEtfGraph('') }}>All Assets</button>
                                    </div>
                                    <div className="col-4">
                                        <input type="search" className="form-control form-search minw-280" spellCheck="false" placeholder="Search" onInput={(e) => dtControllers(e, 'search', setDt)}  />
                                    </div>
                                    <div className="col-4 text-end">
                                        <button type="button" className={`btn ${type === 'top' ? 'btn-active' : 'bg-white'} btn-sm rounded-pill text-nowrap text-blue me-2`} onClick={() => { setType('top'); changeType('top', setDt) }}>
                                            Top 5
                                        </button>
                                        <button type="button" className={`btn ${type === 'all' ? 'btn-active' : 'bg-white'} btn-sm rounded-pill text-nowrap text-blue me-2`} onClick={() => { setType('all'); changeType('all', setDt) }}>
                                            All
                                        </button>
                                        {context && context.auth && <button type="button" className={`btn ${type === 'fav' ? 'btn-active' : 'bg-white'} btn-sm rounded-pill text-nowrap text-blue`} onClick={() => { setType('fav'); changeFav(setDt) }}>
                                            <svg className="icon fs-5" role="img"><use href="#icon_heart_fill" /></svg>
                                        </button>}
                                    </div>
                                </div>

                                <div className="text-center">
                                    {etfList && etfList.length > 0 && etfList.map((item, i) => {
                                        return <button key={i} type="button" className={`btn ${etf === item.id ? 'btn-active' : 'bg-white'} btn-sm rounded-pill text-nowrap text-blue me-2`} onClick={() => { setEtf(item.id); changeEtf(item.id, setDt); changeEtfGraph(item.id) }}>{item.name}</button>
                                    })
                                    }
                                </div>
                            </div>

                        </>
                    }
                    edit_column={{
                        image: (records) =>
                            // eslint-disable-next-line jsx-a11y/img-redundant-alt
                            <img src={`${ASSET_ENDPOINT}${records.image}`} alt='asset-image' className="wh-100 rounded shadow-sm objfit-cover bg-light" onError={noImageHandle} />,

                        name: (records) =>
                            <>
                                <div className="py-2">{__echoText(_.startCase(records.name))}</div>
                                <div className="badge bg-dark minw-70 py-2 fs-12">{__echoText(_.startCase(records.code))}</div>
                            </>,

                        current_value: (records) =>
                            <span>{currencyFormat(records.current_value)}</span>,

                        value_change: (records) =>
                            <span>{currencyFormat(records.value_change)}</span>,

                        percent: (records) =>
                            <span className={`text-${records.trend === 'up' ? 'success' : 'danger'}`}>{records.trend === 'up' ? '+' : '-'} {floatNumber(records.percentage_change)}% </span>,

                        action: (records) =>
                            <div className="d-flex">
                                <button className="btn btn-sm text-success" title="View Chart" data-bs-toggle="modal" data-bs-target="#viewChart" onClick={() => setChartAssetId(records.id)}>
                                    <svg className="icon fs-4" role="img"><use href="#icon_bar_chart" /></svg>
                                </button>
                            </div>,
                        detail: (records) =>
                            <div className="d-flex">
                                <button className="btn btn-sm text-success" title="View Calculation Detail" data-bs-toggle="modal" data-bs-target="#viewDetail" onClick={() => setCalculationAssetId({ "id": records.id, "name": records.name })}>
                                    Calculation Detail
                                </button>
                            </div>

                    }}
                />}
            </div>

        </>
    )
}

export default AppAssetList


