import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { ASSET_PERFOMANCE_GET, fetchData } from './Service';
import { useEffect, useRef } from 'react';

const Chart = (props) => {
	const chartComponentRef = useRef(null)

	const options = {
		accessibility: {
			enabled: true
		},
		credits: {
			enabled: false
		},
		rangeSelector: {
			buttons: [
				{
					count: 1,
					type: 'day',
					text: '1D',
					events: {
						click: function (e) {
							getHistoryData('1D')
						}
					}
				},
				{
					count: 5,
					type: 'day',
					text: '5D',
					events: {
						click: function (e) {
							getHistoryData('5D')
						}
					}
				},
				{
					count: 1,
					type: 'month',
					text: '1M',
					events: {
						click: function (e) {
							getHistoryData('1M')
						}
					}
				},
				{
					count: 1,
					type: 'year',
					text: '1Y',
					events: {
						click: function (e) {
							getHistoryData('1Y')
						}
					}
				},
				{
					type: 'ytd',
					text: 'YTD',
					events: {
						click: function (e) {
							getHistoryData('YTD')
						}
					}
				},
			],
			inputEnabled: false,
			selected: 0
		},

		series: [{
			name: 'Asset Price',
			tooltip: {
				valueDecimals: 2
			}
		}]
	};

	const getHistoryData = (range = '1D') => {
		const chart = chartComponentRef.current.chart;
		const series = chart.series[0];

		fetchData(ASSET_PERFOMANCE_GET + '/' + props.chartAssetId + '/' + range + '/yes', 'GET', '', true, false, (res) => {
			if (res.records) {
				series.setData(res.records)
			}
		});
	}

	useEffect(() => {
		const chart = chartComponentRef.current.chart;
		const series = chart.series[0];

		getHistoryData('1D')

		const updateInterval = setInterval(function () {
			fetchData(ASSET_PERFOMANCE_GET + '/' + props.chartAssetId, 'GET', '', true, false, (res) => {
				if (res.records) {
					series.addPoint([res.records[0], res.records[1]], true, true);
				}
			});
		}, 60 * 1000);

		document.getElementById("viewChart").addEventListener('hidden.bs.modal', function (e) {
			clearInterval(updateInterval)
		})

		return () => clearInterval(updateInterval);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.chartAssetId])

	return (
		<>
			<HighchartsReact
				highcharts={Highcharts}
				constructorType={'stockChart'}
				options={options}
				ref={chartComponentRef}
			/>
		</>
	)
}

export default Chart