import React, { useState, useEffect } from "react";
import { fetchData, ASSET_PRICING_GET, APP_ASSET_BUY, APP_ASSET_SELL } from "../../components/Service";
import { toastNotify, currencyFormat, floatNumber, floatNumberValueBased } from "../../components/Helper";
import { useContext } from "react";
import { Context } from "../../components/Context";
import { now } from "lodash";

const AppBuySell = (props) => {
	const [context] = useContext(Context)
	const [refresh, setRefresh] = useState(now)
	const [assetPricing, setAssetPricing] = useState()
	const [price, setPrice] = useState(0)
	const [stock, setStock] = useState(1)

	const buyAsset = () => {
		if (context && context.auth) {
			fetchData(APP_ASSET_BUY, 'POST', { asset_id: assetPricing.id, value: stock }, true, false, (res) => {
				toastNotify(res)
			});
		} else {
			toastNotify({ error: 'Please login to buy this asset.' })
		}
	}

	const sellAsset = () => {
		if (context && context.auth) {
			fetchData(APP_ASSET_SELL, 'POST', { asset_id: assetPricing.id, value: stock }, true, false, (res) => {
				toastNotify(res)
			});
		} else {
			toastNotify({ error: 'Please login to sell this asset.' })
		}
	}

	// const toggleFavourite = () => {
	// 	if(context && context.auth){
	// 		fetchData(ADD_FAV_ASSET_LIST, 'POST', {asset_id: assetPricing.id}, true, false, (res) => {
	// 			toastNotify(res)
	// 		});
	// 	}else{
	// 		toastNotify({error: 'Please login to add/remove this asset to favourites list.'})
	// 	}
	// }

	const setPriceStock = (e) => {
		
		if (e.target.name === 'price') {
			let one_stock = e.target.value / assetPricing.current_value

			setPrice(e.target.value)
			setStock(one_stock)
		} else {
			let price_of_stocks = e.target.value * assetPricing.current_value

			setStock(e.target.value)
			setPrice(floatNumberValueBased(price_of_stocks))
		}
	}

	useEffect(() => {
		fetchData(ASSET_PRICING_GET + '/' + props.chartAssetId, 'GET', '', true, false, (res) => {
			if (res.records) {
				setPrice(res.records.current_value * stock)
				setStock(stock)
				setAssetPricing(res.records)
			}
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.chartAssetId, refresh])

	useEffect(() => {
		document.getElementById("viewChart").addEventListener('hidden.bs.modal', function (e) {
			clearInterval(interval)
		})

		const interval = setInterval(() => {
			setRefresh(now)
		}, 60 * 1000);

		return () => clearInterval(interval);
	}, [])

	return (
		<>
			{assetPricing && <div className="row p-3">
				<div className="col text-center mb-2"><span className="text-muted">Available Stock</span> <span className="fw-bold fs-5">{floatNumber(assetPricing.current_stock)}</span></div>
				<div className="col text-center mb-3"><span className="text-muted">Current Price</span> <span className={`text-${assetPricing.trend === 'up' ? 'success' : 'danger'} fw-bold fs-5`}>{currencyFormat(assetPricing.current_value)}</span></div>
				<div className="col text-center">
					<label className="w-100 mb-3">
						<div className="form-label text-blue small fw-medium mb-1">Price</div>
						<input type="number" className="form-control text-center" name="price" readOnly value={price} required onChange={setPriceStock} />
					</label>
					<label className="w-100 mb-3">
						<div className="form-label text-blue small fw-medium mb-1">Stocks</div>
						<input type="number" className="form-control text-center" min="1" name="stock" value={stock} required onChange={setPriceStock} />
					</label>

					<button type="button" className="btn btn-success rounded-pill text-nowrap me-2" onClick={buyAsset}>
						BUY
					</button>
					<button type="button" className="btn btn-danger rounded-pill text-nowrap" onClick={sellAsset}>
						SELL
					</button>
					{/* <button type="button" className="btn btn-white rounded-pill text-nowrap" onClick={toggleFavourite}>
						{ assetPricing.favourite ? <svg className="icon fs-5 text-danger" role="img"><use href="#icon_heart_fill"/></svg> : <svg className="icon fs-5" role="img"><use href="#icon_heart"/></svg> } 
					</button> */}
				</div>
			</div>}
		</>
	)
}

export default AppBuySell


