import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";
import { Context } from './Context';

import Layout, { LayoutFront } from './Layout';
import Login from '../modules/Auth/Login';
import ForgotPassword from '../modules/Auth/ForgotPassword';
import ResetPassword from '../modules/Auth/ResetPassword';
import Dashboard from '../modules/Dashboard/Dashboard';

import Error404 from './Error404';
import Users from '../modules/User/Users';
import Assets from '../modules/Assets/Assets';
import SpecialChars from '../modules/SpecialChars/SpecialChars';
import Comments from '../modules/Comment/Comments';
import CommentByPost from '../modules/Comment/CommentByPost';
import Transactions from '../modules/Transaction/Transactions';
import ActivityLog from '../modules/ActivityLog/ActivityLog'
import Post from '../modules/Post/Post';
import VerifyEmail from '../modules/Auth/VerifyEmail';
import Setting from '../modules/Setting/Setting';
import { fetchData, GET_AUTH_USER_INFO } from './Service';
import AppAsset from '../modules/App/AppAsset';
import AppTopBagGetterList from '../modules/App/AppTopBagGetterList';
import Album from '../modules/Album/Album';
import Signup from '../modules/Auth/Signup';
import AppPost from '../modules/App/AppPost';
import AppRecentActivity from '../modules/App/AppRecentActivity';
import AppInMyBag from '../modules/App/AppInMyBag';
import AppMyWallet from '../modules/App/AppMyWallet';
import AppMyProfile from '../modules/App/AppMyProfile';
import AppChangePassword from '../modules/App/AppChangePassword';
import PercentImpactScale from '../modules/PercentImpactScale/PercentImpactScale';
import Calculations from '../modules/Calculations/Calculations';
import Privacy from '../modules/Page/Privacy';
import Contact from '../modules/Page/Contact';
import Contacts from '../modules/Contact/Contacts';



const Routing = () => {
    const [token] = useState(localStorage.getItem("stock-market"));
    const [context, setContext] = useState('');
       
   useEffect(() => {
        if(token){
            fetchData(GET_AUTH_USER_INFO, 'GET', '', true, false, (res) => {
                if(res.records){
                    setContext(prevState => ({
                        ...prevState,
                        auth: res.records
                    }))
                }
            });
        }
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
   
    return (
        <Context.Provider value={[context, setContext]} >
            <Routes>
                <Route path="/login" caseSensitive={false} element={<Login />} />
                <Route path="/signup" caseSensitive={false} element={<Signup />} />
                <Route path="/forgot-password" caseSensitive={false} element={<ForgotPassword />} />
                <Route path="/reset-password" caseSensitive={false} element={<ResetPassword />} />
                <Route path="/verify-email" caseSensitive={false} element={<VerifyEmail />} />
                <Route path="/contact-us" caseSensitive={false} element={<Contact />} />
                <Route path="/privacy-policy" caseSensitive={false} element={<Privacy />} />

                <Route element={<LayoutFront />}>
                    <Route path="/" caseSensitive={false} element={<AppAsset />} />
                    <Route path="app-assets" caseSensitive={false} element={<AppAsset />} />
                    <Route path="app-posts" caseSensitive={false} element={<AppPost />} />
                    <Route path="app-top-bag-getter-list" caseSensitive={false} element={<AppTopBagGetterList />} />
                    <Route path="app-recent-activity" caseSensitive={false} element={<AppRecentActivity />} />
                    <Route path="app-in-my-bag" caseSensitive={false} element={<AppInMyBag />} />
                    <Route path="app-my-wallet" caseSensitive={false} element={<AppMyWallet />} />
                    <Route path="app-change-password" caseSensitive={false} element={<AppChangePassword />} />
                    <Route path="app-my-profile" caseSensitive={false} element={<AppMyProfile />} />
                </Route>

                <Route caseSensitive={false} element={<Layout />}>
                    <Route path="dashboard" caseSensitive={false} element={<Dashboard />} />
                    <Route path="users" caseSensitive={false} element={<Users />} />
                    <Route path="assets" caseSensitive={false} element={<Assets />} />
                    <Route path="Calculations/:calculation_name/:type?" caseSensitive={false}  element={<Calculations/>} />
                    <Route path="percentImpactScale" caseSensitive={false} element={<PercentImpactScale />} />
                    <Route path="specialChars" caseSensitive={false} element={<SpecialChars />} />
                    <Route path="albums" caseSensitive={false} element={<Album />} />
                    <Route path="posts" caseSensitive={false} element={<Post />} />
                    <Route path="comments" caseSensitive={false} element={<Comments />} />
                    <Route path="transactions" caseSensitive={false} element={<Transactions />} />
                    <Route path="activitylist" caseSensitive={false} element={<ActivityLog />} />
                    <Route path="comment-list" caseSensitive={false} element={<CommentByPost />} />
                    <Route path="settings" caseSensitive={false} element={<Setting />} />
                    <Route path="contacts" caseSensitive={false} element={<Contacts />} />
                </Route>

                <Route path="*" element={<Error404 />} />
            </Routes>
        </Context.Provider>
    )
}

export default  Routing