import React, { useState, } from "react";
import { DataList} from "../../components/DataList";
import { APP_BLOG, POST_STATUS, ASSET_ENDPOINT, APP_LATEST_NEWS } from "../../components/Service";
import { noImageHandle, __echoText, statusChange } from "../../components/Helper";
import  { now } from "lodash"
import { useEffect } from "react";

const AppPost = () => {
    const [refresh, setRefresh] = useState(now)
    const [type, setType] = useState('blog')

    const dt = {
        id: 'comments',
        fetchurl: APP_BLOG,
        columns: [
            { name: 'image', title: 'Posts', width: 50, sorting: false },
            { name: 'title', sorting: false, width: 400},
            { name: 'first_name', sorting: false, width: 200},
            { name: 'created_at', sorting: false },
            { name: 'action', sorting: false },
        ],
        loading: false
    }

    const changeType = (type, setDt) => {
        setDt(prevState => ({
            ...prevState,
            fetchurl: type === 'blog' ? APP_BLOG : APP_LATEST_NEWS,
        }))
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setRefresh(now)
          }, 300000);
        
          return () => clearInterval(interval);
    }, [])

    return (
        <div className="row justify-content-center mt-4">
            <DataList
                dt={dt}
                refresh={refresh}
                searching={false}
                pagelength={false}
                outerbutton={(records, setDt) =>
                    <div className="">
                        <div className="dropdown">
                            <button type="button" className={`btn ${type === 'blog' ? 'btn-active' : 'bg-white'} rounded-pill text-nowrap text-blue me-2`} onClick={ () => {setType('blog'); changeType('blog', setDt)} }>
                                Blogs
                            </button>
                            <button type="button" className={`btn ${type === 'news' ? 'btn-active' : 'bg-white'} rounded-pill text-nowrap text-blue me-2`} onClick={ () => {setType('news'); changeType('news', setDt)} }>
                                News
                            </button>
                        </div>
                    </div>
                }
                edit_column={{
                    image: (records) =>
                        // eslint-disable-next-line jsx-a11y/img-redundant-alt
                        <img src={`${ASSET_ENDPOINT}${records.image}`} alt='post-image' className="wh-100 rounded shadow-sm objfit-cover bg-light" onError={noImageHandle}/>,

                    first_name: (records) =>
                        <> { type === 'blog' && <><p className="mb-0"><strong>Author Name: </strong>{ __echoText(`${records.first_name} ${records.last_name}`) }</p>
                        <p className="mb-0"><strong>Likes: </strong>{ records.like }</p>
                        <p className="mb-0"><strong>Views: </strong>{ records.view }</p>
                        <p className="mb-0"><strong>Comments: </strong>{ records.comment }</p>
                        </> } </>,

                    title: (records) =>
                        <>
                        <span className="fw-bold">{ records.title}</span> 
                        { type === 'blog' && records.description && <span dangerouslySetInnerHTML={ records.description.length > 30 ? { __html: records.description.substring(0, 300) + '...'} : { __html: records.description}} ></span> }
                        </>,

                    status: (records) =>
                        <span className={`badge  bg-${ records.status === 'published' ? 'success' : 'warning' } rounded-pill fw-medium minw-70`} role="button" onClick={() => statusChange(POST_STATUS, records.id, records.status === 'draft' ? 'published' : 'draft', setRefresh)} >{ records.status === 'published' ? 'Published' : 'Draft' }</span>,

                    created_at: (records) => records.timeElapsed,

                    action: (records) =>
                        <div className="d-flex">
                            {records.url && <a href={records.url} target="_blank" rel="noreferrer"><svg className="icon fs-4" role="img"><use href="#icon_eye" /></svg></a>}
                            {/* <Link to ={`/comment-list`} state={{ id:records.id}} className="btn btn-sm text-primary"  title="Comments" >
                                <svg className="icon fs-4" role="img"><use href="#icon_chat" /></svg>
                            </Link> */}
                        </div>
                }}
            />
        </div>
    )
}

export default AppPost