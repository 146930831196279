import React, { useState, useEffect } from "react";
import { DataList } from "../../components/DataList";
import { fetchData, ASSET_LIST, ASSET_CREATE, ASSET_GET, ASSET_UPDATE, ASSET_STATUS, ASSET_ENDPOINT, ETF_LIST } from "../../components/Service";
import { noImageHandle, __echoText, toastNotify, validateForm, blobToBase64, statusChange, initialFormState,isJsonString } from "../../components/Helper";
import _, { now } from "lodash"
import Select from "react-select"

function Assets() {
    window.document.title = "Assets";
    const [refresh, setRefresh] = useState(now)
    const [offcanvastitle, setOffcanvastitle] = useState("Add New Asset")
    const [etf, setEtf] = useState([])
    const [defaultEtf, setDefaultEtf] = useState('')
    const [dynamicFields, setDynamicFields] = useState()
    const [inputFieldsAlias, setInputFieldsAlias] = useState([
        { name: 'alias[]', type: 'text', label: "Alias Name", placeholder: 'Enter Alias Name', required: true, defaultValue: '', className: 'form-control mb-2' }
    ])

    const [classNameLabel, setClassNameLabel] = useState()
    const [formfield, setFormfield] = useState({
        id: "",
        etf_ids: "",
        name: "",
        code: "",
        alias: "",
        description: "",
        initial_value: "",
        initial_stock: "",
        current_value: "",
        current_stock: "",
        image: "",
        imageb64: "",
        spotify_id: "",
        soundchart_id: ""
    })


    const dt = {
        id: 'assets',
        fetchurl: ASSET_LIST,
        columns: [
            { name: 'id', title: 'Id' },
            { name: 'image', title: 'Image', width: 50 },
            { name: 'name', title: 'Name' },
            { name: 'alias', title: 'Alias' },
            { name: 'etf', title: 'ETF', width: 500 },
            { name: 'code', title: 'Code' },
            { name: 'initial_value', title: 'Initial Value / Stock', width: 100 },
            { name: 'initial_stock', title: 'Initial Stock' },
            { name: 'current_value', title: 'Current Value / Stock' },
            { name: 'current_stock', title: 'Current Stock' },
            { name: 'status', title: 'Status' },
            { name: 'created_at', title: 'Date & Time', type: 'datetime' },
            { name: 'action', title: 'Action' },
        ]
    }
    const selectedEtfs = (etf) => {
        setClassNameLabel('w-100 mb-3')
        var inputField = [];
        etf.map((data) => {
          return  inputField.push({ errorname: data.label, name: 'weight_' + data.value, weightedPercentage: data.WeightedPercentage, type: 'number', label: data.label + "(%)", step: 0.01, placeholder: 'Enter Weghted %', required: true, defaultValue: (data.WeightedPercentage) ? data.WeightedPercentage : '', className: 'form-control mb-2' })
        });
        setDynamicFields(inputField)
        setDefaultEtf(etf);
    }
    useEffect(() => {
        fetchData(ETF_LIST + '/backend', 'GET', '', false, false, (res) => {
            if (res.records) {
                setDefaultEtf([])
                setEtf(res.records)
            }
        });
    }, []);

    useEffect(() => {
        document.getElementById("addUpdateAsset").addEventListener('hidden.bs.offcanvas', function (e) {
            e.target.reset()
            e.target.classList.remove('was-validated');
            setOffcanvastitle("Add New Asset");
            setFormfield({ /*Set empty state*/
                id: "",
                etf_ids: "",
                name: "",
                code: "",
                alias: "",
                description: "",
                initial_value: "",
                initial_stock: "",
                current_value: "",
                current_stock: "",
                image: "",
                imageb64: "",
                spotify_id: "",
                soundchart_id: ""
            })
        });
    }, [refresh])

    const addAsset = async (e) => {
        let formdata = new FormData(document.getElementById('addUpdateAsset'));
        let id = document.querySelector('#addUpdateAsset [name="id"]').value;

        if (validateForm(e)) {
            fetchData(id ? ASSET_UPDATE : ASSET_CREATE, 'POST', formdata, true, true, (res) => {
                toastNotify(res)

                if (res.success) {
                    document.querySelector('#addUpdateAsset [data-bs-dismiss="offcanvas"]').click();
                }
                setRefresh(now)
            });
        }
    }

    const addAliasTextBox = async (e) => {
        let inputField = { name: 'alias[]', type: 'text', id: "", label: "Alias Name", placeholder: 'Enter Alias Name', required: true, defaultValue: '', className: 'form-control mb-2' }
        setInputFieldsAlias([...inputFieldsAlias, inputField]);
    }
    const createAssetModal = async (e) => {
        initialFormState('addUpdateAsset');
        setInputFieldsAlias([
            { name: 'alias[]', type: 'text', id: "", label: "Alias Name", placeholder: 'Enter Alias Name', required: true, defaultValue: '', className: 'form-control mb-2' }
        ])
        setDynamicFields()
        setDefaultEtf([])
    }


    // handle input change
    const handleInputChangeAlias = (e, index) => {
        let i = index - 1;
        const { value } = e.target;
        const list = [...inputFieldsAlias];
        list[i]['defaultValue'] = value;

        setInputFieldsAlias(list);
    };

    const deleteAlias = (i) => {
        i = i - 1;
        let newinputFieldsAlias = [...inputFieldsAlias];
        newinputFieldsAlias.splice(i, 1);
        setInputFieldsAlias(newinputFieldsAlias)
    }
    const imageSet = async (e) => {
        const imgb64 = await blobToBase64(e.target.files[0])
        setFormfield(prevState => ({
            ...prevState,
            imageb64: imgb64,
        }))
    }

    const editAsset = (getid) => {
        setOffcanvastitle("Update Asset")
        setDefaultEtf('')

        fetchData(ASSET_GET + '/' + getid, 'GET', '', true, false, (res) => {

            if (res.records) {
                let etfArr = [];
                res.records.etf_ids.forEach((item) => {
                    etfArr.push({ value: item.etf_id, label: item.name, WeightedPercentage: item.WeightedPercentage })
                })
                setDefaultEtf(etfArr)
                selectedEtfs(etfArr)  // To show Dynamic generated Input Box for ETF

                let alias = res.records.alias;
                const aliasNamestype=isJsonString(alias);
                const aliasNames = aliasNamestype===true ? JSON.parse(alias) : alias.split(",")
                
                var inputField = [];
                aliasNames.map((data) => {
                   return inputField.push({ name: 'alias[]', type: 'text', label: "Alias Name", placeholder: 'Enter Alias Name', required: true, defaultValue: data, className: 'form-control mb-2' })
                });
                setInputFieldsAlias(inputField)
                setFormfield(prevState => ({
                    ...prevState,
                    id: res.records.id,
                    etf_ids: res.records.etf_id,
                    name: res.records.name,
                    code: res.records.code,
                    alias: res.records.alias,
                    description: res.records.description,
                    initial_value: res.records.initial_value,
                    initial_stock: res.records.initial_stock,
                    current_value: res.records.current_value,
                    current_stock: res.records.current_stock,
                    image: res.records.image,
                    spotify_id: res.records.spotify_id,
                    soundchart_id: res.records.soundchart_id,
                }))
            } else {
                toastNotify(res)
            }
        }, (err) => { });
    }
    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h4 className="page-heading">Assets</h4>
                        </div>
                    </div>

                    <div className="row">
                        <DataList
                            dt={dt}
                            refresh={refresh}
                            outerbutton={(records) =>
                                <div className="ms-auto">
                                    <button type="button" className="btn btn-primary rounded-pill px-3 text-nowrap" onClick={() => { createAssetModal() }} data-bs-toggle="offcanvas" data-bs-target="#addUpdateAsset">
                                        <svg className="icon tscale-1p3 me-1" role="img" ><use href="#icon_plus" /></svg> Create Asset
                                    </button>
                                </div>
                            }
                            edit_column={{
                                etf: (records) =>
                                    __echoText(records.etf),
                                code: (records) =>
                                    <span className="badge bg-dark minw-70 py-2 fs-12">{__echoText(_.startCase(records.code))}</span>,

                                image: (records) =>
                                    <img src={`${ASSET_ENDPOINT}${records.image}`} alt='asset' className="wh-50 rounded shadow-sm objfit-cover bg-light" onError={noImageHandle} />,

                                status: (records) =>
                                    <span className={`badge  bg-${records.status === 'active' ? 'success' : (records.status === 'inactive' ? 'danger' : 'warning')} rounded-pill fw-medium minw-70`} role="button" onClick={() => statusChange(ASSET_STATUS, records.id, records.status !== 'active' ? 'active' : 'inactive', setRefresh)} >{records.status === 'active' ? 'Active' : (records.status === 'inactive' ? 'Disabled' : 'Pending')}</span>,

                                action: (records) =>
                                    <div className="d-flex">
                                        <button className="btn btn-sm text-primary" data-bs-toggle="modal" data-bs-target="#view-asset" title="View" onClick={() => editAsset(records.id)}>
                                            <svg className="icon fs-4" role="img"><use href="#icon_eye" /></svg>
                                        </button>
                                        <button className="btn btn-sm text-primary" data-bs-toggle="offcanvas" data-bs-target="#addUpdateAsset" title="Edit" onClick={() => editAsset(records.id)}  >
                                            <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
                                        </button>
                                    </div>
                            }}
                        />
                    </div>
                </div>
            </section>

            {/* Add Update Form  */}
            <form className="offcanvas offcanvas-end needs-validation " tabIndex="-1" id="addUpdateAsset" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="m-0 text-blue">{offcanvastitle}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-3 p-sm-4">
                    <input type="text" className="d-none" name="id" defaultValue={formfield.id} />
                    <label className="image-uploading d-flex align-items-center mb-3">
                        <img src={!formfield.imageb64 ? ASSET_ENDPOINT + '/' + formfield.image : formfield.imageb64} alt="blog_image" className="me-3" onError={noImageHandle} />
                        <button type="button" className="btn btn-primary text-nowrap">Upload Image </button> <strong className="text-danger mt-n1">*</strong>
                        <input type="file" accept=".jpg,.jpeg,.png" className="form-field" name="image" role="button" title="Upload Image" onChange={async (e) => setFormfield(prevState => ({
                            ...prevState,
                            imageb64: imageSet(e),
                        }))} required={offcanvastitle === "Add New Asset" ? true : false} />
                        <div className="invalid-feedback">Required to upload image.</div>
                    </label>
                    <label className="w-100 mb-2">
                        <div className="mb-1 fw-medium text-blue small">ETF
                        {/* <strong className="text-danger">*</strong> */}
                        </div>
                        {defaultEtf && <Select
                            onChange={e => selectedEtfs(e)}
                            value={defaultEtf}
                            isMulti
                            name="etf_ids[]"
                            options={etf}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Select ETFs"
                            // required
                        />}
                        <div className="invalid-feedback">ETF is required.</div>
                    </label>
                    <label className={classNameLabel}>
                        {(dynamicFields) && dynamicFields.map((item, i) => (
                            <div>
                                <div key={`${item.name}`}>
                                    <div className="form-label text-blue small fw-medium mb-2">{`${item.label}`}<strong className="text-danger">*</strong></div>
                                    <input type={`${item.type}`} className={`${item.className}`} name={`${item.name}`} placeholder={`${item.label}`} defaultValue={(item.defaultValue) ? item.defaultValue : dynamicFields.name} step={`${item.step}`} required />
                                    <div className="invalid-feedback">{`${item.errorname}`} is required.</div>
                                </div>
                            </div>
                        ))}
                    </label>
                    <label className="w-100 mb-3">
                        <div className="form-label text-blue small fw-medium mb-1">Name<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="name" placeholder="Enter Asset Name" spellCheck="false" defaultValue={formfield.name} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, name: e.target.value
                        }))} required />
                        <div className="invalid-feedback">Name is required.</div>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="form-label text-blue small fw-medium mb-1">Code<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="code" placeholder="Enter Code" spellCheck="false" defaultValue={formfield.code} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, code: e.target.value
                        }))} required />
                        <div className="invalid-feedback">Code is required.</div>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="form-label text-blue small fw-medium mb-2">Alias Names<strong className="text-danger">*</strong>
                            <div className="rounded-circle border plus-div" title="Add Alias" onClick={(e) => addAliasTextBox(e)}><svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg></div> </div>
                        {(inputFieldsAlias) && inputFieldsAlias.map((item, i) => (
                            <div>
                                <div key={i}>
                                    <div className="form-label text-blue small fw-medium mb-1">{`${item.label}`} {i = i + 1}<strong className="text-danger">*</strong></div>
                                    <div className="input-group mb-3">
                                        <input type={`${item.type}`} name={`${item.name}`} id={"alias-" + i} className="form-control" placeholder={`${item.label + " " + i}`} value={(item.defaultValue) ? item.defaultValue : ''} required onChange={e => handleInputChangeAlias(e, i)} />

                                        <div className="input-group-append">
                                            <span className="input-group-text addOnText" onClick={() => { deleteAlias(i) }} id={"del-alias-" + i}><svg className="icon fs-5 text-danger" role="img"><use href="#icon_trash" /></svg></span>
                                        </div>
                                        <div className="invalid-feedback">Alias Name {i} is required.</div>
                                    </div>
                                </div>

                            </div>
                        ))}

                    </label>
                    <label className="w-100 mb-3">
                        <div className="form-label text-blue small fw-medium mb-1">Initial Value / Stock<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="initial_value"  min="0" placeholder="Enter Initial Value For Single Stock" spellCheck="false" defaultValue={formfield.initial_value} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, initial_value: e.target.value
                        }))} required />
                        <div className="invalid-feedback">Initial Value / Stock is required / It should be Number Only.</div>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="form-label text-blue small fw-medium mb-1">Initial Stock<strong className="text-danger">*</strong></div>
                        <input type="number" className="form-control" name="initial_stock" min="0" placeholder="Enter Initial Stock" spellCheck="false" defaultValue={formfield.initial_stock} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, initial_stock: e.target.value
                        }))} required />
                        <div className="invalid-feedback">Initial Stock is required / It should be Number Only.</div>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="form-label text-blue small fw-medium mb-1">Spotify ID<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="spotify_id" min="0" placeholder="Spotify ID" spellCheck="false" defaultValue={formfield.spotify_id} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, spotify_id: e.target.value
                        }))} required />
                        <div className="invalid-feedback">Spotify ID is required.</div>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="form-label text-blue small fw-medium mb-1">Soundcharts UUID<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="soundchart_id" min="0" placeholder="Soundcharts UUID" spellCheck="false" defaultValue={formfield.soundchart_id} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, soundchart_id: e.target.value
                        }))} required />
                        <div className="invalid-feedback">Soundcharts UUID is required.</div>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Description<strong className="text-danger">*</strong></div>
                        <textarea className="form-control form-field" name="description" spellCheck={false} rows={5} defaultValue={formfield.description} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, description: e.target.value
                        }))} required></textarea>
                        <div className="invalid-feedback">Description is required.</div>
                    </label>

                </div>
                <div className="offcanvas-footer border-top bg-light p-3 px-sm-4">
                    <button type="button" className="btn btn-primary rounded-pill px-4 me-2" onClick={(e) => addAsset(e)} >Submit</button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Close">Cancel</button>
                </div>
            </form>

            {/* Asset View Modal  */}
            <div className="modal fade bd-example-modal-lg" id="view-asset" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header border-bottom bg-light px-sm-4">
                            <h5 className="m-0 text-blue">Asset Details</h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-3 p-sm-4">
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="image-uploading d-flex align-items-center mb-3">
                                        <img src={ASSET_ENDPOINT + '/' + formfield.image} alt="blog_image" className="me-3" onError={noImageHandle} />
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="w-100 mb-3">
                                                <div className="mb-1 fw-medium text-blue small">Name</div>
                                                <div>{__echoText(_.startCase(formfield.name))}</div>
                                            </label>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="w-100 mb-3">
                                                <div className="mb-1 fw-medium text-blue small">Code</div>
                                                <div><span className="badge bg-dark minw-70 py-2 fs-12">{__echoText(_.startCase(formfield.code))}</span></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="w-100 mb-3">
                                                <div className="mb-1 fw-medium text-blue small">Spotify ID</div>
                                                <div>{__echoText(formfield.spotify_id)}</div>
                                            </label>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="w-100 mb-3">
                                                <div className="mb-1 fw-medium text-blue small">Soundcharts UUID</div>
                                                <div>{__echoText(formfield.soundchart_id)}</div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <label className="w-100 mb-3">
                                        <div className="mb-1 fw-medium text-blue small">Alias</div>
                                        <div>{formfield.alias ? formfield.alias : '-'}</div>
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <label className="w-100 mb-3">
                                        <div className="mb-1 fw-medium text-blue small">Description</div>
                                        <div>{formfield.description ? formfield.description : '-'}</div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*End Post View Modal  */}
        </>
    )
}

export default Assets
