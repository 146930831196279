import { useState } from "react";
import { DataList } from "../../components/DataList";
import { fetchData, USER_LIST, USER_STATUS, ASSET_ENDPOINT, USER_DETAILS, MY_BAG } from "../../components/Service";
import { noImageHandle, __echoText, statusChange, verifyEmailPhone, floatNumber, currencyFormat } from "../../components/Helper";
import _, { now } from "lodash"

function Users() {
    window.document.title = "Users";

    const [refresh, setRefresh] = useState(now)
    const [userInfo, setUserInfo] = useState()
    const [userBagInfo, setUserBagInfo] = useState()

    const dt = {
        id: 'users',
        fetchurl: USER_LIST,
        columns: [
            { name: 'id', title: 'Id' },
            { name: 'profile_image', title: 'Image', width: 50, sorting: false },
            { name: 'username', title: 'Username' },
            { name: 'first_name', title: 'Full Name' },
            { name: 'email', title: 'Email' },
            { name: 'phone', title: 'Phone' },
            { name: 'wallet_amount', title: 'Wallet Amount' },
            { name: 'email_verified_at', title: 'Email Verified' },
            { name: 'phone_verified_at', title: 'Phone Verified' },
            { name: 'status', title: 'Status' },
            { name: 'created_at', title: 'Date & Time', type: 'datetime', width: '200' },
            { name: 'action', title: 'Action' },
        ]
    }

    const getUserInfo = (user_id) => {
        fetchData(USER_DETAILS + '/' + user_id, 'GET', '', true, false, (res) => {
            if (res.records) {
                setUserInfo(res.records)
            }
        });
    }
    const getUserBagInfo = (user_id) => {
        fetchData(MY_BAG + '/' + user_id, 'GET', '', true, false, (res) => {
            if (res.records) {
                setUserBagInfo(res.records)
            }
            else{
                setUserBagInfo('')
            }
        });
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h4 className="page-heading">Users</h4>
                        </div>
                    </div>
                    <div className="row">
                        <DataList
                            dt={dt}
                            refresh={refresh}
                            edit_column={{
                                profile_image: (records) =>
                                    <img src={`${ASSET_ENDPOINT}${records.profile_image}`} alt='profile' className="wh-50 rounded shadow-sm objfit-cover bg-light" onError={noImageHandle} />,

                                first_name: (records) =>
                                    `${records.first_name !== 'null' && __echoText(records.first_name)} ${records.last_name !== 'null' && __echoText(records.last_name)}`,

                                email_verified_at: (records) =>
                                    <>
                                        {records.email_verified_at ? <span className="text-success">Yes</span> :
                                            <span className="badge  danger rounded-pill fw-medium minw-70" onClick={() => verifyEmailPhone(records.id, 'email', setRefresh)} >Verify</span>
                                        }
                                    </>,

                                phone_verified_at: (records) =>
                                    <>
                                        {records.phone_verified_at ? <span className="text-success">Yes</span> :
                                            <span className="badge  danger rounded-pill fw-medium minw-70" onClick={() => verifyEmailPhone(records.id, 'phone', setRefresh)} >Verify</span>
                                        }
                                    </>,

                                status: (records) =>
                                    <span className={`badge  bg-${records.status === 'active' ? 'success' : (records.status === 'inactive' ? 'danger' : 'warning')} rounded-pill fw-medium minw-70`} role="button" onClick={() => statusChange(USER_STATUS, records.id, records.status !== 'active' ? 'active' : 'inactive', setRefresh)} >{records.status === 'active' ? 'Active' : (records.status === 'inactive' ? 'Disabled' : 'Pending')}</span>,

                                action: (records) =>
                                    <div className="d-flex">
                                        <button className="btn btn-sm text-success" title="Check Bag" data-bs-toggle="modal" data-bs-target="#check-bag" onClick={() => getUserBagInfo(records.id)}>
                                            <svg className="icon fs-4" role="img"><use href="#icon_work" /></svg>
                                        </button>
                                        <button className="btn btn-sm text-primary" title="View" data-bs-toggle="modal" data-bs-target="#check-details" onClick={() => getUserInfo(records.id)}>
                                            <svg className="icon fs-4" role="img"><use href="#icon_eye" /></svg>
                                        </button>
                                    </div>
                            }}
                        />
                    </div>
                </div>
            </section>

            {/* Post View Modal  */}
            <div className="modal fade bd-example-modal-lg" id="check-bag" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header border-bottom bg-light px-sm-4">
                            <h5 className="m-0 text-blue">Bag Info</h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-3 p-sm-4">
                            <div className="row">
                                <div className="col-md-12">
                                     {(userBagInfo) ?
                                        <div>
                                            <div className="row">
                                                <div className="col-md-6 text-end"><h6>Available Stocks:</h6></div>
                                                <div className="col-md-6"><h6>{floatNumber(userBagInfo.available_stock, 4)}</h6></div>
                                                <div className="col-md-6 text-end"><h6>Invested Value:</h6></div>
                                                <div className="col-md-6"><h6>{currencyFormat(userBagInfo.invested_value)}</h6></div>
                                                <div className="col-md-6 text-end"><h6 className={`text-${userBagInfo.trend === 'up' ? 'success' : 'danger'}`}>Current Value:</h6></div>
                                                <div className="col-md-6"><h6 className={`text-${userBagInfo.trend === 'up' ? 'success' : 'danger'}`}>{currencyFormat(userBagInfo.current_value)}</h6></div>
                                                <div className="col-md-6 text-end"><h6 className={`text-${userBagInfo.trend === 'up' ? 'success' : 'danger'}`}>Stock Amount Change:</h6></div>
                                                <div className="col-md-6"><h6 className={`text-${userBagInfo.trend === 'up' ? 'success' : 'danger'}`}>{userBagInfo.trend === 'up' ? '+' : '-'} {floatNumber(userBagInfo.stock_change)} ({userBagInfo.trend === 'up' ? '+' : '-'} {floatNumber(userBagInfo.stock_change_percent)}%)</h6></div>
                                                <div className="col-md-6 text-end"><h6 className={`text-${userBagInfo.trend === 'up' ? 'success' : 'danger'}`}>Trend: </h6></div>
                                                <div className="col-md-6 "><h6 className={`text-${userBagInfo.trend === 'up' ? 'success' : 'danger'}`}> {(userBagInfo.trend)}</h6></div>
                                            </div>
                                        </div>
                                        : "No Data Found"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*End Post View Modal  */}

            {/* User View Modal  */}
            <div className="modal fade bd-example-modal-lg" id="check-details" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header border-bottom bg-light px-sm-4">
                            <h5 className="m-0 text-blue">User Details</h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-3 p-sm-4">
                            {userInfo && <>
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="image-uploading d-flex align-items-center mb-3">
                                            <img src={ASSET_ENDPOINT + '/' + userInfo.profile_image} alt="blog_image" className="me-3" onError={noImageHandle} />
                                        </label>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="w-100 mb-3">
                                                    <div className="mb-1 fw-medium text-blue small">Username</div>
                                                    <div>{userInfo.username}</div>
                                                </label>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="w-100 mb-3">
                                                    <div className="mb-1 fw-medium text-blue small">Email</div>
                                                    <div>{userInfo.email}</div>
                                                </label>
                                            </div>
                                            <div className="col-md-2">
                                                <label className="w-100 mb-3">
                                                    <div className="mb-1 fw-medium text-blue small">Gender</div>
                                                    <div>{__echoText(_.startCase(userInfo.gender))}</div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="w-100 mb-3">
                                                    <div className="mb-1 fw-medium text-blue small">First Name</div>
                                                    <div>{__echoText(userInfo.first_name)}</div>
                                                </label>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="w-100 mb-3">
                                                    <div className="mb-1 fw-medium text-blue small">Middle Name</div>
                                                    <div>{__echoText(userInfo.middle_name)}</div>
                                                </label>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="w-100 mb-3">
                                                    <div className="mb-1 fw-medium text-blue small">Last Name</div>
                                                    <div>{__echoText(userInfo.last_name)}</div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label className="w-100 mb-3">
                                            <div className="mb-1 fw-medium text-blue small">Bio</div>
                                            <div>{__echoText(userInfo.bio)}</div>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label className="w-100 mb-3">
                                            <p className="mb-1"><span className="fw-medium text-blue small">Address Line 1: </span>{__echoText(userInfo.address_line_1)}</p>
                                            <p className="mb-1"><span className="fw-medium text-blue small">Address Line 2: </span>{__echoText(userInfo.address_line_2)}</p>
                                            <p className="mb-1"><span className="fw-medium text-blue small">City: </span>{__echoText(userInfo.city)}</p>
                                            <p className="mb-1"><span className="fw-medium text-blue small">State: </span>{__echoText(userInfo.state)}</p>
                                            <p className="mb-1"><span className="fw-medium text-blue small">Country: </span>{__echoText(userInfo.country)}</p>
                                            <p><span className="fw-medium text-blue small">Zipcode: </span>{__echoText(userInfo.zipcode)}</p>
                                        </label>
                                    </div>
                                </div>
                                {/* { userInfo.address_proof_number && userInfo.id_proof_number &&
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="w-100 mb-3">
                                        <div className="mb-1 fw-medium text-blue small">Address Proof</div>
                                        <span className="badge bg-dark minw-70 py-2 fs-12 mb-3">{ __echoText(userInfo.address_proof_number) }</span>
                                        <label className="image-uploading d-flex align-items-center mb-3">
                                            <img src={ ASSET_ENDPOINT + '/' + userInfo ? userInfo : '' } alt="blog_image" className="me-3" onError={noImageHandle} />
                                        </label>
                                    </label>
                                    <label className="w-100 mb-3">
                                        <span className={`badge  bg-${ userInfo && userInfo.address_proof_number_verified_at ? 'success' : 'danger' } rounded-pill fw-medium minw-70`} onClick={() => userInfo && !userInfo.address_proof_number_verified_at && verifyProof(userInfo.id, 'address', getUserInfo)} >{ userInfo && userInfo.address_proof_number_verified_at ? 'Verified at ' + format(new Date(userInfo.address_proof_number_verified_at), "dd MMM, yyyy HH:mm:ss") : 'Verify' }</span>
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <label className="w-100 mb-3">
                                        <div className="mb-1 fw-medium text-blue small">Identity Proof</div>
                                        <span className="badge bg-dark minw-70 py-2 fs-12 mb-3">{ userInfo && userInfo.id_proof_number ? userInfo.id_proof_number: '' }</span>
                                        <label className="image-uploading d-flex align-items-center mb-3">
                                            <img src={ ASSET_ENDPOINT + '/' + userInfo ? userInfo : '' } alt="blog_image" className="me-3" onError={noImageHandle} />
                                        </label>
                                    </label>
                                    <label className="w-100 mb-3">
                                        <span className={`badge  bg-${ userInfo && userInfo.id_proof_number_verified_at ? 'success' : 'danger' } rounded-pill fw-medium minw-70`} onClick={() => userInfo && !userInfo.id_proof_number_verified_at && verifyProof(userInfo.id, 'indentity', getUserInfo)} >{ userInfo && userInfo.id_proof_number_verified_at ? 'Verified at ' + format(new Date(userInfo.id_proof_number_verified_at), "dd MMM, yyyy HH:mm:ss") : 'Verify' }</span>
                                    </label>
                                </div>
                            </div> } */}
                            </>}
                        </div>
                    </div>
                </div>
            </div>
            {/*End User View Modal  */}
        </>
    )
}

export default Users
