
import { loadingData } from "./Helper"

export const ENDPOINT = process.env.REACT_APP_ENDPOINT
export const ASSET_ENDPOINT = process.env.REACT_APP_ASSET_ENDPOINT

export const LOGIN = "/login"
export const SIGNUP = "/signup"
export const GET_AUTH_USER_INFO = "/get-auth-user"
export const LOGOUT = "/logout"
export const VERIFY_EMAIL = "/verify-email"
export const VERIFY_EMAIL_PHONE = "/user-verify-email-phone"
export const VERIFY_PROOF = "/user-verify-proof"

export const CHANGE_PASSWORD = "/change-password"
export const MY_PROFILE = "/my-profile"
export const UPDATE_PROFILE = "/update-profile"
export const UPDATE_PROFILE_IMAGE = "/change-profile-image"

export const FORGOT_PASSWORD = "/forgot-password"
export const RESET_PASSWORD = "/reset-password"

export const ADD_USER = "/signup"
export const USER_LIST = "/user-list"
export const USER_DETAILS = "/user-details"
export const USER_DELETE = "/user-delete"
export const USER_STATUS = "/user-status"

export const WALLET_DETAILS = "/wallet-details"

export const ETF_LIST = "/etf-list"

export const CHAR_LIST = "/char-list"
export const CHAR_CREATE = "/char-create" 
export const CHAR_GET = "/char-details"
export const CHAR_UPDATE = "/char-update"
export const CHAR_DELETE = "/char-delete"

export const PERCENT_IMPACT_SCALE_LIST = "/percent-scale-list"
export const PERCENT_IMPACT_SCALE_CREATE = "/percent-scale-create" 
export const PERCENT_IMPACT_SCALE_GET = "/percent-scale-details"
export const PERCENT_IMPACT_SCALE_UPDATE = "/percent-scale-update"
export const PERCENT_IMPACT_SCALE_DELETE = "/percent-scale-delete"

export const CALCULATIONS_LIST = "/calculations-list"
export const RANK_LIST = "/ranks-list"
export const CALCULATIONS_CREATE = "/calculations-create" 
export const CALCULATIONS_GET = "/calculations-details"
export const CALCULATIONS_UPDATE = "/calculations-update"
export const CALCULATIONS_DELETE = "/calculations-delete"

export const ASSET_LIST = "/asset-list"
export const ASSET_CREATE = "/asset-create" 
export const ASSET_GET = "/asset-details"
export const ASSET_UPDATE = "/asset-update"
export const ASSET_DELETE = "/asset-delete"
export const ASSET_STATUS = "/asset-status"
export const ASSET_PLATFORM_GET = "/asset-platform-info"
export const ASSET_PLATFORM_UPDATE = "/asset-platform-info-update"
export const ASSET_PERFOMANCE_GET = "/asset-performance"
export const ASSET_PERFOMANCE_GRAPH_ETFWISE_GET = "/asset-performance-etf"
export const ASSET_PERFOMANCE_DETAIL_GET = "/asset-performance-detail"
export const ASSET_PRICING_GET = "/asset-pricing"
export const ALL_ASSET_LIST = "/all-asset-list"

export const POST_LIST = "/post-list"
export const POST_CREATE = "/post-create"
export const POST_GET = "/post-details"
export const POST_UPDATE = "/post-update"
export const POST_DELETE = "/post-delete"
export const POST_STATUS = "/post-status"
export const COMMENT_LIST = "/comment-list-for-post"

export const POST_SOCIAL_LIST = "/post-social-list"
export const POST_SOCIAL_GET = "/post-social-details"
export const POST_SOCIAL_UPDATE = "/post-social-update"
export const POST_SOCIAL_DELETE = "/post-social-delete"
export const POST_SOCIAL_STATUS = "/post-social-status"

export const ADD_NEWS = "/add-news"
export const LIST_NEWS = "/list-news"

export const TRANSACTION_LIST = "/transaction-list"
export const LOG_LIST = "/log-list"
export const DASHBOARD_STATICS = "/statistics"

export const GET_SETTINGS = "/get-settings"
export const UPDATE_SETTINGS = "/update-settings"

export const APP_TOP_TRENDING_NEWS = "/top-trending-news-list"
export const APP_LATEST_NEWS = "/latest-news-list"
export const APP_BLOG = "/blog-list"
export const APP_TOP_BAG_GETTER = "/top-bag-getters-list"
export const APP_ASSET_LIST = "/top-asset-list" 
export const FAV_ASSET_LIST = "/favourite-asset-list"
export const ADD_FAV_ASSET_LIST = "/asset-add-to-favourite"
export const RECENT_ACTIVITY = "/recent-activity"
export const MY_ASSETS = "/my-asset-list"
export const MY_BAG = "/my-bag"
export const MY_WALLET = "/my-wallet"
export const MY_WALLET_HISTORY = "/my-wallet-recharge-log"
export const APP_ASSET_BUY = "/buy"
export const APP_ASSET_SELL = "/sell"

export const ADD_CONTACT = "/contact-add"
export const CONTACT_LIST = "/contact-list"
export const CONTACT_CHANGE_STATUS = "/contact-status-change"



export const fetchData = async (url, method, data, token, process, res, err, processimg=false, loading=true) => {
    
    loadingData(loading)
    let headers = {
        'Accept': 'application/json',
    }
    if (process) {
        headers = { ...headers, 'contentType': false, 'processData': false }
    } else {
        headers = { ...headers, 'Content-Type': 'application/json' }
    }
    if (token) {
        let TOKEN = '';
        TOKEN = JSON.parse(localStorage.getItem("stock-market"));
        headers = { ...headers, 'Authorization': 'Bearer ' + TOKEN }
    }
    let request = {
        'method': method,
        'headers': headers,
    }
    if (data) {
        request = { ...request, 'body': process ? data : JSON.stringify(data) }
    }

    await fetch(`${ENDPOINT}${url}`, request).then((response) => processimg === "text" ? response.text() : (processimg === "blob"? response.blob(): response.json() )).then((json) => {
        loadingData(false)     
        if (json.message === "Unauthenticated.") {
            localStorage.removeItem("stock-market");                      
        } else {     
            res(json)
        }
    })
    .catch((error) => { });
}