import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Context } from '../../components/Context';
import { ButtonSpinner, toastNotify, validateForm } from '../../components/Helper';
import { fetchData, SIGNUP } from '../../components/Service';

function Signup(){
    const navigate = useNavigate();
    const [tinyloader, setTinyloader] = useState(false);
    const [context] = useContext(Context)
    const [token] = useState(JSON.parse(localStorage.getItem("stock-market")));

    const changeHandler = (e) => {
        if (e.keyCode === 13) {
            loginSubmission(e)
        }
    }
    
    const loginSubmission = (e) => {
        let formData = new FormData(document.getElementById('signupForm'));

        if(validateForm(e)) {
            setTinyloader(true);
            fetchData(SIGNUP, 'POST', formData, false, true, (res) => {
                setTinyloader(false);
                toastNotify(res)

                if (res.success) {
                    navigate(`/login`);
                }
            });
        };
    }

    useEffect(() => {
        if (token === (context && context.auth && context.auth.access_token)) {
            navigate(`/dashboard`);
        }
    }, [token, navigate, context])

    return(
        <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-5 py-3">
                        <form className="bg-white rounded-15 p-3 p-sm-4 p-sm-5 shadow-sm needs-validation" id="signupForm" noValidate>
                            <div className="text-center">
                                <img src="/logo.png" alt="stock" className="w-100 maxw-280" width="280" height="70.81"/>
                            </div>
                            <hr className="text-muted opacity-10 mx-auto"/>
                            <h4 className="text-center my-3">Sign Up</h4>
                            <label className="input-group input-group-lg input-group-rounded mb-3">
                                <span className="input-group-text">
                                    <svg className="icon tscale-1p3 opacity-50" role="img" aria-label="more">
                                        <use href="#icon_user" />
                                    </svg>
                                </span>
                                <input type="text" className="form-control rounded-pill-end-topbottom" onKeyUp={changeHandler} name="username" placeholder="Username *" autoComplete="username" spellCheck={false} defaultValue={""} required/>
                                <div className="invalid-feedback ps-70">
                                    This is required field.
                                </div>
                            </label>
                            <label className="input-group input-group-lg input-group-rounded mb-3">
                                <span className="input-group-text">
                                    <svg className="icon tscale-1p3 opacity-50" role="img" aria-label="more">
                                        <use href="#icon_user" />
                                    </svg>
                                </span>
                                <input type="email" className="form-control rounded-pill-end-topbottom" onKeyUp={changeHandler} name="email" placeholder="Email *" autoComplete="email" spellCheck={false} defaultValue={""} required/>
                                <div className="invalid-feedback ps-70">
                                    This is required field.
                                </div>
                            </label>
                            <label className="input-group input-group-lg input-group-rounded mb-3">
                                <span className="input-group-text">
                                    <svg className="icon tscale-1p2 opacity-50" role="img" aria-label="more">
                                        <use href="#icon_lock" />
                                    </svg>
                                </span>
                                <input type="password" className="form-control rounded-pill-end-topbottom" onKeyUp={changeHandler} name="password" placeholder="Password *" autoComplete="current-password" minLength={8} title="You have to enter at least 8 digit!" required/>
                                <div className="invalid-feedback ps-70">
                                    This is required field.
                                </div>
                            </label>
                            <label className="input-group input-group-lg input-group-rounded mb-3">
                                <span className="input-group-text">
                                    <svg className="icon tscale-1p2 opacity-50" role="img" aria-label="more">
                                        <use href="#icon_lock" />
                                    </svg>
                                </span>
                                <input type="password" className="form-control rounded-pill-end-topbottom" onKeyUp={changeHandler} name="password_confirmation" placeholder="Confirm Password *" autoComplete="current-password" minLength={8} title="You have to enter at least 8 digit!" required/>
                                <div className="invalid-feedback ps-70">
                                    This is required field.
                                </div>
                            </label>
                            <button type="button" className="btn btn-primary btn-lg w-100 rounded-pill mb-3" onClick={(e)=>loginSubmission(e)} disabled={tinyloader}>
                                <ButtonSpinner load={tinyloader} btnName="Login"/>
                            </button>
                            <div className="d-flex justify-content-between">
                                <div className="my-2">
                                    Go to <Link to="/login" className="fw-medium" title="Login">Login</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Signup