import React, { useState, useEffect } from "react";
import { DataList } from "../../components/DataList";
import { fetchData, MY_WALLET_HISTORY, MY_WALLET } from "../../components/Service";
import {  __echoText, currencyFormat, floatNumber } from "../../components/Helper";
import _, { now } from "lodash"

const AppMyWallet = () => {
    const [refresh] = useState(now)
    const [assetDt, setAssetDt] = useState(false)
    const [myWallet, setMyWallet] = useState(false)

    useEffect(() => {
        fetchData(MY_WALLET, 'GET', '', true, false, (res) => {
            if (res.records) {
                setMyWallet(res.records)
            }
        });

        setAssetDt({
            id: 'assets',
            fetchurl: MY_WALLET_HISTORY,
            columns: [
                { name: 'id', title: 'Transaction ID', sorting: false},
                { name: 'amount', title: 'Amount', sorting: false },
                { name: 'transaction_type', title: 'Type', sorting: false},
                { name: 'status', title: 'Status', sorting: false},
                { name: 'created_at', title: "Date & Time", sorting: false, type:"datetime"},
            ]
        })
    }, [])

    return (
        <div className="row justify-content-center mt-4">
            <div className="row">
                <div className="col-md-6 text-end"><h3>Wallet Amount:</h3></div>
                <div className="col-md-6"><h3>{ currencyFormat(myWallet.wallet_amount) }</h3></div>
            </div>
            <div className="row mt-5">
                <div className="col-md-6 p-0"><h3 >Wallet Transaction History</h3></div>
            </div>
            {assetDt && <DataList
                dt={assetDt}
                refresh={refresh}
                searching={false}
                pagelength={false}
                edit_column={{           
                    amount: (records) =>
                        <span>{ currencyFormat(records.amount) }</span>,

                    transaction_type: (records) =>
                        <span>{ __echoText(_.startCase(records.transaction_type)) }</span>,

                    status: (records) =>
                        <span className={`text-${records.status === 'success' ? 'success' : 'danger' }`}>{ __echoText(_.startCase(records.status)) }</span>,

                    percent: (records) =>
                        <span className={`text-${records.trend === 'up' ? 'success' : 'danger' }`}>{records.trend === 'up' ? '+' : '-' } { floatNumber(records.percentage_change) }%</span>,
                }}
            /> }
        </div>
    )
}

export default AppMyWallet


