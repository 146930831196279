import React, { useState, useEffect } from "react";
import { DataList } from "../../components/DataList";
import { RECENT_ACTIVITY, ASSET_ENDPOINT } from "../../components/Service";
import { noImageHandle, __echoText, currencyFormat, floatNumber } from "../../components/Helper";
import _, { now } from "lodash"

const AppRecentActivity = () => {
    const [refresh] = useState(now)
    const [assetDt, setAssetDt] = useState(false)

    useEffect(() => {
            setAssetDt({
                id: 'assets',
                fetchurl: RECENT_ACTIVITY,
                columns: [
                    { name: 'image', title: 'Assets', width: 50 , sorting: false},
                    { name: 'name', sorting: false},
                    { name: 'amount', sorting: false },
                    { name: 'stock', sorting: false}
                ]
            })

    }, [])

    return (
        <div className="row justify-content-center mt-4">
            {assetDt && <DataList 
                dt={assetDt}
                refresh={refresh}
                searching={false}
                pagelength={false}
                edit_column={{           
                    image: (records) =>
                        // eslint-disable-next-line jsx-a11y/img-redundant-alt
                        <img src={`${ASSET_ENDPOINT}${records.image}`} alt='asset-image' className="wh-100 rounded shadow-sm objfit-cover bg-light" onError={noImageHandle}/>,

                    name: (records) =>
                        <>
                            <div className="py-2">{ __echoText(_.startCase(records.name)) }</div>
                            <div className="badge bg-dark minw-70 py-2 fs-12">{ __echoText(_.startCase(records.code)) }</div>
                        </>,

                    amount: (records) =>
                        <span>{ currencyFormat(records.amount) }</span>,

                    stock: (records) =>
                        <span className={`text-${records.transaction_type === 'buy' ? 'success' : 'danger' }`}>{ floatNumber(records.stock, 4) }</span>,
                }}
            /> }
        </div>
    )
}

export default AppRecentActivity


