import React, { useState, useEffect } from "react";
import { DataList} from "../../components/DataList";
import { Link } from "react-router-dom";
import { fetchData, POST_CREATE, POST_LIST, POST_GET, POST_UPDATE, POST_STATUS, POST_DELETE, ASSET_ENDPOINT } from "../../components/Service";
import { noImageHandle, __echoText, toastNotify, validateForm, blobToBase64, ConfirmationModal, statusChange, deleteRecords } from "../../components/Helper";
import _, { now } from "lodash"

function Post() {
    window.document.title = "Posts";

    const [refresh, setRefresh] = useState(now)
    const [offcanvastitle, setOffcanvastitle] = useState("Add New Post")
    
    const [formfield, setFormfield] = useState({
        id: "",
        title: "",
        description: "",
        image: "",
        imageb64: "",
        post_type: ""
    })

    const dt = {
        id: 'comments',
        fetchurl: POST_LIST,
        columns: [
            { name: 'id', title: 'Id' , width: 50},
            { name: 'first_name', title: 'Author Name' },
            { name: 'post_type', title: 'Post Type', width: 50 },
            { name: 'title', title: 'Title', width: 300 },
            { name: 'description', title: 'Description' },
            { name: 'like', title: 'Likes', width: 50 },
            { name: 'view', title: 'Views', width: 50 },    
            { name: 'comment', title: 'Comments', width: 50 },
            { name: 'share', title: 'Shares', width: 50 },
            { name: 'image', title: 'Image', width: 50 },
            { name: 'status', title: 'Status', width: 50 },
            { name: 'created_at', title: 'Date & Time', type: 'datetime', width: 200},
            { name: 'action', title: 'Action' },
        ]
    }

    useEffect(() => {
        document.getElementById("addupdateBlog").addEventListener('hidden.bs.offcanvas', function (e) {
            e.target.reset()
            e.target.classList.remove('was-validated');
            setOffcanvastitle("Add New Post");
            setFormfield({
                id: "",
                title: "",
                image: "",
                imageb64: "",
            })
        });

    }, [refresh])

    const addBlog = async (e) => {
        let formdata = new FormData(document.getElementById('addupdateBlog'));
        let id = document.querySelector('#addupdateBlog [name="id"]').value;

        if (validateForm(e)) {
            fetchData(id ? POST_UPDATE : POST_CREATE, 'POST', formdata, true, true, (res) => {
                toastNotify(res);
                if (res.success) {
                    document.querySelector('#addupdateBlog [data-bs-dismiss="offcanvas"]').click();
                }
                setRefresh(now)
            });
        }
    }
  
    const imageSet = async (e) => {
        const imgb64 = await blobToBase64(e.target.files[0])
        setFormfield(prevState => ({
            ...prevState,
            imageb64: imgb64,
        }))
    }
  
    const editBlog = (getid) => {
        setOffcanvastitle("Update Post");
        fetchData(POST_GET + '/' + getid, 'GET', '', true, false, (res) => {
            if (res.records) {
                setFormfield(prevState => ({
                    ...prevState,
                    id: res.records.id,
                    title: res.records.title,
                    description: res.records.description,
                    post_type: res.records.post_type,
                    image: res.records.image,
                    url: res.records.url,
                }))
            }
            else {
                toastNotify('danger', res.error)
            }
        }, (err) => {});
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h4 className="page-heading">Posts</h4>
                        </div>
                    </div>
                    <div className="row">
                        <DataList 
                            dt={dt}
                            refresh={refresh}
                            outerbutton={(records) =>
                                <div className="ms-auto">
                                    <button type="button" className="btn btn-primary rounded-pill px-3 text-nowrap" data-bs-toggle="offcanvas" data-bs-target="#addupdateBlog">
                                        <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg> Create Post
                                    </button>
                                </div>
                            }
                            edit_column={{
                                first_name: (records) =>
                                    __echoText(`${records.first_name} ${records.last_name}`),

                                post_type: (records) =>
                                    __echoText(_.startCase(records.post_type)),

                                description: (records) =>
                                    <span dangerouslySetInnerHTML={ records.description.length > 30 ? { __html: records.description.substring(0, 30) + '...'} : { __html: records.description}} ></span> ,
                                
                                image: (records) =>
                                    <img src={`${ASSET_ENDPOINT}${records.image}`} alt='post' className="wh-50 rounded shadow-sm objfit-cover bg-light" onError={noImageHandle}/>,

                                status: (records) =>
                                    <span className={`badge  bg-${ records.status === 'published' ? 'success' : 'warning' } rounded-pill fw-medium minw-70`} role="button" onClick={() => statusChange(POST_STATUS, records.id, records.status === 'draft' ? 'published' : 'draft', setRefresh)} >{ records.status === 'published' ? 'Published' : 'Draft' }</span>,

                                action: (records) =>
                                    <div className="d-flex">
                                        <Link to ={`/comment-list`} state={{ id:records.id}} className="btn btn-sm text-primary"  title="Comments" >
                                            <svg className="icon fs-4" role="img"><use href="#icon_chat" /></svg>
                                        </Link>
                                        <button className="btn btn-sm text-primary" data-bs-toggle="modal" data-bs-target="#view-post" title="View" onClick={() => editBlog(records.id)}>
                                            <svg className="icon fs-4" role="img"><use href="#icon_eye" /></svg>
                                        </button>
                                        <button className="btn btn-sm text-primary" title="Edit" data-bs-toggle="offcanvas" data-bs-target="#addupdateBlog" onClick={() => editBlog(records.id)}>
                                            <svg className="icon fs-5" role="img"><use href="#icon_edit"/></svg>
                                        </button>
                                        <button className="btn btn-sm text-danger" title="Delete" data-bs-toggle="modal" data-bs-target="#confModal" onClick={(e) => setFormfield(prevState => ({ ...prevState, id: records.id }))}>
                                            <svg className="icon fs-5" role="img"><use href="#icon_trash"/></svg>
                                        </button>
                                    </div>
                            }}
                        />
                    </div>
                </div>
            </section>

            {/* Add Update Form  */}
            <form className="offcanvas offcanvas-end needs-validation " tabIndex="-1" id="addupdateBlog" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="m-0 text-blue">{offcanvastitle}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-3 p-sm-4">
                    <input type="text" className="d-none" name="id" defaultValue={formfield.id} />
                    <label className="image-uploading d-flex align-items-center mb-3">
                        <img src={!formfield.imageb64 ? ASSET_ENDPOINT + '/' + formfield.image : formfield.imageb64} alt="blog_image" className="me-3" onError={noImageHandle} />
                        <button type="button" className="btn btn-primary text-nowrap" >Upload Image </button> <strong className="text-danger mt-n1">*</strong>
                        <input type="file" accept=".jpg,.jpeg,.png" className="form-field" name="image" title="Upload Image" role="button" onChange={async (e) => setFormfield(prevState => ({
                            ...prevState,
                            imageb64: imageSet(e),
                        }))} required={offcanvastitle === "Add New Post" ? true : false} />
                        <div className="invalid-feedback">
                            Required to upload image.
                        </div>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Post Type<strong className="text-danger">*</strong></div>
                        <select className="form-select" name="post_type" value={formfield.post_type} onChange={(e) => setFormfield(prevState => ({
                            ...prevState, post_type: e.target.value
                        }))} required>
                            <option value="" className="d-none">Select Post Type</option>
                            <option value="blog">Blog</option>
                            <option value="news">News</option>
                        </select>
                        <div className="invalid-feedback">Post type is required.</div>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="form-label text-blue small fw-medium mb-1">Title<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="title" placeholder="Enter Title" spellCheck="false" defaultValue={formfield.title} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, title: e.target.value
                        }))} required />
                        <div className="invalid-feedback">Title is required.</div>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Description<strong className="text-danger">*</strong></div>
                        <textarea className="form-control form-field" name="description" spellCheck={false} rows={5} defaultValue={formfield.description} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, description: e.target.value
                        }))} required></textarea>
                        <div className="invalid-feedback">Description is required.</div>
                    </label>
                </div>
                <div className="offcanvas-footer border-top bg-light p-3 px-sm-4">
                    <button type="button" className="btn btn-primary rounded-pill px-4 me-2" onClick={(e) => addBlog(e)} >Submit</button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Close">Cancel</button>
                </div>
            </form>

            <ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(POST_DELETE, formfield.id, setRefresh )}/>
            
            {/* Post View Modal  */}
            <div className="modal fade bd-example-modal-lg" id="view-post" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header border-bottom bg-light px-sm-4">
                            <h5 className="m-0 text-blue">Post Details</h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-3 p-sm-4">
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="image-uploading d-flex align-items-center mb-3">
                                        <img src={ ASSET_ENDPOINT + '/' + formfield.image } alt="blog_image" className="me-3" onError={noImageHandle} />
                                    </label>
                                </div>
                                <div className="col-md-7">
                                    <label className="w-100 mb-3">
                                        <div className="mb-1 fw-medium text-blue small">Post Type</div>
                                        <div>{__echoText(_.startCase(formfield.post_type))}</div>
                                    </label>
                                    <label className="w-100 mb-3">
                                        <div className="form-label text-blue small fw-medium mb-1">Title</div>
                                        <div>{formfield.title}</div>
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <label className="w-100 mb-3">
                                        <div className="form-label small text-blue fw-medium mb-1">Url</div>
                                        <div>{formfield.url ? <a href={formfield.url} target="_blank" rel="noreferrer">{formfield.url}</a> : '-' }</div>
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <label className="w-100 mb-3">
                                        <div className="form-label small text-blue fw-medium mb-1">Description</div>
                                        <div>{formfield.description}</div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*End Post View Modal  */}
        </>
    )
}

export default Post
