import { now } from 'lodash';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useParams } from 'react-router-dom';
import { calculationName, ConfirmationModal, deleteRecords, floatNumber, floatNumber5Decimal, initialFormState, showFields, toastNotify, validateForm, _capitalize } from '../../components/Helper';
import { CALCULATIONS_CREATE, CALCULATIONS_DELETE, CALCULATIONS_GET, CALCULATIONS_LIST, CALCULATIONS_UPDATE, fetchData, RANK_LIST } from '../../components/Service';

function Calculations(props) {
    const { calculation_name, type } = useParams()
    const [refresh, setRefresh] = useState(now)
    window.document.title = calculationName(calculation_name);
    const [calculationData, setCalculationData] = useState()
    const [rankData, setRankData] = useState()
    let intialAr = [];
    let intialArObj = {}
    for (let i = 0; i < 5; i++) {
        let obj = i + 1;
        intialArObj = { name: 'rank_key', nameValue: 'rank_value', rankdId: '', type: 'number', label: "Rank Key", labelValue: "Rank Value", placeholder: 'Enter Rank Name', placeholderValue: 'Enter Rank Value', required: true, defaultValueKey: obj, defaultValueValue: '', className: 'form-control mb-2' }
        intialAr[i] = intialArObj;
    }
    let formFieldIntialState = {
        id: "",
        delId: "",
        buy: { "show": false, value: '' },
        sell: { "show": false, value: '' },
        ranks: { "show": false, value: '' },
        higher: { "show": false, value: '' },
        lower: { "show": false, value: '' },
        week_on_top: { "show": false, value: '' },
        no_of_songs: { "show": false, value: '' },
        not_on_list: { "show": false, value: '' },
        no_of_weeks: { "show": false, value: '' },
        rank_diff: { "show": false, value: '' },
        rank_diff_negative: { "show": false, value: '' },
        week2Percent: { "show": false, value: '' },
        week3Percent: { "show": false, value: '' },

    }
    const [inputFieldsRank, setInputFieldsRank] = useState(intialAr)
    const [offcanvastitle, setOffcanvastitle] = useState("Add New " + calculationName(calculation_name))
    const [formfield, setFormfield] = useState(formFieldIntialState)
    const editCalculations = (getid) => {
        initialFormState('addUpdateCalculations');
        setOffcanvastitle("Update " + calculationName(calculation_name))
        fetchData(CALCULATIONS_GET + '/' + getid, 'GET', '', true, false, (res) => {
            if (res.records) {
                setFormfield(prevState => ({
                    ...prevState,
                    id: res.records.id,
                    buy: { show: prevState.buy.show, value: res.records.buy },
                    sell: { show: prevState.sell.show, value: res.records.sell },
                    ranks: { show: prevState.ranks.show, value: prevState.ranks.value },
                    higher: { show: prevState.higher.show, value: res.records.higher },
                    lower: { show: prevState.lower.show, value: res.records.lower },
                    week_on_top: { show: prevState.week_on_top.show, value: res.records.week_on_top },
                    not_on_list: { show: prevState.not_on_list.show, value: res.records.not_on_list },
                    no_of_weeks: { show: prevState.no_of_weeks.show, value: res.records.no_of_week },
                    no_of_songs: { show: prevState.no_of_songs.show, value: res.records.no_of_songs },
                    rank_diff: { show: prevState.rank_diff.show, value: res.records.rank_diff },
                    rank_diff_negative: { show: prevState.rank_diff_negative.show, value: res.records.rank_diff_negative },
                    week2Percent: { show: prevState.week2Percent.show, value: res.records.week2Percent },
                    week3Percent: { show: prevState.week3Percent.show, value: res.records.week3Percent },

                }))
                let ranks = res.records.ranks;
                let intialAr = [];
                for (let i = 0; i < ranks.length; i++) {
                    intialArObj = { name: 'rank_key', nameValue: 'rank_value', rankdId: ranks[i].id, type: 'number', label: "Rank Key", labelValue: "Rank Value", placeholder: 'Enter Rank Name', placeholderValue: 'Enter Rank Value', required: true, defaultValueKey: ranks[i].rank_key, defaultValueValue: ranks[i].rank_value, className: 'form-control mb-2' }
                    intialAr[i] = intialArObj;
                }
                setInputFieldsRank(intialAr)
            } else {
                toastNotify(res)
            }
        }, (err) => { });
    }

    const rankColumns = [
        // { name: 'Id', selector: (row) => row.id, sorting: true },
        { name: 'Rank Key', selector: (row) => (row.rank_key), sorting: true },
        { name: 'Rank Value', selector: (row) => floatNumber5Decimal(row.rank_value), sorting: true },

    ];
    let calculationsColumns = [];
    const datatableColumns = ["buy", "sell", "higher", "lower", "week_on_top", "not_on_list", "no_of_songs", "no_of_weeks", "rank_diff","rank_diff_negative", "week2Percent", "week3Percent"];
    datatableColumns.forEach(dtColumnsFunc);
    function dtColumnsFunc(item, index) {
        if (formfield[item].show === true) {
            if (item === 'no_of_weeks') {
                calculationsColumns.push({ name: _capitalize(calculationName(item)), selector: (row) => row['no_of_week'] ?? 'NA', sorting: true });
            }
            else {
                calculationsColumns.push({ name: _capitalize(calculationName(item)), selector: (row) => row[item] ?? 'NA', sorting: true });
            }
        }
    }
    calculationsColumns.push({
        name: 'Action', title: 'Action', width: '180px', selector: (records) =>
            <div className="d-flex">
                <button className="btn btn-sm text-primary" data-bs-toggle="modal" data-bs-target="#view-specialchar" title="View" onClick={() => editCalculations(records.id)}>
                    <svg className="icon fs-4" role="img"><use href="#icon_eye" /></svg>
                </button>
                <button className="btn btn-sm text-primary" title="Edit" data-bs-toggle="offcanvas" data-bs-target="#addUpdateCalculations" onClick={() => editCalculations(records.id)}>
                    <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
                </button>
                <button className="btn btn-sm text-danger" title="Delete" data-bs-toggle="modal" data-bs-target="#confModal" onClick={(e) => setFormfield(prevState => ({ ...prevState, delId: records.id }))}>
                    <svg className="icon fs-5" role="img"><use href="#icon_trash" /></svg>
                </button>
            </div>
    })
   
    const addRankTextBox = async (e) => {
        let inputField = { name: 'rank_key', nameValue: 'rank_value', type: 'number', label: "Rank Key", labelValue: "Rank Value", placeholder: 'Enter Rank Name', placeholderValue: 'Enter Rank Value', required: true, defaultValueKey: '', defaultValueValue: '', className: 'form-control mb-2' }
        setInputFieldsRank([...inputFieldsRank, inputField]);
    }
    // handle input change
    const handleInputChangeRank = (e, index) => {
        let i = index - 1;
        const { value } = e.target;
        const list = [...inputFieldsRank];
        list[i]['defaultValueKey'] = value;

        setInputFieldsRank(list);
    };
    const deleteRank = (i) => {
        i = i - 1;
        let newinputFieldsRank = [...inputFieldsRank];
        newinputFieldsRank.splice(i, 1);
        setInputFieldsRank(newinputFieldsRank)
    }
    const addCalculations = async (e) => {
        let formdata = new FormData(document.getElementById('addUpdateCalculations'));
        let id = document.querySelector('#addUpdateCalculations [name="id"]').value;

        if (validateForm(e)) {
            fetchData(id ? CALCULATIONS_UPDATE : CALCULATIONS_CREATE, 'POST', formdata, true, true, (res) => {
                toastNotify(res)
                if (res.success) {
                    document.querySelector('#addUpdateCalculations [data-bs-dismiss="offcanvas"]').click();
                    setInputFieldsRank(intialAr)
                }
                else {
                    //$("#");
                }
            });
            setRefresh(now)
        }
    }
    const handleInputChangeRankValue = (e, index) => {
        let i = index - 1;
        const { value } = e.target;
        const list = [...inputFieldsRank];
        list[i]['defaultValueValue'] = value;

        setInputFieldsRank(list);
    };

    useEffect(() => {
        setOffcanvastitle("Add New " + calculationName(calculation_name))
        setFormfield(prevState => ({
            ...prevState,
            "ranks": { "show": showFields("ranks", calculation_name), value: prevState.ranks.value },
            "buy": { "show": showFields("buy", calculation_name), value: prevState.buy.value },
            "sell": { "show": showFields("sell", calculation_name), value: prevState.sell.value },
            "higher": { "show": showFields("higher", calculation_name), value: prevState.higher.value },
            "lower": { "show": showFields("lower", calculation_name), value: prevState.lower.value },
            "week_on_top": { "show": showFields("week_on_top", calculation_name), value: prevState.week_on_top.value },
            "no_of_songs": { "show": showFields("no_of_songs", calculation_name), value: prevState.no_of_songs.value },
            "no_of_weeks": { "show": showFields("no_of_weeks", calculation_name), value: prevState.no_of_weeks.value },
            "not_on_list": { "show": showFields("not_on_list", calculation_name), value: prevState.not_on_list.value },
            "rank_diff": { "show": showFields("rank_diff", calculation_name), value: prevState.rank_diff.value },
            "rank_diff_negative": { "show": showFields("rank_diff_negative", calculation_name), value: prevState.rank_diff_negative.value },
            "week2Percent": { "show": showFields("week2Percent", calculation_name), value: prevState.no_of_songs.value },
            "week3Percent": { "show": showFields("week3Percent", calculation_name), value: prevState.no_of_songs.value }
        }))
        let rankUrl = '';
        let calUrl = '';
        if (type) {
            rankUrl = RANK_LIST + '/' + calculation_name + '/' + type;
            calUrl = CALCULATIONS_LIST + '/' + calculation_name + '/' + type;
        }
        else {
            rankUrl = RANK_LIST + '/' + calculation_name;
            calUrl = CALCULATIONS_LIST + '/' + calculation_name;
        }
        fetchData(rankUrl, 'GET', '', true, false, (res) => {
            if (res.data) {
                setRankData(res.data)
            }
        }, (err) => { }, false, false);
        fetchData(calUrl, 'GET', '', true, false, (res) => {
            if (res.records.data) {
                setCalculationData(res.records.data)
            }
        }, (err) => { }, false, false);
    }, [calculation_name, refresh, type])

    return (
        <div>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h4 className="page-heading">{_capitalize(calculationName(calculation_name))}   {type ? (<span>( {_capitalize(calculationName(type))} )</span>) : (<span></span>)}</h4>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'></div>
                        <div className='col-md-6'>
                            <div className="w-100 d-inline-flex text-end">
                                <div className="ms-auto">
                                    <button type="button" className="btn btn-primary rounded-pill px-3 text-nowrap" data-bs-toggle="offcanvas" data-bs-target="#addUpdateCalculations" onClick={() => { setRefresh(now); setFormfield(formFieldIntialState); setInputFieldsRank(intialAr); initialFormState('addUpdateCalculations', false) }}>
                                        <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg> Create {calculationName(calculation_name)}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-4">
                        <div className='col-md-3'>
                            <DataTable fixedHeader
                                fixedHeaderScrollHeight="300px" columns={rankColumns} className="calcType" data={rankData} pagination sorting />
                        </div>
                        <div className='col-md-9'>
                            <DataTable fixedHeader
                                fixedHeaderScrollHeight="300px" columns={calculationsColumns} className="calcType" data={calculationData} sorting />
                        </div>
                    </div>
                </div>
            </section>
            {/* Add Update Form  */}
            <form className="offcanvas offcanvas-end needs-validation " tabIndex="-1" id="addUpdateCalculations" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <input type="text" className="d-none" name="id" defaultValue={formfield.id} />
                <input type="text" className="d-none" name="calculation_name" defaultValue={calculation_name} />
                <input type="text" className="d-none" name="type" defaultValue={type} />
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="m-0 text-blue">{offcanvastitle}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-3 p-sm-4">
                    {formfield.buy.show ? (
                        <label className="w-100 mb-3">
                            <div className="form-label text-blue small fw-medium mb-1">Buy <strong className="text-danger">*</strong></div>
                            <input type="number" step="0.0001" className="form-control" name="buy" placeholder="Buy" spellCheck="false" defaultValue={(formfield.buy.value)} onKeyUp={(e) => setFormfield(prevState => ({
                                ...prevState, name: e.target.value
                            }))} required />
                            <div className="invalid-feedback">Buy % is required.</div>
                        </label>
                    ) : (<div></div>)}
                    {formfield.sell.show ? (
                        <label className="w-100 mb-3">
                            <div className="form-label text-blue small fw-medium mb-1">Sell <strong className="text-danger">*</strong></div>
                            <input type="number" step="0.0001" className="form-control" name="sell" placeholder="Sell" spellCheck="false" defaultValue={(formfield.sell.value)} onKeyUp={(e) => setFormfield(prevState => ({
                                ...prevState, name: e.target.value
                            }))} required />
                            <div className="invalid-feedback">Sell % is required.</div>
                        </label>
                    ) : (<div></div>)}
                    {formfield.higher.show ? (
                        <label className="w-100 mb-3">
                            <div className="form-label text-blue small fw-medium mb-1">Higher <strong className="text-danger">*</strong></div>
                            <input type="number" step="0.0001" className="form-control" name="higher" placeholder="Higher" spellCheck="false" defaultValue={(formfield.higher.value)} onKeyUp={(e) => setFormfield(prevState => ({
                                ...prevState, name: e.target.value
                            }))} required />
                            <div className="invalid-feedback">Higher is required.</div>
                        </label>
                    ) : (<div></div>)}
                    {formfield.lower.show ? (
                        <label className="w-100 mb-3">
                            <div className="form-label text-blue small fw-medium mb-1">Lower <strong className="text-danger">*</strong></div>
                            <input type="number" step="0.0001" className="form-control" name="lower" placeholder="Lower" spellCheck="false" defaultValue={(formfield.lower.value)} onKeyUp={(e) => setFormfield(prevState => ({
                                ...prevState, name: e.target.value
                            }))} required />
                            <div className="invalid-feedback">Lower is required.</div>
                        </label>
                    ) : (<div></div>)}
                    {formfield.week_on_top.show ? (
                        <label className="w-100 mb-3">
                            <div className="form-label text-blue small fw-medium mb-1">Week on Top <strong className="text-danger">*</strong></div>
                            <input type="number" step="0.0001" className="form-control" name="week_on_top" placeholder="Week on Top" spellCheck="false" defaultValue={(formfield.week_on_top.value)} onKeyUp={(e) => setFormfield(prevState => ({
                                ...prevState, name: e.target.value
                            }))} required />
                            <div className="invalid-feedback">Week on Top is required.</div>
                        </label>
                    ) : (<div></div>)}
                    {formfield.no_of_songs.show ? (
                        <label className="w-100 mb-3">
                            <div className="form-label text-blue small fw-medium mb-1">No. of Songs <strong className="text-danger">*</strong></div>
                            <input type="number" step="0.0001" className="form-control" name="no_of_songs" placeholder="No. of Songs" spellCheck="false" defaultValue={(formfield.no_of_songs.value)} onKeyUp={(e) => setFormfield(prevState => ({
                                ...prevState, name: e.target.value
                            }))} required />
                            <div className="invalid-feedback">No. of Songs is required.</div>
                        </label>
                    ) : (<div></div>)}
                    {formfield.no_of_weeks.show ? (
                        <label className="w-100 mb-3">
                            <div className="form-label text-blue small fw-medium mb-1">No. of Weeks <strong className="text-danger">*</strong></div>
                            <input type="number" step="0.0001" className="form-control" name="no_of_week" placeholder="No. of Weeks" spellCheck="false" defaultValue={(formfield.no_of_weeks.value)} onKeyUp={(e) => setFormfield(prevState => ({
                                ...prevState, name: e.target.value
                            }))} required />
                            <div className="invalid-feedback">No. of Weeks is required.</div>
                        </label>
                    ) : (<div></div>)}
                    {formfield.rank_diff.show ? (
                        <label className="w-100 mb-3">
                            <div className="form-label text-blue small fw-medium mb-1">Rank Difference (+) <strong className="text-danger">*</strong></div>
                            <input type="number" step="0.0001" className="form-control" name="rank_diff" placeholder="Rank Difference (+)" spellCheck="false" defaultValue={(formfield.rank_diff.value)} onKeyUp={(e) => setFormfield(prevState => ({
                                ...prevState, name: e.target.value
                            }))} required />
                            <div className="invalid-feedback">Rank Difference (+) is required.</div>
                        </label>
                    ) : (<div></div>)}
                    {formfield.rank_diff_negative.show ? (
                        <label className="w-100 mb-3">
                            <div className="form-label text-blue small fw-medium mb-1">Rank Difference (-) <strong className="text-danger">*</strong></div>
                            <input type="number" step="0.0001" className="form-control" name="rank_diff_negative" placeholder="Rank Difference (-)" spellCheck="false" defaultValue={(formfield.rank_diff_negative.value)} onKeyUp={(e) => setFormfield(prevState => ({
                                ...prevState, name: e.target.value
                            }))} required />
                            <div className="invalid-feedback">Rank Difference (-) is required.</div>
                        </label>
                    ) : (<div></div>)}
                    {formfield.not_on_list.show ? (
                        <label className="w-100 mb-3">
                            <div className="form-label text-blue small fw-medium mb-1">Not in the List <strong className="text-danger">*</strong></div>
                            <input type="number" step="0.0001" className="form-control" name="not_on_list" placeholder="Enter Not in the List Value" spellCheck="false" defaultValue={(formfield.not_on_list.value)} onKeyUp={(e) => setFormfield(prevState => ({
                                ...prevState, name: e.target.value
                            }))} required />
                            <div className="invalid-feedback">Not in the List Value is required.</div>
                        </label>
                    ) : (<div></div>)}
                    {formfield.week2Percent.show ? (
                        <label className="w-100 mb-3">
                            <div className="form-label text-blue small fw-medium mb-1">Week 2 Percent <strong className="text-danger">*</strong></div>
                            <input type="number" step="0.0001" className="form-control" name="week2Percent" placeholder="Enter Week 2 Percent Value" spellCheck="false" defaultValue={(formfield.week2Percent.value)} onKeyUp={(e) => setFormfield(prevState => ({
                                ...prevState, name: e.target.value
                            }))} required />
                            <div className="invalid-feedback">Week 2 Percent Value is required.</div>
                        </label>
                    ) : (<div></div>)}
                    {formfield.week3Percent.show ? (
                        <label className="w-100 mb-3">
                            <div className="form-label text-blue small fw-medium mb-1">Week 3 Percent<strong className="text-danger">*</strong></div>
                            <input type="number" step="0.0001" className="form-control" name="week3Percent" placeholder="Enter Week 3 Percent Value" spellCheck="false" defaultValue={(formfield.week3Percent.value)} onKeyUp={(e) => setFormfield(prevState => ({
                                ...prevState, name: e.target.value
                            }))} required />
                            <div className="invalid-feedback">Week 3 Percent Value is required.</div>
                        </label>
                    ) : (<div></div>)}
                    {formfield.ranks.show ? (<>
                        <label className="w-100 mb-3">
                            <div className="form-label text-blue smasetFormfield(prevState => ({ ...prevState = '' })); ll fw-medium mb-2">Ranks<strong className="text-danger">*</strong>
                                <div className="rounded-circle border plus-div" title="Add Rank" onClick={(e) => addRankTextBox(e)}><svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg></div> </div>
                        </label>
                        <div>
                            <input type="hidden" name="ranks" value={inputFieldsRank.length}></input>
                        </div>
                        {(inputFieldsRank) && inputFieldsRank.map((item, i) => (
                            <React.Fragment key={i}>
                                <div className='d-none'>{i = i + 1}</div>
                                <div className="input-group mb-3">
                                    <input type="hidden" name={"rankid_" + i} value={item.rankdId}></input>
                                    <input type={`${item.type}`} step="0.000001" name={item.name + "_" + i} id={"rank_key_" + i} className="form-control" placeholder={`${item.label + " " + i}`} defaultValue={(item.defaultValueKey) ? item.defaultValueKey : ''} required onChange={e => handleInputChangeRank(e, i)} />
                                    <div className="invalid-feedback">Rank Key {i} is required.</div>
                                    <input type={`${item.type}`} step="0.000001" name={item.nameValue + "_" + i} id={"rank_value_" + i} className="form-control" placeholder={`${item.labelValue + " " + i}`} defaultValue={(item.defaultValueValue) ? floatNumber5Decimal(item.defaultValueValue) : ''} required onChange={e => handleInputChangeRankValue(e, i)} />
                                    <div className="invalid-feedback">Rank Value {i} is required.</div>
                                    <div className="input-group-append">
                                        <span className="input-group-text addOnText" onClick={() => { deleteRank(i) }} id={"del-Rank-" + i}><svg className="icon fs-5 text-danger" role="img"><use href="#icon_trash" /></svg></span>
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </>) : (<div></div>)}
                </div>
                <div className="offcanvas-footer border-top bg-light p-3 px-sm-4">
                    <button type="button" className="btn btn-primary rounded-pill px-4 me-2" onClick={(e) => addCalculations(e)} >Submit</button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Close">Cancel</button>
                </div>
            </form>

            <ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(CALCULATIONS_DELETE, formfield.delId, setRefresh)} />

            {/* Calculation View Modal  */}
            <div className="modal fade bd-example-modal-lg" id="view-specialchar" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">setI
                    <div className="modal-content">
                        <div className="modal-header border-bottom bg-light px-sm-4">
                            <h5 className="m-0 text-blue">{calculationName(calculation_name)}  Details</h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-3 p-sm-4">
                            <div className="row">
                                <div className="col-md-6 mt-4">
                                    {formfield.higher.show ? (
                                        <div className="row">
                                            <label className=" mb-3 col-md-5">
                                                <div className="mb-1 fw-medium text-blue small">Higher</div>
                                            </label>
                                            <div className='col-md-7'>{(formfield.higher.value)} </div>
                                        </div>
                                    ) : (<div></div>)}
                                    {formfield.lower.show ? (
                                        <div className="row">
                                            <label className=" mb-3 col-md-5">
                                                <div className="mb-1 fw-medium text-blue small">Lower</div>
                                            </label>
                                            <div className='col-md-7'>{(formfield.lower.value)} </div>
                                        </div>
                                    ) : (<div></div>)}
                                    {formfield.no_of_songs.show ? (
                                        <div className="row">
                                            <label className=" mb-3 col-md-5">
                                                <div className="mb-1 fw-medium text-blue small">No. of Songs</div>
                                            </label>
                                            <div className='col-md-7'>{(formfield.no_of_songs.value)} </div>
                                        </div>
                                    ) : (<div></div>)}
                                    {formfield.week_on_top.show ? (
                                        <div className="row">
                                            <label className=" mb-3 col-md-5">
                                                <div className="mb-1 fw-medium text-blue small">Week on Top</div>
                                            </label>
                                            <div className='col-md-7'>{(formfield.week_on_top.value)} </div>
                                        </div>
                                    ) : (<div></div>)}
                                    {formfield.no_of_weeks.show ? (
                                        <div className="row">
                                            <label className=" mb-3 col-md-5">
                                                <div className="mb-1 fw-medium text-blue small">No. of Weeks</div>
                                            </label>
                                            <div className='col-md-7'>{(formfield.no_of_weeks.value)} </div>
                                        </div>
                                    ) : (<div></div>)}
                                    {formfield.not_on_list.show ? (
                                        <div className="row">
                                            <label className=" mb-3 col-md-5">
                                                <div className="mb-1 fw-medium text-blue small">Not in the List</div>
                                            </label>
                                            <div className='col-md-7'>{(formfield.not_on_list.value)} </div>
                                        </div>
                                    ) : (<div></div>)}
                                    {formfield.rank_diff.show ? (
                                        <div className="row">
                                            <label className=" mb-3 col-md-5">
                                                <div className="mb-1 fw-medium text-blue small">Rank Difference (+)</div>
                                            </label>
                                            <div className='col-md-7'>{(formfield.rank_diff.value)} </div>
                                        </div>
                                    ) : (<div></div>)}
                                    {formfield.rank_diff.show ? (
                                        <div className="row">
                                            <label className=" mb-3 col-md-5">
                                                <div className="mb-1 fw-medium text-blue small">Rank Difference (-)</div>
                                            </label>
                                            <div className='col-md-7'>{(formfield.rank_diff_negative.value)} </div>
                                        </div>
                                    ) : (<div></div>)}
                                    {formfield.week2Percent.show ? (
                                        <div className="row">
                                            <label className=" mb-3 col-md-5">
                                                <div className="mb-1 fw-medium text-blue small">Week 2 Percent</div>
                                            </label>
                                            <div className='col-md-7'>{(formfield.week2Percent.value)} </div>
                                        </div>
                                    ) : (<div></div>)}
                                    {formfield.week3Percent.show ? (
                                        <div className="row">
                                            <label className=" mb-3 col-md-5">
                                                <div className="mb-1 fw-medium text-blue small">Week 3 Percent</div>
                                            </label>
                                            <div className='col-md-7'>{(formfield.week3Percent.value)} </div>
                                        </div>
                                    ) : (<div></div>)}
                                    {formfield.buy.show ? (
                                        <div className="row">
                                            <label className=" mb-3 col-md-5">
                                                <div className="mb-1 fw-medium text-blue small">Buy</div>
                                            </label>
                                            <div className='col-md-7'>{(formfield.buy.value)} </div>
                                        </div>
                                    ) : (<div></div>)}
                                    {formfield.sell.show ? (
                                        <div className="row">
                                            <label className=" mb-3 col-md-5">
                                                <div className="mb-1 fw-medium text-blue small">Sell</div>
                                            </label>
                                            <div className='col-md-7'>{(formfield.sell.value)} </div>
                                        </div>
                                    ) : (<div></div>)}
                                </div>
                                {formfield.ranks.show ? (
                                    <div className='col-md-6'>
                                        <div className="mb-1 fw-medium text-blue small">Ranks</div>
                                        {(inputFieldsRank) && inputFieldsRank.map((item, i) => (
                                            <div>
                                                <div className='row'>
                                                    <div className='col-md-2'>{item.defaultValueKey}</div>
                                                    <div className='col-md-2'> {'=>'} </div>
                                                    <div className='col-md-3'>{floatNumber(item.defaultValueValue)}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (<div></div>)}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/*End Percentage Scale  View Modal  */}
        </div>
    );
}

export default Calculations;