import { useEffect, useState } from "react"
import { toastNotify, validateForm } from "../../components/Helper"
import { fetchData, GET_SETTINGS, UPDATE_SETTINGS } from "../../components/Service";

function Setting() {
    window.document.title = "Settings";

    const [setting, setSetting] = useState()

    useEffect(() => {
        fetchData(GET_SETTINGS, 'get', '', true, false, (res) => {
            if(res.records){
                setSetting(res.records)
            }
        })
    }, [])

    const saveData = (e) => {
        if(validateForm(e)){
            let formData = new FormData(document.getElementById('settingsForm'))

            fetchData(UPDATE_SETTINGS, 'POST', formData, true, true, (res) => {
                toastNotify(res)
            })
        }
    }
    return (
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between">
                    <div className="col mb-2">
                        <h4 className="page-heading">Settings</h4>
                    </div>
                </div>
                { setting && 
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body p-sm-4">
                                <form id="settingsForm" action="#" method="post" className="needs-validation" noValidate>
                                    {/* <fieldset className="border1 rounded-10 p-3 p-sm-4 mb-4 bg-light">
                                        <legend className="fs-6 fw-medium text-blue">
                                            Site
                                        </legend>
                                        { setting.site.map( (item, i) =>
                                            <div className="mb-3 row mx-0" key={i}>
                                                <label htmlFor={item.slug} className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                    { item.name }
                                                </label>
                                                <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                    <input type="text" className="form-control" id={item.slug} name={item.slug} defaultValue={item.value} required/>
                                                </div>
                                            </div>
                                        ) }
                                    </fieldset> */}

                                    {/* <fieldset className="border1 rounded-10 p-3 p-sm-4 mb-4 bg-light">
                                        <legend className="fs-6 fw-medium text-blue">
                                            Email
                                        </legend>
                                        { setting.email.map( (item, i) =>
                                            <div className="mb-3 row mx-0" key={i}>
                                                <label htmlFor={item.slug} className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                    { item.name }
                                                </label>
                                                <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                    <input type="text" className="form-control" id={item.slug} name={item.slug} defaultValue={item.value} required/>
                                                </div>
                                            </div>
                                        ) }
                                    </fieldset> */}

                                    <fieldset className="border1 rounded-10 p-3 p-sm-4 mb-4 bg-light">
                                        <legend className="fs-6 fw-medium text-blue">
                                            Currency
                                        </legend>
                                        { setting.currency.map( (item, i) =>
                                            <div className="mb-3 row mx-0" key={i}>
                                                <label htmlFor={item.slug} className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                    { item.name }
                                                </label>
                                                <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                    <select className="form-select" id={item.slug} name={item.slug} defaultValue={item.value} required>
                                                        <option value="USD">USD</option>
                                                        <option value="INR">INR</option>
                                                    </select>
                                                </div>
                                            </div>
                                        ) }
                                    </fieldset>

                                    <fieldset className="border1 rounded-10 p-3 p-sm-4 mb-4 bg-light">
                                        <legend className="fs-6 fw-medium text-blue">
                                            Wallet
                                        </legend>
                                        { setting.wallet.map( (item, i) =>
                                            <div className="mb-3 row mx-0" key={i}>
                                                <label htmlFor={item.slug} className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                    { item.name }
                                                </label>
                                                <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                    <input type="number" className="form-control" id={item.slug} name={item.slug} defaultValue={item.value} required/>
                                                </div>
                                            </div>
                                        ) }
                                    </fieldset>

                                    <fieldset className="border1 rounded-10 p-3 p-sm-4 mb-4 bg-light">
                                        <legend className="fs-6 fw-medium text-blue">
                                            API
                                        </legend>
                                        { setting.api.map( (item, i) =>
                                            <div className="mb-3 row mx-0" key={i}>
                                                <label htmlFor={item.slug} className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                    { item.name }
                                                </label>
                                                <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                    <input type="text" className="form-control" id={item.slug} name={item.slug} defaultValue={item.value} required/>
                                                </div>
                                            </div>
                                        ) }
                                    </fieldset>

                                    <div className="row mx-0 p-3 p-sm-4">
                                        <div className="col-sm-10 offset-sm-3 offset-xxl-2">
                                            <button type="button" className="btn btn-success px-5" onClick={saveData} title="Save">Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div> }
            </div>
        </section>
    )
}

export default Setting