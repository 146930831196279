import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Context } from '../../components/Context';
import { toastNotify, validateForm, asyncsetItem, ButtonSpinner} from '../../components/Helper';
import { fetchData, LOGIN } from '../../components/Service';

function Login(){
    const navigate = useNavigate();
    const [tinyloader, setTinyloader] = useState(false);
    const [context, setContext] = useContext(Context)
    const [token] = useState(JSON.parse(localStorage.getItem("stock-market")));

    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const handlePasswordChange = (evnt) => {
        setPasswordInput(evnt.target.value);
    }
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const changeHandler = (e) => {
        if (e.keyCode === 13) {
            loginSubmission(e)
        }
    }
    
    const loginSubmission = (e) => {
        let requiredField = new FormData(document.getElementById('loginForm'));
        let data = {};
        requiredField.forEach(function(value, key){
            data[key] = value;
        });
        
        if(validateForm(e)) {
            setTinyloader(true);
            
            fetchData(LOGIN, 'POST', data, false, false, (res) => {
                setTinyloader(false);
                toastNotify(res)

                if (res.records) {
                    setContext(prevState => ({
                        ...prevState,
                        auth: res.records
                    }))

                    asyncsetItem('stock-market', JSON.stringify(res.records.access_token)).then(function () {
                        if(res.records.role_id === 4){
                            navigate(`/`);
                        }else{
                            navigate(`/dashboard`);
                        }
                    })
                }
            });
        };
    }

    useEffect(() => {
        if (token === (context && context.auth && context.auth.access_token)) {
            navigate(`/dashboard`);
        }
    }, [token, navigate, context])

    return(
        <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-5 py-3">
                        <form className="bg-white rounded-15 p-3 p-sm-4 p-sm-5 shadow-sm needs-validation" id="loginForm" noValidate>
                            <div className="text-center">
                                <img src="/logo.png" alt="Xipetech" className="w-100 maxw-280" width="280" height="70.81"/>
                            </div>
                            <hr className="text-muted opacity-10 mx-auto"/>
                            <h4 className="text-center my-3">Login</h4>
                            <label className="input-group input-group-lg input-group-rounded mb-3">
                                <span className="input-group-text">
                                    <svg className="icon tscale-1p3 opacity-50" role="img" aria-label="more">
                                        <use href="#icon_user" />
                                    </svg>
                                </span>
                                <input type="email" className="form-control rounded-pill-end-topbottom" onKeyUp={changeHandler} name="email" placeholder="Email*" autoComplete="username" spellCheck={false} defaultValue={""} required/>
                                <div className="invalid-feedback ps-70">
                                    Email is required.
                                </div>
                            </label>
                            <label className="input-group input-group-lg input-group-rounded mb-3">
                                <span className="input-group-text">
                                    <svg className="icon tscale-1p2 opacity-50" role="img" aria-label="more">
                                        <use href="#icon_lock" />
                                    </svg>
                                </span>
                                <input type={passwordType} onChange={handlePasswordChange}  className="form-control " onKeyUp={changeHandler} name="password" placeholder="Password*" autoComplete="current-password" minLength={8} defaultValue={passwordInput} title="You have to enter at least 8 digit!" required/>
                                <span className="input-group-text rounded-pill-end-topbottom" onClick={togglePassword}>
                                    <svg className="icon tscale-1p4 opacity-50" role="img" aria-label="more">
                                    {passwordType === "password" ?     <use href="#icon_eye-off" /> :   <use href="#icon_eye" />}
                                    
                                    </svg>
                                </span>
                               
                                <div className="invalid-feedback ps-70">
                                    Password is required.
                                </div>
                            </label>
                            <button type="button" className="btn btn-primary btn-lg w-100 rounded-pill mb-3" onClick={(e)=>loginSubmission(e)} disabled={tinyloader}>
                                <ButtonSpinner load={tinyloader} btnName="Login"/>
                            </button>
                            <div className="py-2">
                                <Link to="/privacy-policy" className="fw-medium me-3" title="Forgot Password">Privacy Policy</Link>
                                <Link to="/forgot-password" className="fw-medium me-3 float-end" title="Forgot Password">Forgot Password?</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login