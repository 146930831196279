import { DataList } from "../../components/DataList";
import { COMMENT_LIST } from "../../components/Service";
import { __echoText } from "../../components/Helper";
import { useLocation } from "react-router-dom";

function CommentByPost() {
    window.document.title = "Comments List";
    const location = useLocation();
    const post_id = location.state.id;
    const dt = {
        id: 'transactions',
        fetchurl: `${COMMENT_LIST}/${post_id}`,
        columns: [
            { name: 'comment_id', title: 'Id', width: 50 },
            { name: 'first_name', title: 'Name', width: 100 },
            { name: 'comment', title: 'Comment', width: 500 },
            { name: 'status', title: 'Status', width: 100 },
            { name: 'created_at', title: 'Date & Time', type: 'datetime'}
        ]
    } 

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h4 className="page-heading">Comments List</h4>
                        </div>
                    </div>
                    <div className="row">
                        <DataList 
                            dt={dt}
                            edit_column={{
                                first_name: (records) =>
                                    __echoText(`${records.first_name} ${records.last_name}`),

                                status: (records) =>
                                    <span className={`badge  text-${ records.status === 'published' ? 'success' : 'warning' } rounded-pill fw-medium minw-70`}>{ records.status === 'published' ? 'Published' : 'Draft' }</span>
                            }}
                        />
                    </div>
                </div>
            </section>
        </>
    )
}

export default CommentByPost