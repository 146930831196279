import React, { useState, useEffect } from "react";
import { DataList, dtControllers } from "../../components/DataList";
import { CONTACT_CHANGE_STATUS, CONTACT_LIST, fetchData } from "../../components/Service";
import { now } from "lodash"
import { __echoText, toastNotify, validateForm } from "../../components/Helper";

function Contacts() {
    window.document.title = "Contacts";
    const [refresh, setRefresh] = useState(now)
    const [data, setData] = useState({ id: "", name: "", email: "", description: "", message: "", status: "", current_status: "", reply: "" })

    const dt = {
        id: 'contacts',
        fetchurl: CONTACT_LIST,
        columns: [
            { name: "id", title: "#ID" },
            { name: 'name', title: 'Name' },
            { name: "email", title: "Email" },
            { name: "phone", title: "Phone No." },
            { name: "description", title: "Description" },
            { name: "assigned_to", title: "Replied To" },
            { name: "status", title: "Status" },
            { name: "action", title: "Action" }
        ]
    }

    useEffect(() => {

    }, [])

    const openContactModal = (rowData) => {
        setData(prevState => ({
            ...prevState,
            id: rowData.id,
            name: rowData.name,
            email: rowData.email,
            description: rowData.description,
            message: rowData.message,
            current_status: rowData.status,
            status: rowData.status,
            reply: rowData.reply,
        }))
        document.getElementById('classForm').classList.remove('was-validated')
    }

    const closeModel = () => {
        let id = document.getElementById('classForm')
        id.reset()
    }

    const handeleInputChange = (e) => {
        setData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const handleSubmitForm = (e) => {
        e.preventDefault();
        if (validateForm(e)) {
            fetchData(CONTACT_CHANGE_STATUS, 'POST', data, true, false, (res) => {
                toastNotify(res);
                if (res.success) {
                    setData({ id: "", name: "", email: "", description: "", message: "", status: "", current_status: "", reply: "" })
                    setRefresh(now)
                    closeModel();
                    document.querySelector("#viewContact [data-bs-dismiss=modal]").click()
                }
            }, (err) => { })
        }
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h4 className="page-heading">Contacts</h4>
                        </div>
                    </div>
                    <div className="row">
                        <DataList
                            dt={dt}
                            refresh={refresh}
                            outerbutton={(records, setDt) =>
                                <div className="ms-auto">
                                    <select className="form-control" onChange={(e) => dtControllers(e.target.value, 'f_type', setDt)}>
                                        <option value="">All</option>
                                        <option value="request">Request</option>
                                        <option value="in-progress">In Progerss</option>
                                        <option value="done">Done</option>
                                    </select>
                                </div>
                            }
                            edit_column={{
                                assigned_to: (records) =>
                                    __echoText(records.assigned_to ? `${records.first_name} ${records.last_name}` : "Not Replied"),
                                action: (records) =>
                                    <div className="d-flex">
                                        <button className="btn btn-sm text-primary" data-bs-toggle="modal" title="View" data-bs-target="#viewContact" onClick={() => openContactModal(records)}>
                                            <svg className="icon fs-4" role="img"><use href="#icon_eye" /></svg>
                                        </button>
                                    </div>
                            }}
                        />
                    </div>
                </div>
            </section>

            {/* Modal Box For View */}
            <div className="modal fade" id="viewContact" tabIndex="-1" data-bs-backdrop="static" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                    <div className="modal-content border-0 shadow">
                        <div className="modal-header bg-light">
                            <h5 className="modal-title">Contact View</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModel} title='Close'></button>
                        </div>
                        <div className="modal-body p-sm-4">
                            <form action="#" className="needs-validation" method="post" id="classForm" noValidate>
                                <div className="row mx-0 mb-2 mb-sm-4">
                                    <label htmlFor="message" className="col-sm-4 col-form-label">Description<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <textarea className="form-control" rows="4" defaultValue={data.description} readOnly></textarea>
                                    </div>
                                </div>
                                <div className="row mx-0 mb-2 mb-sm-4">
                                    <label htmlFor="message" className="col-sm-4 col-form-label">Reply<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <textarea className="form-control" rows="7" id="reply" name="reply" onChange={handeleInputChange} value={data.reply} readOnly={data.current_status === 'done' ? true : false} required></textarea>
                                        <div className="invalid-feedback">Description is required.</div>
                                    </div>
                                </div>
                                <div className="row mx-0 mb-2 mb-sm-4">
                                    <label htmlFor="reference_class" className="col-sm-4 col-form-label" hidden={data.current_status === 'done' ? true : false}>Choose Status<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <select className="form-select" name="status" id="status" onChange={handeleInputChange} hidden={data.current_status === 'done' ? true : false} required>
                                            <option value={data.current_status} >{data.current_status === 'in-progress' ? 'In Progress' : 'Request'}</option>
                                            {
                                                (data.current_status === 'request') ?
                                                    <>
                                                        <option value='in-progress'>In Progress</option>
                                                        <option value='done'>Done</option></>
                                                    :
                                                    <option value='done'>Done</option>
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-sm-8 offset-sm-4">
                                        {(data.current_status !== 'done') && <button type="button" className="btn btn-primary px-4 me-2" onClick={handleSubmitForm} title='Submit'>Submit</button>}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contacts
