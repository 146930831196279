import React, { useState, useEffect } from "react";
import { DataList} from "../../components/DataList";
import { fetchData, POST_CREATE, POST_LIST, POST_GET, POST_UPDATE, POST_STATUS, POST_DELETE, ASSET_ENDPOINT, ALL_ASSET_LIST } from "../../components/Service";
import { noImageHandle, toastNotify, validateForm, blobToBase64, ConfirmationModal, statusChange, deleteRecords } from "../../components/Helper";
import { now } from "lodash"
import Select from "react-select"

function Album() {
    window.document.title = "Albums";

    const [refresh, setRefresh] = useState(now)
    const [offcanvastitle, setOffcanvastitle] = useState("Add New Album")
    const [assetIds, setAssetIds] = useState('')
    const [defaultAsset, setDefaultAsset] = useState('')
    const currentYear = new Date().getFullYear();
    const [formfield, setFormfield] = useState({
        id: "",
        title: "",
        description: "",
        image: "",
        imageb64: "",
        post_type: "album",
        year: currentYear
    })

    const dt = {
        id: 'comments',
        fetchurl: POST_LIST+'/album',
        columns: [
            { name: 'id', title: 'Id' , width: 50},
            { name: 'title', title: 'Title', width: 300 },
            { name: 'description', title: 'Description' },
            { name: 'like', title: 'Likes', width: 50 },
            { name: 'view', title: 'Views', width: 50 },
            { name: 'comment', title: 'Comments', width: 50 },
            { name: 'share', title: 'Shares', width: 50 },
            { name: 'image', title: 'Image', width: 50 },
            { name: 'status', title: 'Status', width: 50 },
            { name: 'created_at', title: 'Date & Time', type: 'datetime', width: 200},
            { name: 'action', title: 'Action' },
        ]
    }

    useEffect(() => {
        document.getElementById("addupdatealbum").addEventListener('hidden.bs.offcanvas', function (e) {
            e.target.reset()
            e.target.classList.remove('was-validated');
            setOffcanvastitle("Add New Album");
            setFormfield({
                id: "",
                title: "",
                image: "",
                imageb64: "",
                description: "",
                year: "2022"
            })
        });

        fetchData(ALL_ASSET_LIST, 'GET', '', true, false, (res) => {
            if (res.records) {
                setDefaultAsset([])
                setAssetIds(res.records)
            }
        });
    }, [refresh])

    const addAlbum = async (e) => {
        let formdata = new FormData(document.getElementById('addupdatealbum'));
        let id = document.querySelector('#addupdatealbum [name="id"]').value;

        if (validateForm(e)) {
            fetchData(id ? POST_UPDATE : POST_CREATE, 'POST', formdata, true, true, (res) => {
                toastNotify(res)
                if (res.success) {
                    document.querySelector('#addupdatealbum [data-bs-dismiss="offcanvas"]').click()
                }
                setRefresh(now)
            });
        }
    }
  
    const imageSet = async (e) => {
        const imgb64 = await blobToBase64(e.target.files[0])
        setFormfield(prevState => ({
            ...prevState,
            imageb64: imgb64,
        }))
    }
  
    const editAlbum = (getid) => {
        setOffcanvastitle("Update Album");
        setDefaultAsset('')

        fetchData(POST_GET + '/' + getid, 'GET', '', true, false, (res) => {
            if (res.records) {
                let records = JSON.parse(res.records.source)
                let asset = [];

                assetIds.forEach((item) => {
                    if(res.records.asset_ids.includes(item.value)){
                        asset.push({ value: item.value, label: item.label })
                    }
                })

                setDefaultAsset(asset)

                setFormfield(prevState => ({
                    ...prevState,
                    id: res.records.id,
                    title: res.records.title,
                    description: res.records.description,
                    image: res.records.image,
                    url: records.url,
                    year: records && records.year ? records.year : ''
                }))
            }
        });
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h4 className="page-heading">Albums</h4>
                        </div>
                    </div>
                    <div className="row">
                        <DataList 
                            dt={dt}
                            refresh={refresh}
                            outerbutton={(records) =>
                                <div className="ms-auto">
                                    <button type="button" className="btn btn-primary rounded-pill px-3 text-nowrap" data-bs-toggle="offcanvas" data-bs-target="#addupdatealbum">
                                        <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg> Add Album
                                    </button>
                                </div>
                            }
                            edit_column={{
                                description: (records) =>
                                    <span dangerouslySetInnerHTML={ records.description.length > 30 ? { __html: records.description.substring(0, 30) + '...'} : { __html: records.description}} ></span> ,
                                
                                image: (records) =>
                                    <img src={`${ASSET_ENDPOINT}${records.image}`} alt='post' className="wh-50 rounded shadow-sm objfit-cover bg-light" onError={noImageHandle}/>,

                                status: (records) =>
                                    <span className={`badge  bg-${ records.status === 'published' ? 'success' : 'warning' } rounded-pill fw-medium minw-70`} role="button" onClick={() => statusChange(POST_STATUS, records.id, records.status === 'draft' ? 'published' : 'draft', setRefresh)} >{ records.status === 'published' ? 'Published' : 'Draft' }</span>,

                                action: (records) =>
                                    <div className="d-flex">
                                        <button className="btn btn-sm text-primary" data-bs-toggle="modal" data-bs-target="#view-post" title="View" onClick={() => editAlbum(records.id)}>
                                            <svg className="icon fs-4" role="img"><use href="#icon_eye" /></svg>
                                        </button>
                                        <button className="btn btn-sm text-primary" title="Edit" data-bs-toggle="offcanvas" data-bs-target="#addupdatealbum" onClick={() => editAlbum(records.id)}>
                                            <svg className="icon fs-5" role="img"><use href="#icon_edit"/></svg>
                                        </button>
                                        <button className="btn btn-sm text-danger" title="Delete" data-bs-toggle="modal" data-bs-target="#confModal" onClick={(e) => setFormfield(prevState => ({ ...prevState, id: records.id }))}>
                                            <svg className="icon fs-5" role="img"><use href="#icon_trash"/></svg>
                                        </button>
                                    </div>
                            }}
                        />
                    </div>
                </div>
            </section>

            <form className="offcanvas offcanvas-end needs-validation " tabIndex="-1" id="addupdatealbum" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="m-0 text-blue">{offcanvastitle}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-3 p-sm-4">
                    <input type="text" className="d-none" name="id" defaultValue={formfield.id} />
                    <input type="hidden" name="post_type" defaultValue={formfield.post_type} />
                    <label className="image-uploading d-flex align-items-center mb-3">
                        <img src={!formfield.imageb64 ? ASSET_ENDPOINT + '/' + formfield.image : formfield.imageb64} alt="album_image" className="me-3" onError={noImageHandle} />
                        <button type="button" className="btn btn-primary text-nowrap" >Upload Image </button><strong className="text-danger mt-n1">*</strong>
                        <input type="file" accept=".jpg,.jpeg,.png" className="form-field" name="image" title="Upload Image" role="button" onChange={async (e) => setFormfield(prevState => ({
                            ...prevState,
                            imageb64: imageSet(e),
                        }))} required={offcanvastitle === "Add New Album" ? true : false} />
                        <div className="invalid-feedback">Required to upload image.</div>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Assets</div>
                        { defaultAsset && <Select
                            defaultValue={defaultAsset}
                            isMulti
                            name="asset_ids[]"
                            options={assetIds}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Assets"
                            required
                        /> }
                    </label>
                    <label className="w-100 mb-3">
                        <div className="form-label text-blue small fw-medium mb-1">Title<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="title" placeholder="Enter Title" spellCheck="false" defaultValue={formfield.title} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, title: e.target.value
                        }))} required />
                        <div className="invalid-feedback">Title is required.</div>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="form-label text-blue small fw-medium mb-1">Year<strong className="text-danger">*</strong></div>
                        <input type="number" className="form-control" name="year" placeholder="Enter Title" spellCheck="false" defaultValue={formfield.year} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, year: e.target.value
                        }))} required />
                        <div className="invalid-feedback">Year is required.</div>
                    </label>
                    <label className="w-100 mb-3">
                        <label className="w-100 mb-3">
                            <div className="mb-1 fw-medium text-blue small">Description<strong className="text-danger">*</strong></div>
                            <textarea className="form-control form-field" name="description" spellCheck={false} rows={5} defaultValue={formfield.description} onKeyUp={(e) => setFormfield(prevState => ({
                                ...prevState, description: e.target.value
                            }))} required></textarea>
                            <div className="invalid-feedback">Description is required.</div>
                        </label>
                    </label>
                </div>
                <div className="offcanvas-footer border-top bg-light p-3 px-sm-4">
                    <button type="button" className="btn btn-primary rounded-pill px-4 me-2" onClick={(e) => addAlbum(e)} >Submit</button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Close">Cancel</button>
                </div>
            </form>

            <ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(POST_DELETE, formfield.id, setRefresh )}/>
            
            {/* Album View Modal  */}
            <div className="modal fade bd-example-modal-lg" id="view-post" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header border-bottom bg-light px-sm-4">
                            <h5 className="m-0 text-blue">Album Details</h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-3 p-sm-4">
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="image-uploading d-flex align-items-center mb-3">
                                        <img src={ ASSET_ENDPOINT + '/' + formfield.image } alt="album_image" className="me-3" onError={noImageHandle} />
                                    </label>
                                </div>
                                <div className="col-md-7">
                                    <label className="w-100 mb-3">
                                        <div className="form-label text-blue small fw-medium mb-1">Title</div>
                                        <div>{formfield.title}</div>
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <label className="w-100 mb-3">
                                        <div className="form-label small text-blue fw-medium mb-1">Url</div>
                                        <div>{formfield.url ? <a href={formfield.url} target="_blank" rel="noreferrer">{formfield.url}</a> : '-' }</div>
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <label className="w-100 mb-3">
                                        <div className="form-label small text-blue fw-medium mb-1">Description</div>
                                        <div dangerouslySetInnerHTML={{ __html: formfield.description}}></div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*End Album View Modal  */}
        </>
    )
}

export default Album
