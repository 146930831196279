import { DataList } from "../../components/DataList";
import { __echoText } from "../../components/Helper";
import { LOG_LIST } from "../../components/Service";
import _ from "lodash"

function ActivityLogs() {
    window.document.title = "Activity Logs";

    const dt = {
        id: 'activity-logs',
        fetchurl: LOG_LIST,
        columns: [
            { name: 'id', title: 'Id' },
            { name: 'first_name', title: 'Name' },
            { name: 'email', title: 'Email' },
            { name: 'module_type', title: 'Module' },
            { name: 'log_message', title: 'Activity Message' },
            { name: 'created_at', title: 'Date & Time', type: 'datetime'}
        ]
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h4 className="page-heading">Activity Logs</h4>
                        </div>
                    </div>
                    <div className="row">
                        <DataList 
                            dt={dt}
                            edit_column={{
                                first_name: (records) =>
                                    __echoText(`${records.first_name} ${records.last_name}`),

                                module_type: (records) =>
                                    __echoText(_.startCase(records.module_type))
                            }}
                        />
                    </div>
                </div>
            </section>
        </>
    )
}

export default ActivityLogs