import React, { useState } from "react";
import Chart from "../../components/Chart";
import CalculationDetail from "../../components/CalculationDetail";
import AppAssetList from "./AppAssetList";
import AppBuySell from "./AppBuySell";
import EtfChart from "../../components/EtfChart";

const AppAsset = () => {
    const [chartAssetId, setChartAssetId] = useState()
    const [calculationAssetId, setCalculationAssetId] = useState({ "id": 0, "name": "Artist Name" })
    const [etfGraph, setEtfGraph] = useState(1)
    return (
        <>
            <div className="row justify-content-center mt-4">
                <AppAssetList setChartAssetId={setChartAssetId} setCalculationAssetId={setCalculationAssetId} setEtfGraph={setEtfGraph} />
            </div>
            <div className="row justify-content-center mt-4 mb-5">
                {etfGraph && <EtfChart etfGraph={etfGraph} />}
            </div>
            <div className="modal fade bd-example-modal-lg" id="viewChart" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header border-bottom bg-light px-sm-4">
                            <h5 className="m-0 text-blue">Asset Price</h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-3 p-sm-4">
                            <div className="row">
                                <div className="col-lg-10">
                                    {chartAssetId && <Chart chartAssetId={chartAssetId} />}
                                </div>
                                <div className="col-lg-2">
                                    {chartAssetId && <AppBuySell chartAssetId={chartAssetId} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade bd-example-modal-lg" id="viewDetail" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header border-bottom bg-light px-sm-4">
                            <h5 className="m-0 text-blue">Asset Calculations  of {calculationAssetId.name} </h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-3 p-sm-4">
                            <div className="row">
                                <div className="col-lg-12 overflow-auto" style={{ height: '650px' }}>
                                    {calculationAssetId && <CalculationDetail assetId={calculationAssetId} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AppAsset


