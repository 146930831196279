import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { toastNotify, validateForm } from '../../components/Helper';
import { fetchData, CHANGE_PASSWORD } from '../../components/Service';

function AppChangePassword() {
    window.document.title = "Change Password";

    const navigate = useNavigate()

    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const handlePasswordChange = (evnt) => {
        setPasswordInput(evnt.target.value);
    }
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const [newPasswordType, setNewPasswordType] = useState("password");
    const [newPasswordInput, setNewPasswordInput] = useState("");
    const handleNewPasswordChange = (evnt) => {
        setNewPasswordInput(evnt.target.value);
    }
    const toggleNewPassword = () => {
        if (newPasswordType === "password") {
            setNewPasswordType("text")
            return;
        }
        setNewPasswordType("password")
    }

    const [confirmNewpasswordType, setConfirmNewPasswordType] = useState("password");
    const [confirmNewPasswordInput, setConfirmNewPasswordInput] = useState("");
    const handleConfirmNewPasswordChange = (evnt) => {
        setConfirmNewPasswordInput(evnt.target.value);
    }
    const toggleConfirmNewPassword = () => {
        if (confirmNewpasswordType === "password") {
            setConfirmNewPasswordType("text")
            return;
        }
        setConfirmNewPasswordType("password")
    }

    const changePassword = (e) => {
        ;
        let formdata = new FormData(document.getElementById('changepasswordForm'));

        if (validateForm(e)) {
            fetchData(CHANGE_PASSWORD, 'POST', formdata, true, true, (res) => {
                toastNotify(res)

                if (res.success) {
                    navigate('/')
                }
            });
        };
    }

    return (
        <div className="row">
            <form className="needs-validation" id="changepasswordForm" noValidate>
                <div className="col-sm-12">
                    <div className="row mx-0 bg-white rounded-15 p-4 shadow-sm d-flex justify-content-center">
                        <div className="col-sm-12 col-md-8 col-lg-7 col-xl-6 col-xxl-5 my-2">
                            <label htmlFor="current_password" className="form-label small text-blue fw-medium mb-1">
                                Current Password<strong className="text-danger">*</strong>
                            </label>
                            <label className="input-group">
                                <span className="input-group-text">
                                    <svg className="icon tscale-1p3 opacity-50" role="img" aria-label="more">
                                        <use href="#icon_lock" />
                                    </svg>
                                </span>
                                                             
                                <input type={passwordType} onChange={handlePasswordChange}  className="form-control" name="current_password" placeholder="Current Password" autoComplete="Current Password" spellCheck={false} defaultValue={passwordInput} required />
                                <span className="input-group-text" onClick={togglePassword}>
                                    <svg className="icon tscale-1p2 opacity-50" role="img" aria-label="more">
                                    {passwordType === "password" ? <use href="#icon_eye-off" /> : <use href="#icon_eye" />} 
                                    </svg>
                                </span> 
                               
                                <div className="invalid-feedback">Current Password is required.</div>
                            </label>
                        </div>
                        <div className="col-sm-12"></div>
                        <div className="col-sm-12 col-md-8 col-lg-7 col-xl-6 col-xxl-5 my-2">
                            <label htmlFor="new_password" className="form-label small text-blue fw-medium mb-1">
                                New Password<strong className="text-danger">*</strong>
                            </label>
                            <label className="input-group">
                                <span className="input-group-text">
                                    <svg className="icon tscale-1p2 opacity-50" role="img" aria-label="more">
                                        <use href="#icon_lock" />
                                    </svg>
                                </span>
                                <input type={newPasswordType} onChange={handleNewPasswordChange}  className="form-control" name="new_password" placeholder="New Password" autoComplete="New Password" spellCheck={false} defaultValue={newPasswordInput} required />
                                <span className="input-group-text" onClick={toggleNewPassword}>
                                    <svg className="icon tscale-1p2 opacity-50" role="img" aria-label="more">
                                    {newPasswordType === "password" ? <use href="#icon_eye-off" /> : <use href="#icon_eye" />} 
                                    </svg>
                                </span>                               
                                <div className="invalid-feedback">New Password is required.</div>
                            </label>
                        </div>
                        <div className="col-sm-12"></div>
                        <div className="col-sm-12 col-md-8 col-lg-7 col-xl-6 col-xxl-5 my-2">
                            <label htmlFor="confirm_password" className="form-label small text-blue fw-medium mb-1">
                                Confirm New Password<strong className="text-danger">*</strong>
                            </label>
                            <label className="input-group">
                                <span className="input-group-text">
                                    <svg className="icon tscale-1p2 opacity-50" role="img" aria-label="more">
                                        <use href="#icon_lock" />
                                    </svg>
                                </span>
                                <input type={confirmNewpasswordType} onChange={handleConfirmNewPasswordChange}  className="form-control" name="confirm_password" placeholder="Confirm New Password" autoComplete="Confirm New Password" spellCheck={false} defaultValue={confirmNewPasswordInput} required />
                                <span className="input-group-text" onClick={toggleConfirmNewPassword}>
                                    <svg className="icon tscale-1p2 opacity-50" role="img" aria-label="more">
                                    {confirmNewpasswordType === "password" ? <use href="#icon_eye-off" /> : <use href="#icon_eye" />} 
                                    </svg>
                                </span>
                               
                                <div className="invalid-feedback">Confirm New Password is required.</div>
                            </label>
                        </div>
                        <div className="col-sm-12 my-2 pt-2 text-center">
                            <button type="button" className="btn btn-primary rounded-pill minw-140 fw-medium" onClick={(e) => changePassword(e)} >Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AppChangePassword