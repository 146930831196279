import { Toast } from "bootstrap";
import { format } from 'date-fns'
import $ from 'jquery';
import { fetchData, VERIFY_EMAIL_PHONE, VERIFY_PROOF } from "./Service";
import { now } from "lodash"

export const scrollbarSetToTop = () => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
    });
}

export const noImageHandle = (e) => {
    e.target.src = "no-image.png";
}

export const scrolldownpage = (e, id) => {
    e.preventDefault();
    if (document.getElementById(id) !== null) {
        document.getElementById(id).scrollIntoView({ behavior: "smooth" });
        [...document.querySelectorAll('.top-menu a, .bottom-mobile-menu .mobile-link')].forEach((i) => {
            i.classList.remove('active')
        })
        e.target.classList.add('active');
    } else {
        window.location.href = "/"
    }
}

export const ConfirmationModal = (props) => {
    return <>
        <div className="modal fade" id="confModal" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content border-0 rounded-15">
                    <div className="modal-body fs-5 text-blue py-4 fw-medium text-center">
                        {props.msg}
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button type="button" className="btn btn-secondary rounded-pill minw-100" data-bs-dismiss="modal">No</button>
                        <button type="button" className="btn btn-primary rounded-pill minw-100 ms-2" onClick={props.method}>Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </>
}


export const toastNotify = (res) => {
    let type = ''
    let message = ''
    let idGenerate = Math.floor((Math.random() * 1000000) + 1)

    if (res.errors) {
        let i = 1;
        for (let key in res.errors) {
            if (i === 1) {
                $(`input[name="${key}"]`).closest('form').removeClass('was-validated');
                $(`input[name="${key}"]`).closest('form').find('.form-control').removeClass('is-invalid');
                $(`input[name="${key}"]`).closest('form').find('.invalid-feedback').remove();
            }

            $(`input[name="${key}"]`).addClass('is-invalid').after(`<div class="invalid-feedback">${res.errors[key][0]}</div>`)
            i++;
        }
    } else {
        if (res.success) {
            type = 'success'
            message = res.success
        } else if (res.error) {
            type = 'danger'
            message = res.error
        } else {
            type = 'danger';
            message = 'Something went wrong!'
        }

        let htmlToast = `<div id="${idGenerate}" class="toast fade bg-white mb-2 border-0" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="4000">
                            <div class="d-flex bg-${type}">
                                <div class="toast-body first-text-uppercase fw-medium1 fs-16px text-white">${message}</div>
                                <button type="button" class="btn-close ms-auto me-2 mt-2 mmwh-22px" data-bs-dismiss="toast" aria-label="Close" title="Close"></button>
                            </div>
                        </div>`;

        document.getElementById('toastContainer').insertAdjacentHTML("afterbegin", htmlToast);

        var getIdToast = document.getElementById(idGenerate);
        var toast = new Toast(getIdToast);
        toast.show();

        getIdToast.addEventListener('hidden.bs.toast', function () {
            setTimeout(() => {
                this.remove();
            }, 600);
        });
    }
}

export const blobToBase64 = async (blob) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result)
        };
        reader.readAsDataURL(blob);
    });
}

export const readFile = async (e) => {
    if (e.target.files && e.target.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = function (evt) {
            return evt.target.result;
        }
    }
}

export const checkFile = (e) => {
    const fileExt = ['jpeg', 'jpg', 'png'];
    const files = e.target.files[0];
    let ext = files.name.split('.').pop();
    if (!fileExt.includes(ext.toLowerCase())) {
        alert('Please upload file in pdf, jpeg, jpg, png format.');
        return ''
    } else {
        return files
    }
}

export const validateForm = (e) => {
    let forms = document.querySelectorAll('.needs-validation')
    let error = 0;
    Array.prototype.slice.call(forms)
        .forEach(function (form) {
            if (!form.checkValidity()) {
                error++;
                e.preventDefault();
                e.stopPropagation();
            }
            form.classList.add('was-validated')
        })
    if (error) {
        return false
    } else {
        return true
    }
}

export const loadingData = (e) => {
    if (e) {
        document.querySelector('body').classList.add('loading-data');
    } else {
        document.querySelector('body').classList.remove('loading-data');
    }
}

export const asyncsetItem = async (key, value) => {
    await null;
    return localStorage.setItem(key, value);
}

export const __echoText = (data, type = 'text') => {
    let text;

    if (type === 'date') {
        text = format(new Date(data), 'dd MMM, yyyy')
    } else if (type === 'datetime') {
        text = format(new Date(data), "dd MMM, yyyy HH:mm:ss")
    } else {
        text = data ? data : '-'
    }

    return text
}
export const _capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
export function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export const statusChange = (url, getid, status, setRefresh) => {
    var data = { id: getid, status: status }
    fetchData(url, 'POST', data, true, false, (res) => {
        toastNotify(res)
        setRefresh(now)
    });
}

export const verifyEmailPhone = (getid, verify, setRefresh) => {
    var data = { id: getid, verify: verify }
    fetchData(VERIFY_EMAIL_PHONE, 'POST', data, true, false, (res) => {
        toastNotify(res)
        setRefresh(now)
    });
}

export const verifyProof = (getid, verify, getUserInfo) => {
    var data = { id: getid, verify: verify }
    fetchData(VERIFY_PROOF, 'POST', data, true, false, (res) => {
        toastNotify(res)
        getUserInfo(getid)
    });
}

export const initialFormState = (formId, setData = false) => {
    [...document.querySelectorAll(`#${formId} .form-control, #${formId} .form-select`)].forEach((ele) => {
        ele.classList.remove('is-invalid');
    });
    document.getElementById(formId).classList.remove('was-validated')
    document.getElementById(formId).reset()
    if (setData) {
        setData(prevState => ({ ...prevState = '' }))
    }
    return setData;
}

export const deleteRecords = (url, id, setRefresh) => {
    fetchData(url, 'POST', { id: id }, true, false, (res) => {
        document.querySelector('#confModal [data-bs-dismiss="modal"]').click()
        toastNotify(res)
        setRefresh(now)
    });
}
export const showFields = (fieldsName, cal_name) => {
    var fields = [
        { "billboard_global_200_excl_us": [{ "no_of_songs": false }, { "week2Percent": true }, { "week3Percent": true }, { "no_of_weeks": true }, { "not_on_list": true }, { "rank_diff": false },{ "rank_diff_negative": true },{"week_on_top":false},{"higher":false},{"lower":false},{"ranks":true},{"buy":false},{"sell":false}] },
        { "billboard_global_200": [{ "no_of_songs": false }, { "week2Percent": true }, { "week3Percent": true }, { "no_of_weeks": true }, { "not_on_list": true },{ "rank_diff": false },{ "rank_diff_negative": true },{"week_on_top":false},{"higher":false},{"lower":false},{"ranks":true},{"buy":false},{"sell":false}] },
        { "billboard_top_100_artist": [{ "no_of_songs": false }, { "week2Percent": true }, { "week3Percent": true }, { "no_of_weeks": true }, { "not_on_list": true }, { "rank_diff": true },{ "rank_diff_negative": true },{"week_on_top":false},{"higher":false},{"lower":false},{"ranks":true},{"buy":false},{"sell":false}] },
        { "billboard_hot_100": [{ "no_of_songs": true }, { "week2Percent": false }, { "week3Percent": false }, { "no_of_weeks": true }, { "not_on_list": true }, { "rank_diff": false },{ "rank_diff_negative": true },{"week_on_top":false},{"higher":false},{"lower":false},{"ranks":true},{"buy":false},{"sell":false}] },
        { "billboard_top_200_albums": [{ "no_of_songs": true }, { "week2Percent": false }, { "week3Percent": false }, { "no_of_weeks": true }, { "not_on_list": true }, { "rank_diff": false },{ "rank_diff_negative": true },{"week_on_top":false},{"higher":false},{"lower":false},{"ranks":true},{"buy":false},{"sell":false}] },
        { "SoundChartTop100To139": [{ "no_of_songs": false }, { "week2Percent": false }, { "week3Percent": false }, { "no_of_weeks": false }, { "not_on_list": true }, { "rank_diff": false },{ "rank_diff_negative": false },{"week_on_top":false},{"higher":false},{"lower":false},{"ranks":true},{"buy":false},{"sell":false}] },
        { "SoundChartHipHopRap": [{ "no_of_songs": false }, { "week2Percent": false }, { "week3Percent": false }, { "no_of_weeks": false }, { "not_on_list": true }, { "rank_diff": false },{ "rank_diff_negative": false },{"week_on_top":false},{"higher":false},{"lower":false},{"ranks":true},{"buy":false},{"sell":false}] },
        { "SoundChartTop200US": [{ "no_of_songs": false }, { "week2Percent": false }, { "week3Percent": false }, { "no_of_weeks": false }, { "not_on_list": true }, { "rank_diff": false },{ "rank_diff_negative": false },{"week_on_top":false},{"higher":false},{"lower":false},{"ranks":true},{"buy":false},{"sell":false}] },
        { "scraper_weekly_top_albums": [{ "no_of_songs": false }, { "week2Percent": false }, { "week3Percent": false }, { "no_of_weeks": true }, { "not_on_list": true }, { "rank_diff": true },{ "rank_diff_negative": true },{"week_on_top":true},{"higher":false},{"lower":false},{"ranks":true},{"buy":false},{"sell":false}] },
        { "scraper_daily_top_artists": [{ "no_of_songs": false }, { "week2Percent": false }, { "week3Percent": false }, { "no_of_weeks": true }, { "not_on_list": true }, { "rank_diff": true },{ "rank_diff_negative": true },{"week_on_top":true},{"higher":false},{"lower":false},{"ranks":true},{"buy":false},{"sell":false}] },
        { "scraper_daily_viral_tracks": [{ "no_of_songs": false }, { "week2Percent": false }, { "week3Percent": false }, { "no_of_weeks": true }, { "not_on_list": true }, { "rank_diff": true },{ "rank_diff_negative": true },{"week_on_top":true},{"higher":false},{"lower":false},{"ranks":true},{"buy":false},{"sell":false}] },
        { "scraper_daily_top_tracks": [{ "no_of_songs": false }, { "week2Percent": false }, { "week3Percent": false }, { "no_of_weeks": true }, { "not_on_list": true }, { "rank_diff": true },{ "rank_diff_negative": true },{"week_on_top":true},{"higher":false},{"lower":false},{"ranks":true},{"buy":false},{"sell":false}] },
        { "itune": [{ "no_of_songs": false }, { "week2Percent": false }, { "week3Percent": false }, { "no_of_weeks": false }, { "not_on_list": false }, { "rank_diff": false },{ "rank_diff_negative": false },{"week_on_top":false},{"higher":false},{"lower":false},{"ranks":true},{"buy":false},{"sell":false}] },
        { "spotify": [{ "no_of_songs": false }, { "week2Percent": false }, { "week3Percent": false }, { "no_of_weeks": false }, { "not_on_list": true }, { "rank_diff": false },{ "rank_diff_negative": false },{"week_on_top":false},{"higher":true},{"lower":true},{"ranks":true},{"buy":false},{"sell":false}] },
        { "turnt": [{ "no_of_songs": false }, { "week2Percent": false }, { "week3Percent": false }, { "no_of_weeks": false }, { "not_on_list": true }, { "rank_diff": false },{ "rank_diff_negative": false },{"week_on_top":false},{"higher":false},{"lower":false},{"ranks":true},{"buy":false},{"sell":false}] },
        { "soundchart_rankings_spotify": [{ "no_of_songs": false }, { "week2Percent": false }, { "week3Percent": false }, { "no_of_weeks": false }, { "not_on_list": false }, { "rank_diff": false },{ "rank_diff_negative": false },{"week_on_top":false},{"higher":true},{"lower":true},{"ranks":false},{"buy":false},{"sell":false}] },
        { "soundchart_broadcast_tracks": [{ "no_of_songs": false }, { "week2Percent": false }, { "week3Percent": false }, { "no_of_weeks": false }, { "not_on_list": false }, { "rank_diff": false },{ "rank_diff_negative": false },{"week_on_top":false},{"higher":true},{"lower":true},{"ranks":false},{"buy":false},{"sell":false}] },
        { "buy_&_sell": [{ "no_of_songs": false }, { "week2Percent": false }, { "week3Percent": false }, { "no_of_weeks": false }, { "not_on_list": false }, { "rank_diff": false },{ "rank_diff_negative": false },{"week_on_top":false},{"higher":false},{"lower":false},{"ranks":false},{"buy":true},{"sell":true}] },
        { "soundchart_spotify_listeners": [{ "no_of_songs": false }, { "week2Percent": false }, { "week3Percent": false }, { "no_of_weeks": false }, { "not_on_list": false }, { "rank_diff": false },{ "rank_diff_negative": false },{"week_on_top":false},{"higher":true},{"lower":true},{"ranks":false},{"buy":false},{"sell":false}] },
        { "soundchart_youtube_listeners": [{ "no_of_songs": false }, { "week2Percent": false }, { "week3Percent": false }, { "no_of_weeks": false }, { "not_on_list": false }, { "rank_diff": false },{ "rank_diff_negative": false },{"week_on_top":false},{"higher":true},{"lower":true},{"ranks":false},{"buy":false},{"sell":false}] },
    ]
    var cal_name_key = [];
    for (var index in fields) {
        for (var key in fields[index]) {
            if (key === cal_name) {
                cal_name_key = fields[index][key];
            }
            break;
        }
    }
    var result = false;
    $.each(cal_name_key, function (key, value) {
        if (value.hasOwnProperty(fieldsName)) {
            return result = value[fieldsName];
        }
    });
    return result
}

export const calculationName = (calName) => {
    var i, frags = calName.split('_');
    if (frags.length > 1) {
        for (i = 0; i < frags.length; i++) {
            frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
    }
    else {
        var result = calName.replace(/(\d{3}|[A-Z])/g, ' $1').trim();
        return result;
    }
}
export const calculationNameNavLink = (calName) => {
    var nm = calculationName(calName);
    var i, frags = nm.split('/');
    if (frags.length > 1) {
        for (i = 0; i < frags.length; i++) {
           frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            if (i === frags.length - 1) {
                frags[i] = "( " + frags[i].charAt(0).toUpperCase() + frags[i].slice(1) + " )";
            }
        }
        return frags.join(' ');
    }
    else {
        return calculationName(calName);
    }
}

export const ButtonSpinner = props => {
    return (
        <>
            {
                props.load ?
                    <div className="spinner-border spinner-border-sm mx-3" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    : props.btnName
            }
        </>
    )
}

export const floatNumber = (num, decimal_digit = 2) => {
    return parseFloat(num).toFixed(decimal_digit + 1).slice(0, -1);
}
export const floatNumberValueBased = (num) => {
    if(num>1){
        return parseFloat(num).toFixed(3);
    }
    else{
        return parseFloat(num).toFixed(4); 
    }
}
export const floatNumber4Decimal = (num) => {
    return parseFloat(num).toFixed(4);
}
export const floatNumber5Decimal = (num) => {
    return parseFloat(num).toFixed(5);
}


export const currencyFormat = (num) => {
    return '$' + floatNumber4Decimal(num);
}
export const htmlEntities = (str) => {
    return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}