import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router';
import { Tooltip } from "bootstrap";
import { toastNotify, noImageHandle } from '../components/Helper';
import { ASSET_ENDPOINT, fetchData, LOGOUT } from '../components/Service';
import { Context } from './Context';
import { useContext } from 'react';

const HeaderBar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [context, setContext] = useContext(Context)
    const [profileImage, setProfileImage] = useState()
    const [token] = useState(localStorage.getItem("stock-market"));

    useEffect(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
        })
        if (context !== '') {
            setProfileImage(ASSET_ENDPOINT + '/' + context.auth.profile_image);
        }
        else {
            setProfileImage('');
        }
        if (!token) {
            navigate('/login')
        }


    }, [context, navigate, token]);
    const handleLogout = () => {
        fetchData(LOGOUT, 'POST', '', true, false, (res) => {
            toastNotify(res)
            if (res.success) {
                setContext('')
                localStorage.removeItem("stock-market");
                navigate('/login')
            }
        });
    }

    const menuToggle = () => {
        document.body.classList.toggle('menu-active');
    }
    return (
        <>
            <nav className="admin-header">
                <div className="row flex-nowrap m-0 h-100 align-items-center">
                    <div className="col-auto d-block d-xl-none">
                        <div className="admin-notification" onClick={menuToggle} role="button">
                            <svg className="icon" role="img"><use href="#icon_menu" /></svg>
                        </div>
                    </div>
                    <div className="col-auto d-none d-md-inline-flex">
                        <span className="page-title" id="tw_pagetitle"></span>
                    </div>
                    <div className="col-auto ms-auto">
                        <div className="dropdown d-inline-block align-middle">
                            <button type="button" className="admin-profile" data-bs-toggle="dropdown">
                                <img src={profileImage} onError={noImageHandle} alt="User" width="36" height="36" className="bg-white" />
                                <div className="d-none d-lg-block mx-2 pe-1">{"Xipe Tech"}</div>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2">
                                <li>
                                    <Link to="dashboard" title="Dashboard" className={`dropdown-item rounded-20 text-blue py-2 px-4 my-1 ${location.pathname.match('/dashboard') ? 'active' : ''}`}>
                                        <svg className="icon tscale-1p2 opacity-75 me-3" role="img" aria-label="Dashboard">
                                            <use href="#icon_dashboard" />
                                        </svg>
                                        Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <Link to="app-my-profile" title="Profile Settings" className={`dropdown-item rounded-20 text-blue py-2 px-4 my-1 ${location.pathname.match('/my-profile') ? 'active' : ''}`}>
                                        <svg className="icon tscale-1p4 opacity-75 me-3" role="img" aria-label="Profile Settings">
                                            <use href="#icon_usercog" />
                                        </svg>
                                        Profile Settings
                                    </Link>
                                </li>
                                <li>
                                    <Link to="app-change-password" title="Change Password" className={`dropdown-item rounded-20 text-blue py-2 px-4 my-1 ${location.pathname.match('/app-change-password') ? 'active' : ''}`}>
                                        <svg className="icon tscale-1p2 opacity-75 me-3" role="img" aria-label="Change Password">
                                            <use href="#icon_lock" />
                                        </svg>
                                        Change Password
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider text-white opacity-25" />
                                </li>
                                <li>
                                    <button type="button" onClick={handleLogout} className="dropdown-item rounded-20 text-blue py-2 px-4" title="Logout">
                                        <svg className="icon tscale-1p2 opacity-75 me-3" role="img" aria-label="Setting">
                                            <use href="#icon_logout" />
                                        </svg>
                                        Logout
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <div className="overlay-onmobile" onClick={menuToggle}></div>
        </>
    )
}

export const HeaderFront = () => {
    const navigate = useNavigate();
    // const [token] = useState(localStorage.getItem("stock-market"));
    const location = useLocation();
    const [context, setContext] = useContext(Context)
    const [profileImage, setProfileImage] = useState()

    useEffect(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
        })
        if (context !== '') {
            setProfileImage(ASSET_ENDPOINT + '/' + context.auth.profile_image);
        }
        else {
            setProfileImage('');
        }

    }, [context]);
    const handleLogout = () => {
        fetchData(LOGOUT, 'POST', '', true, false, (res) => {
            toastNotify(res)
            if (res.success) {
                setContext('')
                localStorage.removeItem("stock-market");
                navigate('/login')
            }
        });
    }

    const menuToggle = () => {
        document.body.classList.toggle('menu-active');
    }

    return (
        <>
            <nav className="admin-header-front">
                <div className="row flex-nowrap m-0 h-100 align-items-center">
                    <div className="col-auto p-0">
                        <div className="d-inline-block align-middle me-2">
                            <Link to="app-assets" className={`btn ${location.pathname.match('/app-assets') ? 'btn-active' : 'btn-primary'} rounded-pill minw-100`}>
                                <div className="d-none d-lg-block mx-2 pe-1">Bar & Flow</div>
                            </Link>
                        </div>
                        <div className="d-inline-block align-middle me-2">
                            <Link to="app-top-bag-getter-list" className={`btn ${location.pathname.match('/app-top-bag-getter-list') ? 'btn-active' : 'btn-primary'} rounded-pill minw-100`}>
                                <div className="d-none d-lg-block mx-2 pe-1">Top Bag Getters</div>
                            </Link>
                        </div>
                        <div className="d-inline-block align-middle me-2">
                            <Link to="app-posts" className={`btn ${location.pathname.match('/app-posts') ? 'btn-active' : 'btn-primary'} rounded-pill minw-100`}>
                                <div className="d-none d-lg-block mx-2 pe-1">News & Blogs</div>
                            </Link>
                        </div>
                        {context && context.auth && <div className="d-inline-block align-middle me-2">
                            <Link to="app-in-my-bag" className={`btn ${location.pathname.match('/app-in-my-bag') ? 'btn-active' : 'btn-primary'} rounded-pill minw-100`}>
                                <div className="d-none d-lg-block mx-2 pe-1">In My Bag</div>
                            </Link>
                        </div>}
                        {context && context.auth && <div className="d-inline-block align-middle me-2">
                            <Link to="app-recent-activity" className={`btn ${location.pathname.match('/app-recent-activity') ? 'btn-active' : 'btn-primary'} rounded-pill minw-100`}>
                                <div className="d-none d-lg-block mx-2 pe-1">Recent Activity</div>
                            </Link>
                        </div>}
                        {context && context.auth && <div className="d-inline-block align-middle me-2">
                            <Link to="app-my-wallet" className={`btn ${location.pathname.match('/app-my-wallet') ? 'btn-active' : 'btn-primary'} rounded-pill minw-100`}>
                                <div className="d-none d-lg-block mx-2 pe-1">My Wallet</div>
                            </Link>
                        </div>}
                    </div>
                    {context && context.auth ? <div className="col-auto ms-auto p-0">
                        <div className="dropdown d-inline-block align-middle">
                            <button type="button" className="admin-profile" data-bs-toggle="dropdown">
                                <img src={profileImage} onError={noImageHandle} alt="User" width="36" height="36" className="bg-white" />
                                <div className="d-none d-lg-block mx-2 pe-1">{context.auth.username}</div>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2">
                                {context && context.auth.role_id === 1 && <li>
                                    <Link to="dashboard" title="Dashboard" className={`dropdown-item rounded-20 text-blue py-2 px-4 my-1 ${location.pathname.match('/dashboard') ? 'active' : ''}`}>
                                        <svg className="icon tscale-1p4 opacity-75 me-3" role="img" aria-label="Dashboard">
                                            <use href="#icon_dashboard" />
                                        </svg>
                                        Dashboard
                                    </Link>
                                </li>}
                                <li>
                                    <Link to="app-my-profile" title="My Profile" className={`dropdown-item rounded-20 text-blue py-2 px-4 my-1 ${location.pathname.match('/app-my-profile') ? 'active' : ''}`}>
                                        <svg className="icon tscale-1p4 opacity-75 me-3" role="img" aria-label="My Profile">
                                            <use href="#icon_usercog" />
                                        </svg>
                                        My Profile
                                    </Link>
                                </li>
                                <li>
                                    <Link to="app-change-password" title="Change Password" className={`dropdown-item rounded-20 text-blue py-2 px-4 my-1 ${location.pathname.match('/app-change-password') ? 'active' : ''}`}>
                                        <svg className="icon tscale-1p2 opacity-75 me-3" role="img" aria-label="Change Password">
                                            <use href="#icon_lock" />
                                        </svg>
                                        Change Password
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider text-white opacity-25" />
                                </li>
                                <li>
                                    <button type="button" onClick={handleLogout} className="dropdown-item rounded-20 text-blue py-2 px-4" title="Logout">
                                        <svg className="icon tscale-1p2 opacity-75 me-3" role="img" aria-label="Setting">
                                            <use href="#icon_logout" />
                                        </svg>
                                        Logout
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div> :
                        <div className="col-auto ms-auto p-0">
                            <div className="d-inline-block align-middle me-2">
                                <Link to="login" className="btn btn-primary rounded-pill minw-100">
                                    <div className="d-none d-lg-block mx-2 pe-1">Login</div>
                                </Link>
                            </div>
                        </div>}
                </div>
            </nav>
            <div className="overlay-onmobile" onClick={menuToggle}></div>
        </>
    )
}

export default HeaderBar