import React, {useState, useEffect} from "react";
import {DataList} from "../../components/DataList";
import {RECENT_ACTIVITY, ASSET_ENDPOINT, fetchData, MY_ASSETS, MY_BAG} from "../../components/Service";
import {noImageHandle, __echoText, currencyFormat, floatNumber} from "../../components/Helper";
import _, {now} from "lodash";
import {Link} from "react-router-dom";

const AppInMyBag = () => {
    const [refresh] = useState(now);
    const [assetDt, setAssetDt] = useState(false);
    const [myBag, setMyBag] = useState(false);
    const [recentActivity, setRecentActivity] = useState("");

    useEffect(() => {
        fetchData(MY_BAG, "GET", "", true, false, (res) => {
            if (res.records) {
                setMyBag(res.records);
            }
        });

        fetchData(RECENT_ACTIVITY, "GET", "", true, false, (res) => {
            if (res.records) {
                setRecentActivity(res.records);
            }
        });

        setAssetDt({
            id: "assets",
            fetchurl: MY_ASSETS,
            columns: [
                {name: "image", title: "Assets", width: 50, sorting: false},
                {name: "name", sorting: false},
                {name: "bag.total_value", sorting: false},
                {name: "bag.amount_increase_shares", sorting: false},
                {name: "bag.share_percentage", sorting: false},
                {name: "action", sorting: false},
            ],
        });
    }, []);

    return (
        <div className="row justify-content-center mt-4">
            <div className="row">
                <div className="col-md-6 text-end">
                    <h3>Available Stocks:</h3>
                </div>
                <div className="col-md-6">
                    <h3>{floatNumber(myBag.available_stock, 4)}</h3>
                </div>
                <div className="col-md-6 text-end">
                    <h3>Invested Value:</h3>
                </div>
                <div className="col-md-6">
                    <h3>{currencyFormat(myBag.invested_value)}</h3>
                </div>
                <div className="col-md-6 text-end">
                    <h3 className={`text-${myBag.trend === "up" ? "success" : "danger"}`}>Current Value:</h3>
                </div>
                <div className="col-md-6">
                    <h3 className={`text-${myBag.trend === "up" ? "success" : "danger"}`}>
                        {currencyFormat(myBag.current_value)}
                    </h3>
                </div>
                <div className="col-md-6 text-end">
                    <h3 className={`text-${myBag.trend === "up" ? "success" : "danger"}`}>Stock Amount Change:</h3>
                </div>
                <div className="col-md-6">
                    <h3 className={`text-${myBag.trend === "up" ? "success" : "danger"}`}>
                        {myBag.trend === "up" ? "+" : "-"} {floatNumber(myBag.stock_change)} (
                        {myBag.trend === "up" ? "+" : "-"} {floatNumber(myBag.stock_change_percent)}%)
                    </h3>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-6 p-0">
                    <h3>Current Rapper Assets</h3>
                </div>
            </div>
            {assetDt && (
                <DataList
                    dt={assetDt}
                    refresh={refresh}
                    searching={false}
                    pagelength={false}
                    edit_column={{
                        image: (records) => (
                            // eslint-disable-next-line jsx-a11y/img-redundant-alt
                            <img
                                src={`${ASSET_ENDPOINT}${records.image}`}
                                alt="asset-image"
                                className="wh-100 rounded shadow-sm objfit-cover bg-light"
                                onError={noImageHandle}
                            />
                        ),

                        name: (records) => (
                            <>
                                <div className="py-2">{__echoText(_.startCase(records.name))}</div>
                                <div className="badge bg-dark minw-70 py-2 fs-12">
                                    {__echoText(_.startCase(records.code))}
                                </div>
                            </>
                        ),

                        "bag.total_value": (records) => <span>{currencyFormat(records.bag.total_value)}</span>,

                        "bag.amount_increase_shares": (records) => <span>{currencyFormat(records.bag.amount_increase_shares)}</span>,

                        "bag.share_percentage": (records) => (
                            <>
                                <span className={`text-${records.bag.share_percentage > 0 ? "success" : "danger"}`}>
                                    {records.bag.share_percentage > 0 ? "+" : ""}{" "}
                                    {floatNumber(records.bag.share_percentage)}%
                                </span>
                            </>
                        ),

                        action: (records) => (
                            <div className="d-flex">
                                {/* <button className="btn btn-sm text-success" title="View Chart" data-bs-toggle="modal"  data-bs-target="#viewChart" onClick={() => viewAssetPerformances(records.id) }>
                                <svg className="icon fs-4" role="img"><use href="#icon_bar_chart" /></svg>
                            </button> */}
                            </div>
                        ),
                    }}
                />
            )}

            <div className="row mt-5">
                <div className="col-md-6 p-0">
                    <h3>Recent Activity</h3>
                </div>
                <div className="col-md-6 p-0 text-end">
                    <Link to="/app-recent-activity" className="btn btn-primary rounded-pill text-nowrap text-blue">
                        View All
                    </Link>
                </div>
            </div>
            <div className="table-spinner active">
                <div className="table-responsive">
                    <table className="table-common">
                        <thead>
                            <tr>
                                <th>Recent Activity List</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {recentActivity && recentActivity.data.length > 0 ? (
                                recentActivity.data.map((ele, num) => {
                                    return (
                                        <tr role="row" key={num}>
                                            <td>
                                                {ele.transaction_type === "buy" ? "Purchased" : "Sold"}{" "}
                                                {floatNumber(ele.stock, 4)} stock(s) of {ele.name}({ele.code})
                                            </td>
                                            <td className="text-end">{__echoText(ele.created_at, "datetime")}</td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr role="row">
                                    <td colSpan="3">
                                        <div className="d-flex align-items-center justify-content-center text-danger">
                                            No Record Found
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default AppInMyBag;
