import { useEffect } from "react"
import { Link } from "react-router-dom"
import { scrollbarSetToTop } from "../../components/Helper"

export const CommonHeader = ({ title }) => {

    useEffect(() => {
        scrollbarSetToTop()
    }, [])


    return (
        <>
            <div className="blackStripe py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h1>{title}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



export const CommonFooter = () => {


    return (
        <>

            <style jsx="true">{`
                .contactForm label {
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 10px;
                }
                
                .contactForm .form-control{
                    height: 50px;
                }
                
                .contactForm .form-control:focus {
                    border-color: #000;
                    box-shadow: none;
                }
                
                .contactForm button{
                    background-color: #000;
                    color: #fff;
                    border: none;
                    margin-top: 20px;
                    width: 150px;
                    height: 50px;
                }
                
                .contactForm button:hover{
                    opacity: 0.7;
                    background-color: #000;
                }

                .blackStripe{
                    background-color: #000;
                }
                .blackStripe h1{
                    color: #fff;
                    font-size: 50px;
                }
                .privacyContent h5{
                    color: #4A4A4A;
                }
                .privacyUl li{
                    margin-top: 10px;
                }
                .footerLink{
                    color: #fff !important;
                    text-decoration: none;
                }
                .copyRighttxt{
                    font-size: 14px;
                    color: #919191;
                }
            `}</style>
            <div className="blackStripe mt-5 py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-6 text-left">
                            <Link to="/privacy-policy" className="footerLink">Privacy Policy</Link>
                        </div>
                        <div className="col-6 text-end">
                            <Link to="/contact-us" className="footerLink">Contact Us</Link>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 text-center">
                            <p className="mb-0 copyRighttxt">rappstock © Copyright 2023. All Rights Reserved.</p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
