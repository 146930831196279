import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { fetchData, VERIFY_EMAIL } from "../../components/Service"

function VerifyEmail(){
    window.document.title = "Stock | Verify Email";
    
    const [search] = useSearchParams();
    const [emailStatus, setEmailStatus] = useState('')

    useEffect(() => {
        if(search){
            fetchData(`${VERIFY_EMAIL}?expires=${search.get('expires')}&hash=${search.get('hash')}&id=${search.get('id')}&signature=${search.get('signature')}`, 'GET', '', false, false, (res) => {
                if (res.success){
                    setEmailStatus(res.success)
                }
            })
        }
    }, [search])
    
    return(
        <>
        <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-5 py-3">
                        <div className="bg-white rounded-15 p-3 p-sm-4 p-sm-5 shadow-sm">
                            <div className="text-center">
                                <img src="/logo.png" alt="Xipetech" className="w-100 maxw-280" width="280" height="70.81"/>
                            </div>
                            <hr className="text-muted opacity-10 mx-auto"/>
                            { emailStatus ? 
                                <>
                                    <h2 className="text-center text-success fw-bold">Account Verified</h2>
                                    <p className=" text-center text-success opacity-75">{ emailStatus }</p>
                                </>
                                : 
                                <>
                                <h2 className="text-center fw-bold">Verifing Account ...</h2>
                                <p className=" text-center opacity-75">We are verifying your email. Please do not press back button.</p>
                                </> 
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default VerifyEmail