import React, { useState, useEffect } from "react";
import { DataList } from "../../components/DataList";
import { fetchData, CHAR_LIST, CHAR_CREATE, CHAR_GET, CHAR_UPDATE, CHAR_DELETE } from "../../components/Service";
import { toastNotify, validateForm, deleteRecords, ConfirmationModal, initialFormState } from "../../components/Helper";
import { now } from "lodash"


function SpecialChars() {
    window.document.title = "Special Character";
    const [refresh, setRefresh] = useState(now)
    const [offcanvastitle, setOffcanvastitle] = useState("Add New Special Character")
    const [formfield, setFormfield] = useState({
        id: "",
        character: "",
    })

    const dt = {
        id: 'specialChars',
        fetchurl: CHAR_LIST,
        columns: [
            { name: 'id', title: 'Id' },
            { name: 'character', title: 'Special Character' },
            { name: 'created_at', title: 'Date & Time', type: 'datetime' },
            { name: 'action', title: 'Action' },
        ]
    }

    useEffect(() => {
        document.getElementById("addUpdateChar").addEventListener('hidden.bs.offcanvas', function (e) {
            e.target.reset()
            e.target.classList.remove('was-validated');
            setOffcanvastitle("Add New Special Character");
            setFormfield({ /*Set empty state*/
                id: "",
                character: "",

            })
        });
    }, [refresh])

    const addChar = async (e) => {
        let formdata = new FormData(document.getElementById('addUpdateChar'));
        let id = document.querySelector('#addUpdateChar [name="id"]').value;

        if (validateForm(e)) {
            fetchData(id ? CHAR_UPDATE : CHAR_CREATE, 'POST', formdata, true, true, (res) => {
                toastNotify(res)

                if (res.success) {
                    document.querySelector('#addUpdateChar [data-bs-dismiss="offcanvas"]').click();
                }
                setRefresh(now)
            });
        }
    }

    const editSpecialChar = (getid) => {
        initialFormState('addUpdateChar');
        setOffcanvastitle("Update Special Character")
        fetchData(CHAR_GET + '/' + getid, 'GET', '', true, false, (res) => {
            if (res.records) {
                setFormfield(prevState => ({
                    ...prevState,
                    id: res.records.id,
                    character: res.records.character,
                }))
            } else {
                toastNotify(res)
            }
        }, (err) => { });
    }
    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h4 className="page-heading">Special Characters (Please Note This Module is Case Sensitive)</h4>
                        </div>
                    </div>

                    <div className="row">

                        {<DataList
                            dt={dt}
                            refresh={refresh}
                            outerbutton={(records) =>
                                <div className="ms-auto">
                                    <button type="button" className="btn btn-primary rounded-pill px-3 text-nowrap" data-bs-toggle="offcanvas" data-bs-target="#addUpdateChar" onClick={()=>initialFormState('addUpdateChar')}>
                                        <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg> Create Special Character
                                    </button>
                                </div>
                            }
                            edit_column={{
                                character: (records) =>
                                    (records.character),
                                action: (records) =>
                                    <div className="d-flex">
                                        <button className="btn btn-sm text-primary" data-bs-toggle="modal" data-bs-target="#view-specialchar" title="View" onClick={() => editSpecialChar(records.id)}>
                                            <svg className="icon fs-4" role="img"><use href="#icon_eye" /></svg>
                                        </button>
                                        <button className="btn btn-sm text-primary" title="Edit" data-bs-toggle="offcanvas" data-bs-target="#addUpdateChar" onClick={() => editSpecialChar(records.id)}>
                                            <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
                                        </button>
                                        <button className="btn btn-sm text-danger" title="Delete" data-bs-toggle="modal" data-bs-target="#confModal" onClick={(e) => setFormfield(prevState => ({ ...prevState, id: records.id }))}>
                                            <svg className="icon fs-5" role="img"><use href="#icon_trash" /></svg>
                                        </button>
                                    </div>
                            }}
                        />}
                    </div>
                </div>
            </section>

            {/* Add Update Form  */}
            <form className="offcanvas offcanvas-end needs-validation " tabIndex="-1" id="addUpdateChar" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <input type="text" className="d-none" name="id" defaultValue={formfield.id} />
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="m-0 text-blue">{offcanvastitle}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-3 p-sm-4">
                    <label className="w-100 mb-3">
                        <div className="form-label text-blue small fw-medium mb-1">Special Character<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="character" placeholder="Enter Special Character" spellCheck="false" defaultValue={(formfield.character)} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, name: e.target.value
                        }))} required />
                        <div className="invalid-feedback">Special Character is required.</div>
                    </label>

                </div>
                <div className="offcanvas-footer border-top bg-light p-3 px-sm-4">
                    <button type="button" className="btn btn-primary rounded-pill px-4 me-2" onClick={(e) => addChar(e)} >Submit</button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Close">Cancel</button>
                </div>
            </form>

            <ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(CHAR_DELETE, formfield.id, setRefresh)} />

            {/* Special Char View Modal  */}
            <div className="modal fade bd-example-modal-lg" id="view-specialchar" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header border-bottom bg-light px-sm-4">
                            <h5 className="m-0 text-blue">Special Character Details</h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-3 p-sm-4">
                            <div className="row">

                                <div className="col-md-9">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="w-100 mb-3">
                                                <div className="mb-1 fw-medium text-blue small">Character</div>
                                                <div>{(formfield.character)}</div>
                                            </label>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/*End Post View Modal  */}
        </>
    )
}

export default SpecialChars
